.meta-navigation {
    padding: 0;
    background-color: $grey-800;
    font-family: $font-family-arial;
    font-size: rem(12);

    &__list {
        flex-direction: row;

        li {
            &:nth-last-of-type(1) {
                .dropdown-menu {
                    right: 0;
                    left: auto;

                    .dropdown-item {
                        text-align: right;
                    }
                }
            }
        }

        .nav-link {
            text-transform: uppercase;
            padding: rem(10) rem(15);
            color: $grey-400 !important;
            position: relative;

            &.dropdown-toggle {
                padding-right: rem(30);
            }

            .icon--chevron-right {
                width: 18px;
                height: 18px;
                color: $grey-400;
                fill: $grey-400;
                position: absolute;
                top: 9px;
                right: 8px;
                transform: rotate(90deg);
            }

            &.submit {
                cursor: pointer;
                background-color: transparent;
                border: 0;
            }
        }
    }

    .dropdown {
        > .dropdown-toggle {
            &:active {
                pointer-events: none;
            }

            &::after {
                display: none;
            }
        }

        &:hover,
        &.is-active {
            background-color: $gray-500;

            .icon--chevron-right {
                color: $white;
                fill: $white;
            }

            .nav-link {
                color: $white !important;
            }

            > .dropdown-menu {
                display: block;
                position: absolute;
                margin: 0;
                padding: 0;
                border-left: solid 1px $gray-400;
                border-right: solid 1px $gray-400;
                border-top: solid 0 $gray-400;
                border-bottom: solid 1px $gray-400;
                font-size: rem(12);
                max-height: 117px;
                overflow-x: auto;

                .dropdown-item {
                    border-bottom: solid 1px $gray-400;
                    padding: rem(10) rem(14);

                    &:nth-last-of-type(1) {
                        border-bottom: solid 0 $gray-400;
                    }

                    &:hover,
                    &:focus,
                    &.is-active {
                        background-color: $gray-100;
                    }
                }
            }

            .dropdown-toggle {
                &:hover,
                &:focus {
                    background-color: $gray-500;
                }
            }
        }
    }
}
