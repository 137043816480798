.hotspot-intro-fade {
    &-enter-active,
    &-leave-active {
        transition: 500ms all ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

.hotspot-map-fade {
    &-enter-active,
    &-leave-active {
        transition: 750ms all ease-in-out;
        transition-delay: 500ms;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
