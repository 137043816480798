.cta-panel {
    position: relative;
    transform: translateY(rem(50px));
    z-index: 20;

    a {
        text-decoration: none;
    }

    .row {
        min-height: auto;

        @include media-breakpoint-up(lg) {
            min-height: 325px;
        }
    }

    .card {
        margin: 0;
        padding: 0;
        background-color: $white;
        border-radius: 0;
        box-shadow: 2px -2px 78px 0 rgba($black, 0.2);

        &:hover {
            box-shadow: -6.632px 4.474px 78px 0 rgba($black, 0.5);
        }

        &__image {
            margin: 0;
            height: 320px;

            @include media-breakpoint-up(lg) {
                height: auto;
                max-width: 50%;
            }
        }

        &-body {
            margin-bottom: 0;
            padding: rem(35px);

            @include media-breakpoint-up(lg) {
                max-width: 50%;
                padding: 0 rem(30px);
            }
        }

        &-title {
            font-size: rem(16px);
            @include line-height(20px, 16px);

            font-weight: $font-weight-normal;
            color: heading-color(dark) !important;

            @include media-breakpoint-up(lg) {
                @include line-height(28px, 24px);

                font-size: rem(24px);
            }
        }

        &-text {
            @include line-height(18px, 14px);

            margin-bottom: rem(25px);
            font-size: rem(14px);
            font-weight: $font-weight-normal;
            color: text-color(dark) !important;

            @include media-breakpoint-up(lg) {
                @include line-height(21px, 16px);

                margin-bottom: rem(25px);
                font-size: rem(16px);
            }
        }

        .btn {
            @include line-height(16px, 16px);

            position: relative;
            bottom: auto;
            left: auto;
            padding-top: rem(13px);
            padding-bottom: rem(13.6px);
            font-size: rem(16px);
            font-weight: $font-weight-normal;
            border: 1px solid theme-color(primary);
            color: theme-color(primary);

            &:hover {
                color: $white;
            }
        }
    }

    &--blue {
        transform: translateY(rem(20px));
        background-color: theme-color(primary) !important;
        color: $white !important;

        p {
            color: inherit;
        }

        .card-title,
        .card-text {
            color: $white !important;
        }

        .btn {
            border-color: $white !important;
            color: $white !important;

            &:hover {
                color: theme-color(primary) !important;
                background: $white !important;
            }
        }
    }
}

// .bg-dark {
//     .cta-panel {
//         .card {
//             .btn {
//                 border-color: $white !important;
//                 color: $white !important;

//                 &:hover {
//                     color: theme-color(primary) !important;
//                     background: $white;
//                 }
//             }
//         }
//     }
// }
