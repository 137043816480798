.markets {
    [class*='col-'] {
        margin-bottom: 0;
    }

    .collapse-trigger {
        @include line-height(55px, 21px);

        position: relative;
        margin: 0;
        padding-left: rem(14px);
        font-size: rem(21px);
        display: block;
        border-bottom: 1px solid $grey-300;
        color: theme-color(primary) !important;
        cursor: pointer;

        .icon {
            position: absolute;
            top: rem(5px);
            right: rem(15px);
            fill: theme-color(primary);

            svg {
                transition: transform 250ms ease-in-out;
                transform: rotate(0deg);
            }
        }

        &.open {
            .icon {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        @include media-breakpoint-up(md) {
            @include line-height(28px, 24px);

            margin-bottom: rem(32px);
            padding-left: 0;
            font-size: rem(24px);
            color: heading-color(dark) !important;
            cursor: default;
            border-bottom: 0;
        }
    }

    .nav {
        margin-top: rem(5px);
        padding-bottom: rem(15px);
        border-bottom: 1px solid $grey-300;

        @include media-breakpoint-up(md) {
            margin: 0 -20px rem(30px) -20px;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    .nav-item {
        padding-left: rem(14px);
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            padding: 0 20px;
            width: 33.3333%;
        }

        @include media-breakpoint-up(lg) {
            width: 25%;
        }
    }

    .nav-link {
        position: relative;
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(md) {
            border-top: 1px solid $grey-200;
        }
    }

    .flag-icon {
        margin: 3px 10px 0 0;
        display: block;
        float: left;
        box-shadow: 1px 3px 7px -1px rgba($black, 0.2);
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .markets {
        .collapse-trigger {
            color: $white !important;
            border-color: $grey-600;

            .icon {
                fill: $white;
            }
        }

        .nav {
            border-color: $grey-600;
        }

        .nav-link {
            color: $grey-400;
            border-color: $grey-600;

            &:hover {
                color: $white;
            }
        }
    }
}
