$showcase-card-transition: all 300ms ease-in;

.swiper-slide-active {
    $card: '.card';

    &--showcase {
        #{$card}-body {
            @include media-breakpoint-up(sm) {
                opacity: 1;
                transition: $showcase-card-transition;
            }
        }

        #{$card}__image {
            @include media-breakpoint-up(sm) {
                transform: scale(1.2);
                transition: $showcase-card-transition;
                transform-origin: bottom center;
                filter: none;
            }
        }

        #{$card}__link-overlay {
            pointer-events: auto;
        }
    }
}

.card {
    $card: '.card';

    .is-slickcurrent & {
        &--showcase {
            #{$card}-body {
                @include media-breakpoint-up(sm) {
                    opacity: 1;
                    transition: $showcase-card-transition;
                }
            }

            #{$card}__image {
                @include media-breakpoint-up(sm) {
                    transform: scale(1.2);
                    transition: $showcase-card-transition;
                    transform-origin: bottom center;
                    filter: none;
                }
            }

            #{$card}__link-overlay {
                pointer-events: auto;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 100;
            }
        }
    }

    &--showcase {
        background-color: transparent;
        display: flex;
        align-items: stretch;
        padding: 0;
        height: 100%;
        margin-top: rem(30px);
        border-radius: 0;
        cursor: pointer;

        // IE 10 & 11 hack :(
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }

        #{$card}__link-overlay {
            pointer-events: none;
        }

        #{$card}-title {
            color: theme-color(secondary) !important;
            text-align: center;
            font-size: rem(24px);
            font-weight: 500;
        }

        #{$card}-subtitle {
            color: $grey-600;
            text-align: center;
            font-size: rem(18px);
            font-weight: 500;
            margin-bottom: rem(10px);
        }

        #{$card}__image {
            transition: $showcase-card-transition;
            transform-origin: bottom center;

            @include media-breakpoint-up(sm) {
                filter: grayscale(100%);
            }

            img {
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }
        }

        #{$card}-body {
            padding-bottom: rem(75px);
            transition: $showcase-card-transition;
            position: relative;

            @include media-breakpoint-up(sm) {
                opacity: 0;
            }
        }

        #{$card}-text {
            text-align: center;
            margin-bottom: 25px;
        }

        #{$card}-list {
            padding-left: rem(25px);
            padding-right: rem(25px);

            li {
                border-bottom: solid 1px $grey-200;
                padding: rem(10px) rem(20px);
                color: $grey-600;
            }
        }

        .list {
            &--checkmark {
                li {
                    padding-left: rem(45px);
                    position: relative;
                }

                .icon {
                    width: 20px;
                    height: 20px;

                    &--checkmark {
                        position: absolute;
                        top: 10px;
                        left: 15px;
                        margin-right: rem(10px);
                        color: theme-color(secondary);
                        fill: theme-color(secondary);
                    }
                }
            }
        }

        .btn {
            &-link {
                position: absolute;
                bottom: 30px;
                left: 0;
                right: 0;
                padding-left: 0;
                padding-right: 0;
                color: $grey-800;
                text-decoration: underline !important;
            }

            &:hover {
                text-decoration: none !important;
            }
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .card {
        $card: '.card';

        &--showcase {
            #{$card}-subtitle,
            #{$card}-text {
                color: $grey-400;
            }

            #{$card}-list {
                li {
                    border-bottom: solid 1px lighten($grey-600, 5%);
                    color: $white;
                }
            }

            .btn {
                color: theme-color(primary);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}
