.card {
    $card: '.card';

    &-wrapper {
        &--contact {
            position: relative;
            margin-bottom: rem(30px);

            @include media-breakpoint-up(sm) {
                margin-bottom: rem(60px);
            }

            .collapser {
                position: absolute;
                width: 100%;
                box-shadow: -6.632px 4.474px 78px 0 rgba(0, 0, 0, 0.12);
                transition: all 0.1s ease-in-out;
                z-index: 2;
            }

            &.is-open {
                #{$card}--contact {
                    background-color: $white;
                    box-shadow: -6.632px 4.474px 78px 0 rgba(0, 0, 0, 0.12);
                    transition: all 0.1s ease-in-out;
                    z-index: 3;
                }
            }
        }
    }

    &--contact {
        padding: rem(25px) rem(30px) rem(40px) rem(30px);
        background-color: $grey-100;
        border-radius: 5px 5px 0 0;
        margin-bottom: 0;

        &:hover {
            .btn-round {
                @include background-image(
                    linear-gradient(0deg, theme-color(primary), theme-color(primary-light)) !important
                );
            }
        }

        #{$card}__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        #{$card}-body {
            padding: 0;

            .card-title {
                font-weight: $font-weight-medium;
                color: $grey-800 !important;
            }

            .card-text {
                color: $grey-500 !important;
            }

            .card-title,
            .card-text {
                text-shadow: none;
            }
        }

        .card__image,
        .icon--diverge {
            position: relative;
            top: auto;
            left: auto;
            margin: 0 0 rem(15px) 0;
            padding: 0;
            max-width: 30px;
            max-height: 30px;
        }

        .icon--diverge {
            fill: theme-color(secondary);
        }

        .btn {
            @include background-image(linear-gradient(90deg, rgba($grey-700, 1) 0, rgba($grey-900, 1) 100%) !important);

            position: absolute;
            bottom: -20px;
            left: rem(30px);

            .icon {
                transition: all 0.25s ease-in-out;
                left: 5px;
            }

            @at-root {
                .card-wrapper--contact {
                    &.is-open {
                        .btn {
                            @include background-image(
                                linear-gradient(0deg, rgba($grey-400, 1) 0, rgba($grey-100, 1) 100%) !important
                            );

                            cursor: pointer;

                            .icon {
                                fill: theme-color(primary);
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &.is-active {
                        .btn {
                            z-index: 3;
                        }
                    }
                }
            }

            &:hover,
            &:focus,
            &:active,
            &.active {
                @include background-image(
                    linear-gradient(0deg, theme-color(primary), theme-color(primary-light)) !important
                );

                cursor: pointer;

                .icon {
                    fill: $white;
                }
            }
        }
    }

    &--expanded {
        padding: rem(25px) rem(30px) rem(40px) rem(30px);
        background-color: theme-color(primary) !important;
        border-radius: 0 0 rem(5px) rem(5px);

        > div {
            &:last-of-type {
                border-bottom: solid 0 theme-color(primary-light);
                padding-bottom: 0;
            }
        }
    }

    #{$card}-openinghours,
    #{$card}-contactdata {
        p {
            color: $white;
            margin-bottom: rem(2px);
        }

        span {
            display: block;
        }

        .lnk {
            color: $white;

            &--email {
                text-decoration: underline !important;
            }
        }
    }

    #{$card}-contactdata {
        border-bottom: solid 1px theme-color(primary-light);
        padding-top: rem(20px);
        padding-bottom: rem(20px);

        &__name,
        &__tel {
            font-weight: 500;
        }
    }

    #{$card}-openinghours {
        border-bottom: solid 1px theme-color(primary-light);
        padding-top: rem(10px);
        padding-bottom: rem(20px);

        p {
            margin-bottom: 0;
        }

        span {
            color: theme-color(primary-lighter);
            margin-right: 15px;
            display: inline-block;
            width: 85px;
        }
    }
}

.bg-medium {
    .card {
        $card: '.card';

        &--contact {
            background-color: $white;
        }
    }
}
