$content-carousel-transition: all 0.1s ease-in;

.content-carousel {
    &-yellow {
        .card--showcase .card-title {
            color: theme-color(warning) !important;
        }

        .content-carousel__btn .icon {
            color: theme-color(warning);
            fill: theme-color(warning);
        }
    }

    &-blue {
        .card--showcase .card-title {
            color: theme-color(primary) !important;
        }

        .content-carousel__btn .icon {
            color: theme-color(primary);
            fill: theme-color(primary);
        }
    }

    &-grey {
        .card--showcase .card-title {
            color: $grey-300 !important;
        }

        .content-carousel__btn .icon {
            color: $grey-300;
            fill: $grey-300;
        }
    }

    .slick-disabled {
        opacity: 0;
        transition: $content-carousel-transition;
        pointer-events: none;
    }

    button {
        transition: $content-carousel-transition;
    }

    &__btn {
        position: absolute;
        z-index: 1;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;

        // top: 23%;
        top: 16%;

        @include media-breakpoint-up(sm) {
            // top: 19%;
            top: rem(90px);
        }

        @include media-breakpoint-up(md) {
            top: rem(120px);
        }

        @include media-breakpoint-up(lg) {
            top: rem(170px);
        }

        &:hover {
            transform: scale(1.2);
        }

        .icon {
            width: 50px;
            height: 50px;
            color: theme-color(secondary);
            fill: theme-color(secondary);
            display: block;
        }

        &--prev {
            position: absolute;
            left: -9%;
            transition: $content-carousel-transition;

            @include media-breakpoint-up(sm) {
                left: 29%;
            }
        }

        &--next {
            position: absolute;
            right: -9%;
            transition: $content-carousel-transition;

            @include media-breakpoint-up(sm) {
                right: 29%;
            }
        }
    }
}

.swiper-container.swiper-container--vue {
    padding-top: rem(25px);

    .is-slickcurrent {
        .card--showcase {
            .card__image {
                transform: scale(1);
            }
        }
    }

    .card--showcase {
        .card__image {
            transform: scale(0.8);
        }
    }
}

.slick-track {
    .slick-slide {
        > div {
            &:first-child {
                width: 100%;
            }
        }
    }
}
