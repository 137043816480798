.sub-navigation {
    @include simple-gradient($top: $grey-100, $bottom: $grey-200);

    &__top {
        min-height: 64px;
        @include media-breakpoint-up(lg) {
            min-height: 70px;
        }

        .icon--arrow-back {
            width: 22px;
            height: 22px;
            color: $brand-primary;
            fill: $brand-primary;
            position: relative;
            top: 4px;
            margin-right: 5px;
        }
    }

    &__topics {
        border-top: solid 1px $grey-200;

        .topics {
            padding-top: rem(20);
            padding-bottom: rem(40);

            .list {
                li {
                    border-bottom: 1px solid $grey-300;
                    position: relative;

                    // fix to empty
                    height: 41px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        pointer-events: none;
                    }

                    &.is-active {
                        border-bottom: 3px solid $brand-primary;
                        pointer-events: none;
                    }

                    &:hover {
                        background-color: $white;
                    }

                    &.empty {
                        display: none;
                        border-bottom: 0;

                        &::before {
                            border-bottom: 0;
                        }

                        &:hover {
                            background: transparent;
                        }

                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }

                    a {
                        color: $brand-primary;
                        text-decoration: none;
                        display: block;
                        padding-top: rem(8);
                        padding-bottom: rem(9);
                        padding-left: rem(11);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:focus-visible {
                            text-decoration: underline;
                            outline: 1px solid $brand-primary !important;
                            outline-offset: rem(-1);
                        }

                        .icon--chevron-right {
                            width: 16px;
                            height: 16px;
                            color: $brand-primary;
                            fill: $brand-primary;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
        }
    }
}

// hamburger animation
.icon--submenu {
    width: 22px;
    height: 14px;
    position: relative;
    margin: 4px auto;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    cursor: pointer;
    padding-top: rem(13);
    padding-bottom: rem(13);
    color: $brand-primary;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background-color: $brand-primary;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(even) {
            left: 50%;
        }

        &:nth-child(odd) {
            left: 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
            top: 6px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            top: 12px;
        }

        &:nth-child(5),
        &:nth-child(6) {
            top: 18px;
        }
    }

    &.is-open {
        span {
            &:nth-child(1),
            &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2),
            &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: 2px;
                top: 8px;
            }

            &:nth-child(2) {
                left: calc(50% - 2px);
                top: 8px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 2px;
                top: 15px;
            }

            &:nth-child(6) {
                left: calc(50% - 2px);
                top: 15px;
            }
        }
    }
}
