/* stylelint-disable value-keyword-case */
/* stylelint-disable scss/dollar-variable-colon-space-after */
$font-family-sans-serif:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';

$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-family-arial: Arial, Inter, sans-serif;
$font-size-base: 1rem; // 16px
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
