@media print {
    * {
        overflow: visible !important;
    }

    .modern,
    .otc-page {
        height: auto;
        position: relative;
        overflow: visible;
        overflow-x: hidden;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;

        .otc-main {
            height: auto;
            position: relative;
            overflow: visible;
        }

        header,
        footer,
        section,
        .main-overlay,
        .main-background,
        .modal,
        .visualisationviewer,
        .visualisation-controls,
        .sidepanels {
            display: none;

            &.is-active,
            &.is-visible,
            &.is-selected {
                display: none;
            }
        }

        .summary,
        .summary.is-active {
            display: block;
            height: auto !important;
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            transform: none;
            overflow: visible;
            padding: 0;

            .btn-print {
                display: none;
            }

            .summary-category {
                overflow: visible;
                border-bottom: none;

                .summary-category-title {
                    float: none;
                    width: auto;
                    margin-bottom: 0.5rem;
                }

                .summary-featuregroup-items {
                    float: none;
                    width: auto;

                    .summary-featuregroup-item {
                        > .summary-item {
                            overflow: visible;
                            page-break-inside: avoid;
                        }
                    }
                }
            }
        }
    }
}
