select {
    &.form-control {
        padding: rem(5px) rem(5px) rem(5px) rem(15px) !important;
        color: $grey-900;
        box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1);
        cursor: pointer;

        &:focus,
        &:active,
        &:hover {
            color: $grey-900;
            box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1) !important;
        }

        &:not([size]) {
            &:not([multiple]) {
                height: 42px;
            }
        }
    }
}
