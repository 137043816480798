.teaser-diverge {
    padding: rem(25px) rem(30px) rem(40px) rem(30px);
    background-color: $grey-100;
    border-radius: 5px 5px 0 0;

    .bg-medium & {
        background-color: $white;
    }

    .card__image,
    .icon--diverge {
        position: relative;
        top: auto;
        left: auto;
        margin: 0 0 rem(15px) 0;
        padding: 0;
        max-width: 30px;
        max-height: 30px;
    }

    .icon--diverge {
        fill: theme-color(secondary);

        @at-root .teaser-diverge--blue .icon--diverge {
            fill: theme-color(primary);
        }

        @at-root .teaser-diverge--yellow .icon--diverge {
            fill: $yellow;
        }

        @at-root .teaser-diverge--grey .icon--diverge {
            fill: $grey-400;
        }
    }

    .teaser-body {
        padding: 0;

        .card-title {
            font-weight: $font-weight-medium;
            color: $grey-800 !important;
            text-shadow: none;
        }

        .card-text,
        p,
        a,
        li {
            color: $grey-500 !important;
            text-shadow: none;
        }
    }

    .btn {
        @include background-image(linear-gradient(90deg, rgba($grey-700, 1) 0, rgba($grey-900, 1) 100%) !important);

        position: absolute;
        bottom: -20px;
        left: rem(30px);
        z-index: 20;

        .icon {
            transition: fill 250ms ease-in-out;
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            @include background-image(linear-gradient(0deg, rgba($grey-400, 1) 0, rgba($grey-100, 1) 100%) !important);

            cursor: pointer;

            .icon {
                fill: theme-color(primary);
            }
        }
    }

    &--dark,
    &--blue,
    &--black {
        .card-text,
        p,
        a,
        li {
            color: $grey-500 !important;
            text-shadow: none;
        }
    }
}
