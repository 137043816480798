@import 'nouislider/distribute/nouislider';

.scrollytelling-dragger {
    width: 21.75rem;
    transition: width 250ms, bottom 400ms linear 750ms;

    @include media-breakpoint-up(md) {
        &:not(:hover).is-collapsed {
            width: rem(100px);

            .scrollytelling-dragger__label {
                opacity: 0;
            }
        }
    }

    .noUi-target {
        display: flex;
        height: 3.25rem;
        opacity: 0.8;
        padding: 0 1.75rem 0 5rem;
        border: 0;
        border-radius: 2rem;
        background: linear-gradient(270deg, $brand-primary-light, $brand-primary);
        box-shadow: none;
    }

    .noUi-handle {
        top: -1rem;
        display: inline-flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 5.25rem;
        height: 5.25rem;
        border: 0;
        border-radius: 50%;
        background-color: rgba($white, 0.2);
        box-shadow: 0 0 1.25rem 0 rgba($black, 0.25) !important;
        cursor: pointer;

        &:focus,
        &:active {
            box-shadow: 0 0 1.25rem 0 rgba($black, 0.75) !important;
        }

        &::before {
            content: none;
        }

        &::after {
            content: none;
        }
    }

    .noUi-touch-area {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        width: 4rem;
        border-radius: inherit;
        background-color: $white;

        &,
        &:focus,
        &:active {
            box-shadow: 0 0 1.25rem 0 rgba($black, 0.5) !important;
        }

        svg {
            width: 2.75rem;
            height: 2.75rem;
            stroke: $brand-secondary;
        }
    }

    &.is-open .noUi-touch-area > svg {
        transform: rotate(180deg);
    }

    .noUi-connects {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        color: $white;
    }

    &.is-open .noUi-connects {
        margin-left: -3.5rem;
    }

    &__label {
        margin: auto;
        margin-right: 0;
        font-size: rem(18px);
        font-weight: $font-weight-medium;

        .scrollytelling-dragger.is-open & {
            margin-right: auto;
            margin-left: 0;
        }
    }
}
