$hotspot-detail-close-z-index: 6;
$hotspot-detail-overlay-z-index: 5;
$hotspot-button-label-z-index: 3;
$hotspot-point-z-index: 2;
$hotspot-decoration-button-z-index: 2;

.hotspot {
    position: relative;

    .hotspot-detail {
        z-index: $hotspot-detail-overlay-z-index;
        @include fill-parent-absolute;
    }
}

.hotspot-intro {
    position: relative;

    &__image,
    &__overlay {
        @include fill-parent-absolute;
    }

    &__overlay {
        background-image: radial-gradient(circle, rgba($black, 0.1), rgba($black, 0.4) 50%, rgba($black, 0.75));
    }

    &__container {
        position: relative;
        min-height: 80vh;
        padding: 4rem 0;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            padding-top: 8rem;
            padding-right: 6rem;
            padding-left: 6rem;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            width: 75%;
            padding: 0;
        }

        .hotspot-button__border {
            &::after {
                border: none;
            }
        }
    }

    &__details {
        text-align: center;

        @include media-breakpoint-up(lg) {
            @include align-vertical(absolute);

            top: 45%; // some top offset from center
            width: 50%;
            text-align: left;
            margin-right: auto;
        }
    }

    &-text-padding {
        @include media-breakpoint-up(lg) {
            padding-right: 4rem;
        }
    }

    &__button-decoration-wrapper {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 5rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        .hotspot-button {
            z-index: $hotspot-decoration-button-z-index;
            margin-right: auto;
            margin-left: auto;

            @include media-breakpoint-up(lg) {
                @include align-center(absolute);
            }
        }
    }
}

.hotspot-map {
    position: relative;

    @include media-breakpoint-down(lg) {
        overflow-x: auto;

        .hotspot.is-detail-open & {
            overflow-x: hidden;
        }
    }

    &__container {
        position: relative;
        height: rem(600px);

        @include media-breakpoint-up(lg) {
            height: auto;
        }
    }

    &__image {
        height: 100%;

        @include media-breakpoint-up(lg) {
            width: 100%;
            height: auto;
        }
    }

    &__point {
        z-index: $hotspot-point-z-index;
        position: absolute;
        transform: translate(-50%, -50%);
    }

    @keyframes hotspotMapScrollGesture {
        0% {
            transform: translateX(-50%) rotate(-5deg);
        }

        25% {
            transform: translateX(-50%) rotate(5deg);
        }

        100% {
            transform: translateX(-50%) rotate(-5deg);
        }
    }

    &__scroll-indicator {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        opacity: 1;
        transform: translateX(-50%);
        margin-bottom: 3rem;
        color: $white;
        pointer-events: none;
        animation: hotspotMapScrollGesture 1s infinite;
        transition: opacity 300ms ease-in;

        &.has-scrolled {
            opacity: 0;
        }

        > svg {
            width: rem(95px);
            height: rem(95px);
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.hotspot-detail {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $grey-800;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    &__title {
        font-size: 1.5rem;

        @include media-breakpoint-up(lg) {
            font-size: 3rem;
        }
    }

    &__description {
        p {
            color: inherit;
        }
    }

    &__close {
        z-index: $hotspot-detail-close-z-index;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%); // place midway on top of border

        @include media-breakpoint-up(lg) {
            top: 2rem;
            transform: translateX(-50%);
        }

        &-label {
            position: absolute;
            top: 50%;
            right: 135%;
            transform: translateY(-50%);
            display: none; // hide on mobile
            color: rgba($white, 0.6);
            font-size: $font-size-lg;
            letter-spacing: 1px;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                display: inline-block;
            }
        }

        .hotspot-button {
            box-shadow: 0 2px 4px 0 rgba($black, 0.5), 0 0 40px 0 rgba($black, 0.7);

            > .icon {
                width: 100%;
            }
        }
    }

    &__container {
        position: relative;
        flex: 0 0 50%;
        display: flex;
        height: 50%;

        @include media-breakpoint-up(lg) {
            max-width: 50%;
            height: auto;
            align-items: center;
            padding-right: 8rem;
        }
    }

    &__content {
        flex-direction: column;
        padding: 0 3rem 1rem;
        margin-top: auto;

        @include media-breakpoint-up(lg) {
            width: 60%;
            padding: 0;
            margin-top: 0;
            margin-left: auto;
        }
    }

    &__overlay {
        @include fill-parent-absolute;

        opacity: 0.1;
        background-image: radial-gradient(circle, $white, $grey-300 50.61%, $black);
        pointer-events: none;
    }

    &__image {
        flex: 0 0 50%;
        height: 50%;

        @include media-breakpoint-up(lg) {
            max-width: 50%;
            height: auto;
        }
    }
}
