/**
* Font Inter
**/
@font-face {
    font-family: Inter;
    font-stretch: 50% 100%; /* Note that font-stretch is a % of normal width */
    // font-style: NORMAL_OR_ITALIC; /* See note below */
    font-weight: 200 700;
    display: swap;
    src: url('/assets/fonts/inter/Inter-VariableFont_opsz\,wght.woff2') format('woff2');
}
/*
Due to some inconsistencies with how browsers handle variable font files that contain both italic and upright
variants (and also files that contain both italic and slant axes), in these scenarios it’s best to omit
the font-style line completely.
*/

@font-face {
    font-family: icomoon;
    src:
        url('/assets/fonts/icomoon/icomoon.woff2') format('woff2'),
        url('/assets/fonts/icomoon/icomoon.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: fontawesome;
    src:
        url('/assets/fonts/fontawesome/fontawesome-webfont.woff2') format('woff2'),
        url('/assets/fonts/fontawesome/fontawesome-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: fallback;
}

// sass-lint:enable-all
