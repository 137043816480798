$catwalk-build-up-reveal-layer: 7;
$catwalk-progress-z-index: 6;
$catwalk-details-container-z-index: 5;
$catwalk-image-gradient-z-index: 4;
$catwalk-objects-image-layer-z-index: 3;
$catwalk-tarmac-image-layer-z-index: 2;
$catwalk-sky-image-layer-z-index: 1;
$catwalk-sky-image-layer-transform-max: 40px;
$catwalk-image-aspect-ratio: 16 * 0.125;
$catwalk-details-transition-duration: 1500ms;

.catwalk {
    position: relative;
    overflow: hidden;

    &__image-wrapper {
        $image-wrapper-height-mobile: rem(600px);

        height: $image-wrapper-height-mobile;
        width: calc(#{$image-wrapper-height-mobile} * #{$catwalk-image-aspect-ratio});

        @include media-breakpoint-up(md) {
            $image-wrapper-height-tablet: rem(800px);

            height: $image-wrapper-height-tablet;
            width: calc(#{$image-wrapper-height-tablet} * #{$catwalk-image-aspect-ratio});
        }

        @include media-breakpoint-up(lg) {
            width: auto;
            height: calc(100vw / #{$catwalk-image-aspect-ratio});
        }
    }

    &__build-up-reveal-layer {
        z-index: $catwalk-build-up-reveal-layer;
        position: absolute;
        background-color: $grey-800;
        width: 300vw;
        height: 100%;
        transform: translateX(-30%) skewX(-45deg);
        transition: transform 2s ease-out;

        @include media-breakpoint-up(lg) {
            transform: translateX(-30%) skewX(-70deg);
        }

        .catwalk.is-building-up &,
        .catwalk.build-up-finished & {
            transform: translateX(75%) skewX(-45deg);

            @include media-breakpoint-up(lg) {
                transform: translateX(75%) skewX(-70deg);
            }
        }
    }

    &.is-building-up {
        .catwalk__image-layer {
            transition-duration: 3s;

            @include media-breakpoint-up(lg) {
                &--sky {
                    animation: imageSkyLayerParallex 2.5s ease-out;
                    animation-fill-mode: forwards;
                }
            }
        }

        .swiper-slide.is-active-desktop {
            @include media-breakpoint-up(lg) {
                transition-duration: $catwalk-details-transition-duration;
            }
        }
    }

    &.build-up-finished {
        .swiper-container {
            opacity: 1;
        }

        .swiper-slide {
            @include media-breakpoint-up(lg) {
                opacity: 0.5;
                overflow: hidden;
                width: 20%;
                min-width: 20rem;
            }
        }

        .catwalk-details {
            animation: showUp $catwalk-details-transition-duration ease-out;

            @include media-breakpoint-up(lg) {
                animation: none;
                opacity: 1;
                transform: translateX(0);
                transition: transform $catwalk-details-transition-duration ease-out $catwalk-details-transition-duration,
                    opacity $catwalk-details-transition-duration ease-out $catwalk-details-transition-duration;
            }
        }

        .catwalk__progress {
            opacity: 1;
        }
    }

    .swiper-container {
        z-index: $catwalk-details-container-z-index;
        position: absolute;
        top: 0;
        right: 0;
        left: 15%; // scalable space on the left until white border
        display: flex;
        align-items: flex-end;
        width: auto;
        height: 100%;
        opacity: 0; // prepare for build-up
        transition: opacity $catwalk-details-transition-duration;

        @include media-breakpoint-up(lg) {
            left: 0;
        }
    }

    .swiper-wrapper {
        height: auto;

        @include media-breakpoint-up(lg) {
            align-items: flex-end;
            justify-content: center;
            background-image: linear-gradient(180deg, transparent, rgba($black, 0.6));
        }
    }

    .swiper-slide {
        opacity: 0;
        height: auto;
        transition: opacity 500ms;

        &-active {
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            width: 1px; // prepare for build-up
            min-width: 1px; // prepare for build-up
            overflow: hidden;
            border-left: 1px solid $white;
            transition: transform $catwalk-details-transition-duration, opacity $catwalk-details-transition-duration,
                width $catwalk-details-transition-duration ease-out,
                min-width $catwalk-details-transition-duration ease-out;

            &.is-active-desktop {
                opacity: 1;
                transition-duration: 500ms;
            }
        }
    }

    &__image-layer {
        position: absolute;
        left: 0;
        min-width: 100%;
        height: 100%;
        max-height: 100%;
        transition: transform 1.25s;

        &--objects {
            z-index: $catwalk-objects-image-layer-z-index;
        }

        &--tarmac {
            z-index: $catwalk-tarmac-image-layer-z-index;
        }

        &--sky {
            z-index: $catwalk-sky-image-layer-z-index;

            @include media-breakpoint-up(lg) {
                // to make sure transform isn't moving the image inside the viewport
                width: calc(100% + #{$catwalk-sky-image-layer-transform-max * 2});
                margin-left: rem(-$catwalk-sky-image-layer-transform-max);
            }
        }

        @include media-breakpoint-up(lg) {
            // image layer transform is not important on desktop
            transform: none;
            transition: transform 100ms;
        }
    }

    &__gradient {
        z-index: $catwalk-image-gradient-z-index;
        position: absolute;
        opacity: 0;
        height: 100%;
        transition: opacity 500ms ease-in;

        &--right {
            top: 0;
            right: 0;
            width: 20%;
            background-image: linear-gradient(90deg, transparent, rgba($black, 0.4));
        }

        &--left {
            top: 0;
            left: 0;
            width: 20%;
            background-image: linear-gradient(90deg, rgba($black, 0.4), transparent);
        }

        &--bottom {
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(180deg, transparent, $black);
        }

        &.is-active {
            opacity: 1;
        }
    }

    &-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding: 0 2rem 4rem;
        color: $white;

        @include media-breakpoint-up(lg) {
            opacity: 0; // prepare for build-up
            transform: translateX(-100%); // prepare for build-up
            padding-bottom: 3rem;
        }

        &__divider {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 1px;

            // height is styled inline
            background-color: $white;
        }

        &__title {
            margin-top: 0;
            margin-bottom: 1rem;
            font-size: rem(80px);
            line-height: 1;
        }

        &__list {
            width: 100%;

            li {
                margin-bottom: rem(20px);
                font-size: $font-size-lg;

                @include media-breakpoint-up(lg) {
                    margin-bottom: rem(40px);
                    font-size: rem(22px);
                }
            }
        }

        .btn {
            margin-top: auto;
        }
    }

    &__progress {
        z-index: $catwalk-progress-z-index;
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        opacity: 0; // prepare for build-up
        transition: opacity $catwalk-details-transition-duration;
    }

    &__progress-dot {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        margin-left: 0.5rem;
        background-color: rgba($white, 0.5);
        transition: background-color 300ms;

        &.is-active {
            background-color: $white;
        }
    }
}

@keyframes imageSkyLayerParallex {
    0% {
        transform: translateX(-$catwalk-sky-image-layer-transform-max);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes showUp {
    0% {
        opacity: 0;
        transform: translateX(-85%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
