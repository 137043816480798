$flag-icon-css-path: '/assets/images/common/flags';

@import 'flag-icon-css/sass/flag-icon';

.flag-icon {
    width: rem(24px);
    height: rem(18px);
    border: 1px solid $grey-200;
    line-height: 0;
    background-size: cover;

    &::before {
        content: '';
    }

    &-rounded {
        @extend .flag-icon-squared;

        border-radius: 50%;
        width: rem(32px) !important;
        height: rem(32px) !important;
    }
}
