.interaction {
    ul {
        border-top: 1px solid #999;
        padding: 0.5rem 0;

        li {
            display: inline-block;
            margin-right: 2rem;

            a {
                padding: 0 0.5rem;
                background: #ccc;
            }
        }
    }
}

.firststep {
    display: none;
    overflow: hidden;
    padding-bottom: rem(78px);

    &.is-active {
        display: block;
    }
}

.mainpanel {
    opacity: 0;
    transition: opacity 300ms ease;

    @include media-breakpoint-up(lg) {
        width: 75%;
    }

    &.is-active {
        opacity: 1;
    }
}

.results-message {
    text-align: center;
    padding: 4rem;
    display: none;

    &.is-active {
        display: block;
    }
}

.premodelselectionstep {
    display: none;
    padding-bottom: rem(78px);

    @include media-breakpoint-up(xl) {
        position: relative;
    }

    &.is-active {
        display: block;
    }

    &.premodelselectionstep-results {
        $result-height: 78vh;
        $result-height-md: 480px; // In pixels because of IOS
        $result-top-md: 6rem;
        $result-height-lg: 70vh;
        $result-height-xl: 78vh;
        $result-top-xl: 6rem;

        .mainpanel {
            width: 100%;

            @include media-breakpoint-up(xl) {
                top: $result-top-xl;
            }

            .results-title {
                margin-bottom: 1rem;
            }

            .results-noresults {
                display: none;
                margin-top: 3rem;
                font-size: 1.5rem;

                &.is-active {
                    display: block;
                }
            }

            .modelimage {
                width: 7rem;
                @include media-breakpoint-up(xl) {
                    width: 9rem;
                }
            }

            .btn-column {
                padding-top: rem(9px);

                .select-model-btn {
                    display: none;
                    margin: 0;
                    transition:
                        background-color 300ms ease,
                        color 300ms ease;
                    @extend .btn;
                    @extend .btn-dark;

                    @include media-breakpoint-up(xl) {
                        display: inline;
                    }
                }
            }
        }

        .sidepanels {
            background: $brand-primary;

            @include media-breakpoint-up(xl) {
                width: 25%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
            }

            &.totalscroll {
                overflow-x: hidden;
                overflow-y: scroll;

                .sidepanel-main {
                    height: auto;
                    overflow: hidden;
                }
            }

            .filterset {
                a {
                    &.filterset-item {
                        &.is-incompatible {
                            display: none;
                        }
                    }
                }
            }

            .sidepanel-main {
                height: auto;
                overflow: hidden;
            }
        }
    }

    .results-table {
        tbody {
            tr {
                cursor: pointer;
            }
        }

        tr {
            height: 2.875rem;
            font-size: 0.875rem;
            @include media-breakpoint-up(xl) {
                font-size: 1rem;
            }

            &.is-inactive {
                opacity: 0.2;
            }

            th {
                .order-toggle {
                    color: $grey-600;
                    @extend .otc-icon;

                    @include media-breakpoint-up(md) {
                        font-size: 0.875rem;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 1rem;
                    }

                    &::before {
                        right: -1rem;
                        top: 0.125rem;
                    }

                    &:hover {
                        color: $black;
                        text-decoration: none;
                    }
                }

                &.sorting-asc {
                    .order-toggle {
                        color: $grey-800;
                        @extend .icon-up;

                        &::before {
                            right: -1rem;
                            top: 0.125rem;
                        }
                    }
                }

                &.sorting-desc {
                    .order-toggle {
                        color: $grey-800;
                        @extend .icon-down;

                        &::before {
                            right: -1rem;
                            top: 0.125rem;
                        }
                    }
                }
            }

            td {
                &.axlesideview {
                    height: 4rem;
                }
            }

            &:hover {
                .select-model-btn {
                    opacity: 1;
                }
            }
        }
    }
}

.detail-main {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;

    .detail-maincontent {
        margin-top: 6rem;

        .detail-titleblock {
            width: 25%;
            position: absolute;
            top: 0;
            left: 0;
            margin-left: 3rem;
            transform: translateX(-100%);
            transition: transform 300ms ease;

            .detail-advantage-title {
                margin: 1rem 0;
            }

            .detail-list-advantages {
                @extend .otc-list-checks;
            }
        }

        .detail-infoblock {
            width: 25%;
            position: absolute;
            top: 0;
            right: 0;
            margin: 2.5rem 3rem 0 0;
            transform: translateX(120%);
            transition: transform 300ms ease;
            padding: 2rem;
            background: rgba($white, 0.9);
            border-radius: $r;

            .detail-include-title {
                margin-bottom: 1rem;
            }

            .detail-list-includes {
                @extend .otc-list-bullets;
            }

            .detail-price {
                margin: 1rem 0;
                text-align: center;
                @extend strong;

                .currency {
                    font-size: 1.5rem;
                    position: relative;
                    top: -0.25em;
                    left: -0.25em;
                }

                .amount {
                    font-size: 2rem;
                }
            }

            .detail-button-wrapper {
                position: absolute;
                width: 100%;
                bottom: -4rem;
                left: 0;

                .btn-configure-wrapper {
                    text-align: center;
                    margin-top: 2rem;

                    .btn-configure {
                        @extend .btn;
                        @extend .btn-dark;

                        margin-bottom: 1rem;
                    }
                }

                .btn-contactdealer-wrapper {
                    margin-top: rem(27px);
                    text-align: center;

                    .btn-contactdealer {
                        color: $brand-primary;
                        @extend strong;
                    }
                }
            }
        }
    }

    &.is-active {
        display: block;

        .detail-maincontent {
            &.is-active {
                .detail-titleblock {
                    transform: translateX(0);
                }

                .detail-infoblock {
                    transform: translateX(0);
                }
            }
        }
    }
}

.postmodelselection {
    display: none;

    &.is-active {
        display: block;
    }

    .configuration-content-wrapper {
        a {
            color: $white;
        }

        .sidepanels {
            .sidepanel-main {
                > li {
                    &.is-ready {
                        display: none;
                    }

                    &.is-visible {
                        display: block;
                    }
                }
            }
        }
    }

    .sidepanels {
        .sidepanel-main {
            overflow-y: scroll;
            max-height: calc(100dvh - 194px);
            @include media-breakpoint-down(md) {
                max-height: calc(100dvh - 188px);
            }
        }
    }
}

.button-wrapper {
    // Only not showing on mobile or can we just delete the whole thing?
    // @include media-breakpoint-down(sm) {
    display: none !important;

    // }
    background-color: $grey-100;
    text-align: center;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: rem(10px) rem(16px);
    z-index: 15;

    .otc-continue__btn,
    .otc-loader__btn {
        width: calc(50% - #{rem(6px)});
        max-width: rem(250px);
    }

    .otc-loader__btn {
        margin-right: rem(12px);
    }

    .otc-loader {
        width: calc(50% - #{rem(6px)});
        max-width: rem(250px);
        margin-right: rem(12px);
    }
}

header {
    height: $header-height;
    background: $grey-100;
    width: 100%;

    a {
        color: $grey-600;
        fill: $grey-600;
        transition: color 200ms ease !important;

        &:hover {
            color: $grey-700;
            fill: $grey-600;
        }
    }

    .logo-wrapper {
        height: 100%;
        float: left;
        padding-left: rem(15px);
        padding-right: 0.5rem;
        display: flex;

        .logo {
            position: relative;
            display: flex;
            height: 100%;

            img {
                display: block;
                width: rem(91px);
                padding-right: rem(10px);
                border-right: rem(1px) solid $grey-400;
                height: 100%;
            }

            .header-title {
                position: relative;
                top: unset;
                overflow: hidden;
                display: none;
                font-size: rem(14px);
                margin: auto 0 auto 16px;
                line-height: 1.5;
                color: $brand-truck-configurator-blue;
                font-weight: 400;

                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }
        }
    }

    .header-nav-wrapper {
        float: right;

        .header-nav {
            float: right;

            li {
                display: none;
                float: left;
                height: $header-height;
                border-left: $border-default;
                justify-content: center;
                align-items: center;
                padding: 0 rem(15px);
                font-weight: $font-weight-bold;
                font-size: rem(20px);
                line-height: rem(25px);

                &:not(.header-send) {
                    color: $grey-600;
                    fill: $grey-600;
                }

                a.btn--dealerlocator span {
                    font-weight: $font-weight-medium;
                    font-size: rem(20px);
                    vertical-align: middle;
                }

                &:hover {
                    color: $grey-700;
                    fill: $grey-700;
                    background-color: $grey-300;
                }

                &.is-active {
                    display: flex;
                }

                &.header-contact {
                    .icon {
                        fill: $brand-primary;
                        display: block;

                        &:hover {
                            fill: theme-color(secondary) !important;
                        }
                    }
                }

                &.header-send {
                    background-color: $brand-primary;
                    color: $white;
                    border-radius: 0;
                }

                &.header-save,
                &.header-load,
                &.header-send,
                &.button-summary,
                &.header-dealerlocator {
                    a {
                        &::before {
                            padding-right: 0;
                        }

                        @media (min-width: $lg) {
                            width: 100%;

                            &::before {
                                padding-right: 0.5rem;
                            }
                        }

                        &:not(.dealersendlink) {
                            span.desktop-only {
                                @include media-breakpoint-down(lg) {
                                    display: none;
                                }
                            }
                        }

                        &.dealersendlink {
                            span.desktop-only {
                                display: none;
                                @include media-breakpoint-up(sm) {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $footer-height;
    color: $brand-primary;
    z-index: 100;
    transform: translateY(100%);
    transition: transform 300ms ease;

    @include media-breakpoint-down(sm) {
        height: $footer-height-mobile;
    }

    &.is-disabled {
        pointer-events: none;
    }

    &.is-active {
        transform: translateY(0);
        z-index: 1;
    }
}

.footer-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: transform 300ms ease;
    border-top: $border-default;
    background: $grey-100;
    z-index: 10;
    display: none;

    &.is-active {
        display: block;
        transform: translateY(0);
    }
}

footer.is-disabled .footer-nav-wrapper {
    opacity: 0.5;
}

.footer-pre-model {
    .footer-nav-wrapper {
        width: 100%;

        .footer-nav {
            margin: auto;
            height: 100%;

            li {
                height: 100%;

                a {
                    text-align: center;
                }
            }
        }
    }
}

.footer-post-model {
    background-color: $white;

    .footer-nav-wrapper {
        display: flex;

        .active-background {
            display: none;
            @include media-breakpoint-up(xl) {
                display: block;
            }
        }

        &.is-active {
            transform: translateY(0);
        }
    }

    .footer-nav {
        color: $brand-primary;
        display: flex;
        height: 100%;

        @include media-breakpoint-up(sm) {
            background: $white;
        }

        .footer-shadow {
            width: 100%;
            height: 100%;
            position: fixed;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
            background-size: 35%;
            background-repeat: no-repeat;
            background-position: right;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    li {
        float: none;
        @include media-breakpoint-up(xl) {
            float: left;
        }

        &:not(:first-child, :last-child) {
            border-right: $border-default;
        }

        &.footer-home {
            a {
                &::before {
                    text-align: left;
                    @include media-breakpoint-up(xl) {
                        line-height: $footer-height;
                        text-align: center;
                    }
                }
            }
        }

        a {
            @include media-breakpoint-up(xl) {
                line-height: $footer-height;
                height: $footer-height;
            }
        }

        &.is-active a {
            color: $white;
            background-color: $brand-primary;
        }
    }

    .button-summary {
        @extend .icon-font !optional;
        @extend .icon-up !optional;

        display: block;
        width: $panelwidth-main-md;
        height: $footer-height;
        float: right;
        line-height: $footer-height;
        padding: 0 3.875rem 0 1.875rem;
        background: $brand-primary;
        color: $white;

        @include media-breakpoint-up(xl) {
            width: $panelwidth-main-xl;
        }

        &::before {
            line-height: $footer-height;
            color: $white;
            top: 0;
            right: 1.875rem;
            width: 1rem;
            text-align: center;
        }

        &.is-active {
            @extend .icon-up-rotate;

            &::before {
                top: 0.125em;
            }
        }
    }
}

.footer-nav-wrapper {
    width: 100%;
    background: inherit;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
        justify-content: center;
        overflow-x: unset;
    }
}

.otc-previous__btn {
    font-size: rem(20px);
    margin: auto;
    background-image: none !important;
    background-color: transparent;
    box-shadow: none;
    color: $brand-primary;
    height: 100%;
    border-left: $border-default;
    border-right: $border-default;
    border-radius: 0;

    div {
        padding-left: rem(40px);
    }

    .icon {
        fill: $brand-primary;
        margin-right: rem(8px);
        height: 100%;
    }

    &:hover {
        background-color: $grey-300;
    }
}

.otc-continue__btn {
    margin-left: auto;
    width: 50%;
    max-width: rem(250px);
}

.footer-nav {
    @extend .horizontal-list;

    position: relative;

    > li {
        &.footer-nav-template {
            display: none;
        }

        &.is-disabled {
            a {
                cursor: default;
                color: $grey-600;

                &:hover {
                    background: $grey-100;
                    color: $grey-600;
                }
            }
        }

        a {
            padding: 0 1rem;
            color: inherit;
            transition:
                background 300ms ease,
                color 300ms ease;
            overflow: hidden;
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;

            @include media-breakpoint-up(xl) {
                padding: 0 rem(25px);
            }

            &:hover {
                background: rgba($brand-primary, 0.8);
                color: $white;

                .icon {
                    fill: $white;
                }
            }
        }

        &.footer-home {
            > a {
                @extend .icon-no-label;

                &::before {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    font-size: 1.75rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .premodelsection-back-nav {
                background: rgba($white, 0.9);
                position: absolute;
                left: 1rem;
                bottom: 2rem;
                transform: translateX(-150%);
                transition: transform 300ms ease;

                &::before {
                    content: ' ';
                    position: absolute;
                    left: 2.5rem;
                    bottom: -1rem;
                    border: 0.5rem dashed transparent;
                    border-top: 0.5rem solid rgba($white, 0.9);
                }

                &.is-active {
                    transform: translateX(0);
                }

                .premodelsection-back-nav-template {
                    display: none;
                }

                li {
                    border-bottom: $border-default;

                    &:last-child {
                        border: 0;
                    }

                    a {
                        @extend .otc-icon;
                        @extend .icon-back;
                        @extend strong;

                        padding-left: 3rem;
                        color: $brand-primary;

                        &::before {
                            top: 1.625rem;
                            left: 1rem;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        &:hover {
                            background: none;
                            text-decoration: underline;

                            &::before {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// in mobile view on post-model page, we don't want the footer to be positioned
// sticky at the bottom, but simply in the regular flow
@include media-breakpoint-down(s) {
    footer:has(.footer-post-model.is-active) {
        position: static;
        height: auto;

        .footer-post-model {
            position: static;
            border: none;
            height: auto;
            padding: 5rem 18px 2rem; // 18px is same as visualisation-container
            background: $grey-100;

            .footer-nav-wrapper {
                display: block;
            }

            .footer-nav {
                display: block;
                height: auto;
            }

            .footer-shadow {
                display: none;
            }

            li:not(.footer-nav-template) {
                display: block;
                margin-bottom: 0.5rem;
                border: none;
                text-align: left;

                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    background: $white;
                    border-radius: 4px;
                    padding: 0.5625rem 1rem;
                    color: $black;
                    font-weight: bold;
                    font-size: 0.875rem; // 14px;

                    &::after {
                        display: block;
                        color: $brand-primary;

                        // we can't use @extend inside media query,
                        // so define basics of icon-next here
                        content: '\f105';
                        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                        font-family: fontawesome;
                    }
                }
            }
        }
    }
}

.summary {
    transition: transform 600ms ease;
    transform: translateY(100vh);
    background: $white;
    text-align: left;

    .summary-inner {
        width: 100%;
        margin: 0 auto;

        &__title {
            font-weight: 300;
            padding: 32px 0;
            margin: 0;
        }

        li {
            line-height: 1.75;
            margin-bottom: 0.75rem;
            margin-left: 0.75rem;
        }

        .summary-truck-image-wrapper {
            width: 100%;
            height: 200px;
            text-align: center;
            opacity: 0;
            transition: opacity 300ms ease;

            .summary-truck-image {
                display: block;
                height: 100%;
                margin: 0 auto;
                border: 1px solid #000;
            }

            &.is-active {
                opacity: 1;
            }
        }

        .summary-title {
            width: 80%;
            float: left;

            + .seperator {
                clear: both;
            }
        }

        .no-summary {
            display: none;

            &.is-active {
                display: block;
            }
        }

        .summary-content {
            overflow-y: auto;
        }

        .summary-category {
            clear: both;
            overflow: hidden;
            padding-bottom: 2rem;

            &:last-child {
                padding-bottom: 0;
            }

            .summary-category-title {
                font-weight: $font-weight-bold;
                margin-bottom: 2rem;
            }

            .summary-featuregroup-items {
                width: 100%;

                @include media-breakpoint-up(sm) {
                    padding-top: 0;
                    width: 80%;
                    float: left;
                }

                .summary-featuregroup-item {
                    @extend .with-bullets;

                    > .summary-item {
                        overflow: hidden;

                        .summary-item-title {
                            width: 60%;
                            float: left;

                            @include media-breakpoint-up(sm) {
                                width: 80%;
                            }
                        }

                        .summary-item-id {
                            text-align: right;
                            width: 40%;
                            max-width: 6rem;
                            float: right;

                            @include media-breakpoint-up(sm) {
                                width: 20%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.send-to-dealer-overlay {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110000;
    transition:
        transform 600ms ease,
        background-color 600ms ease;
    background-color: transparent;

    h1 {
        font-size: 2rem;
        font-weight: 400;
    }

    &.is-active {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        width: 100%;
        max-width: 100% !important;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 120000;
        max-height: 80%;
        height: 100%;
        width: inherit;
        max-width: 750px;

        @include media-breakpoint-down(sm) {
            max-height: 100%;
        }

        .send-to-dealer-overlay-inner {
            width: auto;
            max-width: 900px;
            max-height: 100%;
            position: relative;
            overflow: auto;
            background-color: $white;
            border-radius: 2px;
            padding: 4rem 2rem;

            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 100%;
                border-radius: 0;
                padding: 4rem 2rem;
            }

            .form-check-label,
            .custom-control-label {
                color: $grey-900 !important;
            }

            .form-row,
            .row,
            .form-group:not(.form-group--list) {
                .invalid-feedback--msg {
                    margin: 0;
                    position: initial;
                    padding: rem(8px) 0 0 0 !important;
                    font-size: rem(14px);
                    pointer-events: none;
                    @include line-height(18px, 14px);

                    @include media-breakpoint-up(md) {
                        align-items: center;
                    }
                }
            }

            .form-row,
            .row {
                .input-validation-wrapper {
                    position: relative;

                    .invalid,
                    .valid,
                    .help {
                        &-feedback {
                            &.icon {
                                position: absolute;
                                top: 0;
                                right: 0;
                                z-index: 10;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .field-validation-error {
                &.help-block {
                    color: theme-color(danger);
                }

                &.alert-danger {
                    padding-left: 0.75rem;
                }
            }
        }

        .icon-close-wrapper {
            position: absolute;
            top: -50px;
            right: 0;
            padding: 1rem;
            cursor: pointer;
            z-index: 130000;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border: 2px solid #fff;
            border-radius: 50%;
            background-color: transparent;
            box-sizing: border-box;

            @include media-breakpoint-down(sm) {
                top: 10px;
                right: 15px;
                border: 2px solid #000;
            }

            .close-send-to-dealer-form {
                color: #fff;

                &::before {
                    line-height: 2;
                    font-weight: 700;
                }

                @include media-breakpoint-down(sm) {
                    color: #353c45;
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #c2c9d2;
}
