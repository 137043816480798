.vacancies-search {
    .loader {
        display: none;
    }

    .custom-border-bottom {
        border-bottom: $border-default;
    }

    &__filters {
        [class*='col-'] {
            margin: 0;
        }

        margin-bottom: 10px;

        .label {
            font-weight: 500;
            display: block;
            margin-bottom: 5px;
            font-size: rem(13px);
        }

        .form-row {
            margin-bottom: 10px;
        }

        input {
            margin-bottom: 10px;
        }

        .btn {
            &-primary {
                font-size: rem(14px);
                padding: rem(4px) rem(8px);

                @include media-breakpoint-up(lg) {
                    font-size: rem(16px);
                    padding: rem(8px) rem(16px);
                }
            }
        }
    }

    &__results {
        thead {
            th {
                vertical-align: top;
            }
        }

        .vacancy-subtitle {
            display: block;
            font-style: italic;
            font-size: 90%;
        }

        .first-col {
            width: 62%;
        }

        .second-col {
            width: 19%;
        }

        .third-col {
            width: 19%;
        }

        .vacancy-item {
            background-color: $white;
            padding: 10px;
            margin-bottom: 10px;
            border-bottom: solid 2px $grey-300;
            position: relative;
            width: 100%;

            [class*='col-'] {
                margin: 0;
            }

            &:hover {
                box-shadow: 0 5px 25px -10px rgba(0, 0, 0, 0.25);
            }

            &__link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &__content {
                .content-title {
                    margin-bottom: rem(10px);
                    font-size: rem(20px);
                }
            }

            &__meta {
                > ul {
                    margin-bottom: 0;

                    li {
                        position: relative;
                    }
                }

                .icon {
                    position: absolute;
                    top: 3px;
                    right: 0;
                    color: $grey-500;
                    fill: $grey-500;
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                }

                .label {
                    display: inline-block;
                    font-size: $font-size-sm;
                    margin-bottom: 0;
                    padding-right: 25px;
                }
            }
        }
    }

    &__select {
        margin-bottom: 15px;
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .vacancy-item {
        .icon {
            fill: $grey-500;
        }

        p {
            color: $grey-500 !important;
        }
    }
}
