form {
    background: transparent;
}

a {
    text-decoration: none;
    color: $brand-primary;
    cursor: pointer;
    transition: color 200ms ease, background 200ms ease;

    .hover,
    &:hover {
        text-decoration: none;
    }
}

h3 {
    font-size: 1.125rem;
    line-height: 1.25;
}

.btn:not(.btn-primary) {
    background: $white;
    color: $brand-primary;
    border: none;
    transition: color 200ms ease, background 200ms ease, padding 200ms ease, border 200ms ease;
    min-height: 0;

    &:hover {
        background: $brand-primary;
        color: $white;
        border: none;
        fill: $white;
    }

    &.is-inactive {
        background: $grey-400;
        color: $grey-600;
        pointer-events: none;
    }

    &.btn-dark {
        background: $brand-primary;
        color: $white;
        border-color: $brand-primary;

        &:hover {
            background: $brand-primary-dark;
            color: $white;
            border-color: $brand-primary-dark;
        }
    }

    &.btn-ghost {
        background: transparent;
        border: 2px solid $brand-primary;
        color: $brand-primary;

        &:hover,
        &.hover,
        &:active,
        &.active,
        &:focus,
        &.focus {
            color: $white;
        }
    }

    &.btn-ghost-white {
        background: transparent;
        border-color: $white;
        color: $white;

        &:hover {
            background: $white;
            border-color: $white;
            color: $brand-primary;
        }
    }

    &.btn-small {
        padding: 4px 16px;
    }

    &.has-loader {
        padding-right: 4rem;
        position: relative;
        pointer-events: none;

        &::before {
            content: ' ';
            position: absolute;
            top: 1rem;
            right: 30px;
            border: 0.125rem solid transparent;
            border-top: 0.125rem solid $white;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            animation: loaderspin 1s linear infinite;
        }
    }

    &.clipboard-copy {
        .icon {
            margin-right: rem(10px);
            vertical-align: text-bottom;
            cursor: pointer;

            &.copy {
                display: inline-block;
            }

            &.checkmark {
                display: none;
            }
        }

        &.success {
            &:active,
            &:hover {
                background-color: $brand-truck-configurator-success-green;
            }

            .icon {
                &.copy {
                    display: none;
                }

                &.checkmark {
                    display: inline-block;
                }
            }
        }
    }
}

h1 {
    font-size: rem(46px);
    line-height: 1.25;
}

strong:not(.accordion-toggle-title) {
    font-weight: $font-weight-bold;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &.no-bullets {
        list-style: none;
    }

    &.horizontal-list {
        list-style: none;

        > li {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.with-bullets {
        > li {
            position: relative;
            padding-left: 1rem;

            &::before {
                content: ' ';
                position: absolute;
                top: 0.6rem;
                left: 0;
                width: 0.25rem;
                height: 0.25rem;
                background: $brand-primary;
                border-radius: 50%;
            }
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;

    tr {
        border-bottom: $border-default;
        padding: 1rem;

        th,
        td {
            padding: 0.5rem;
            text-align: left;
        }
    }
}

.otc-main {
    section:not(.summary) {
        max-width: rem(990px);
        margin: 0 auto;
    }
}

// I'm not sure why everything regarding the visualisation is this file
// consider moving it to separate file?

// wrapper around the viewer
.visualisation-container {
    --header-height: #{$header-height};
    --footer-height: #{$footer-height};
    --prev-next-btn-width: 2.5rem;
    // define vars that we need for some calculations
    --btn-height: 2rem;
    --controls-padding: 0.25rem;
    --border-width: 1px;
    --controls-height: calc(var(--btn-height) + 2 * (var(--controls-padding) + var(--border-width)));
    --gutter-width-mobile: 18px; // same as on other pages

    position: relative;
    display: none;
    // in older version of code, height was set to calc(100vh - 129px)
    // seems incorrect: header-height + footer-height = 130?
    height: calc(100vh - var(--header-height) - var(--footer-height));
    border-top: $border-default;
    background: $grey-100; // same as used in rendered image

    @include media-breakpoint-down(s) {
        padding: 0 var(--gutter-width-mobile);
        height: auto;
    }

    @include media-breakpoint-down(md) {
        // in older version of code, height was set to calc(100vh - 103px)
        // seems incorrect: header-height + footer-mobile-height = 124?
        --footer-height: #{$footer-height-mobile};
    }

    &.is-active {
        display: block;
    }
}

.visualisationviewer {
    display: none;
    width: 100%;
    overflow: hidden;
    height: 100%;

    @include media-breakpoint-down(s) {
        // on mobile views, make space at the sides for prev/next buttons
        margin: 0 auto;
        height: auto;
        aspect-ratio: 1;
    }

    transition: opacity 300ms ease;

    &.is-active {
        display: block;
    }

    &.is-visible {
        opacity: 1;
    }
}

.swipezone {
    position: absolute;
    inset: 0;
    z-index: 1;
    display: none;

    &.is-active {
        // we only want swipezone to be active when image has loaded
        display: block;
    }
}

.model-name-container {
    display: none;

    &.is-active {
        display: block;
        position: absolute;
        top: 2.5rem;
        width: 80vw;
        text-align: center;
        left: 50%;
        margin-left: -40vw;

        .model-name {
            font-family: inherit;
            font-weight: bold;
            letter-spacing: inherit;
            font-size: 24px;
            @include media-breakpoint-up(lg) {
                height: rem(56px);
                font-size: 36px;
            }
        }
    }
}

// styling of controls and everything within changes a lot between
// breakpoints s and sm. define the general settings for all elements first,
// then group specific settings per breakpoint

.visualisation-controls {
    // contains disclaimer and two controllers: 3d/top and prev/next
    display: none;
    transition: opacity 300ms ease, z-index 300ms ease 300ms;
    opacity: 0;

    &:not(.is-active) {
        pointer-events: none;
    }

    &.is-active {
        display: block;
        opacity: 1;
    }

    &.is-outofsight {
        z-index: 0;
        opacity: 0;
    }
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--controls-height);
    border: $border-default;
    background: $white;
    padding: 0.25rem;
    border-radius: 0.3rem;
    overflow: hidden;

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 100%;
        margin: 0 0.125rem;
    }
}

.controls-turn {
    display: none;
    transition: opacity 300ms ease;
    pointer-events: none;
    flex-direction: row;
    font-size: 1.75rem;
    color: $brand-primary;

    &.is-active {
        display: flex;
        pointer-events: auto;
    }
}

// single control (a inside li)
.control {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: background 300ms ease, color 300ms ease;
    will-change: background, color;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background: $grey-400;
        color: $grey-800;
    }

    &.is-active,
    &:active {
        background: $brand-primary;
        color: $white;
    }
}

.control-turn-left,
.control-turn-right {
    @extend .icon-back;

    width: 2rem;

    &::before {
        position: static; // overrule position absolute that's probably added by @extend
    }
}

.control-turn-right {
    @extend .icon-next;
}

.control-front {
    &.is-active {
        object {
            // 360 icon
            // white color
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
        }
    }

    object {
        pointer-events: none;

        // black color
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }
}

.control-top {
    text-transform: uppercase;
    font-size: rem(18px);
    line-height: rem(22px);
}

// specific styles for all control-related things below sm breakpoint
// here, view-controls are underneath image; turn-controls are on top of image
@include media-breakpoint-down(s) {
    .controls {
        // on larger views, .controls-wrapper has position: absolute,
        // which positions it on top of swipezone. On mobile view, .controls-wrapper
        // must have position: static to be able to position the 360/top and
        // prev/next controls separately. They need to get positioning-context
        // (i.e. relative, absolute) to place them on top of swipezone.
        position: relative;
        z-index: 2;
    }

    .controls-turn {
        justify-content: space-between;
        position: absolute;
        top: calc(50vw - var(--btn-height));
        left: 0;
        right: 0;
        background: none;
        border: none;
        padding: 0;

        li {
            flex: 0;
        }
    }

    .control-turn {
        width: var(--btn-height);
        height: var(--btn-height);
    }

    .control-turn-left {
        left: 0;
    }

    .control-turn-right {
        right: 0;
    }

    .control-front,
    .control-top {
        flex: 1 1 50%;
    }
}

// specific styles for all control-related things above sm breakpoint
// here, view-controls and turn-controls are side by side
@include media-breakpoint-up(sm) {
    .visualisation-controls {
        top: unset;
        bottom: 0.5rem;
        width: 100%;
        height: 126px;
        text-align: center;
        position: absolute;
        z-index: 2;

        @include media-breakpoint-up(md) {
            bottom: 1rem;
        }
    }

    .controls-wrapper {
        display: flex;
        position: absolute;
        top: unset;
        bottom: 0;
        left: auto;
        right: 2rem;
        padding: 0 1rem;

        @include media-breakpoint-up(xl) {
            right: 20%;
        }
    }

    .controls {
        margin: 0 0.4rem;
    }

    .control-front,
    .control-top {
        min-width: 55px;
    }
}

// on desktop, btn-disclaimer is inside controls-container
// on mobile, it needs to be placed underneath categories
// we need to separate elements to achieve that
// the mobile button is in the footer, so it might make sense to
// put this code with the rest of the footer css in otcmain.scss,
// but since the two buttons are so related, I've kept it here
.btn-disclaimer-mobile {
    display: block;
    margin: 1rem;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

a.btn-disclaimer,
a.btn-disclaimer-mobile-up {
    // we need the "a" in the selector to overrule the specificity of the rule
    // a:not([href]):not([tabindex]) in bootstrap's _reboot.scss
    display: block;
    position: absolute;
    font-size: 1rem;
    padding-left: 0;
    color: $grey-600;
    bottom: rem(-16px);
    max-width: unset;
    text-decoration: underline;
    font-weight: 500;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    @include media-breakpoint-down(s) {
        display: none;
    }

    @include media-breakpoint-up(sm) {
        bottom: 0;
        left: 2rem;
        top: unset;
        transform: translateX(0);
        width: auto;
        max-width: 40%;
        font-size: rem(14px);
        padding-left: 1.5rem;
    }

    @include media-breakpoint-up(md) {
        max-width: 50%;
    }

    @include media-breakpoint-up(xl) {
        left: 20%;
    }
}

// on desktop, btn-disclaimer is inside controls-container
// on mobile, it needs to be placed underneath categories
// we need to separate elements to achieve that
// the mobile button is in the footer, so it might make sense to
// put this code with the rest of the footer css in otcmain.scss,
// but since the two buttons are so related, I've kept it here
a.btn-disclaimer-mobile {
    // we need the "a" in the selector to overrule the specificity of the rule
    // a:not([href]):not([tabindex]) in bootstrap's _reboot.scss
    position: static;
    display: block;
    margin: 1rem;
    text-decoration: underline;
    text-align: center;
    font-weight: normal;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.loader {
    border: 0.5rem solid transparent;
    border-top: 0.5rem solid $brand-primary;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin: 2rem 0 0 -2rem;
    animation: loaderspin 1s linear infinite;
    display: none;
    position: absolute;
    top: 21vh;
    left: 50%;

    &.is-active {
        display: block;
    }

    &.dark {
        border-top-color: $grey-600;
    }
}

@keyframes loaderspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.main-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.6);
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms linear;

    &.is-active {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }
}

.main-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: $grey-800;
    transition: background 300ms ease;

    img {
        display: block;
        width: 100%;
        opacity: 0.1;
        transition: opacity 300ms ease;
    }

    &.is-hidden {
        background: transparent;

        img {
            opacity: 0;
        }
    }
}

.main-title {
    font-size: rem(30px);
    font-weight: $font-weight-bold;
    color: $grey-800;
    padding: rem(15px);
    margin: rem(10px) 0 rem(10px) 0;
    text-align: center;
    letter-spacing: rem(-1px);

    @include media-breakpoint-up(sm) {
        margin: rem(40px) 0 rem(40px) 0;
        font-size: rem(56px);
        line-height: 1;
    }

    @include media-breakpoint-up(lg) {
        padding: 1.25rem 4rem;
        color: $grey-800;
        min-height: 1em;
        max-width: 100%;
        opacity: 0;
        font-weight: $font-weight-bold;
        will-change: opacity;
        transition: opacity 300ms ease;
    }

    + hr {
        margin-left: auto;
        margin-right: auto;
        opacity: 0;
        will-change: opacity;
        transition: opacity 300ms ease;
    }

    &.is-active {
        opacity: 1;

        + hr {
            opacity: 1;
        }
    }

    &.is-hidden {
        display: none;

        + hr {
            display: none;
        }
    }
}

.center-text {
    text-align: center;
}

// Responsive Toolkit
.breakpoint-md,
.breakpoint-lg,
.breakpoint-xl {
    display: none;
}

.breakpoint-sm {
    display: block;
}

@include media-breakpoint-up(md) {
    .breakpoint-sm,
    .breakpoint-lg,
    .breakpoint-xl {
        display: none;
    }

    .breakpoint-md {
        display: block;
    }
}

@media (min-width: $lg) {
    .breakpoint-sm,
    .breakpoint-md,
    .breakpoint-xl {
        display: none;
    }

    .breakpoint-lg {
        display: block;
    }
}

@include media-breakpoint-up(xl) {
    .breakpoint-sm,
    .breakpoint-md,
    .breakpoint-lg {
        display: none;
    }

    .breakpoint-xl {
        display: block;
    }
}

.ratingblock {
    width: 4.5rem;
    @include media-breakpoint-up(xl) {
        width: 5.75rem;
    }

    span {
        background: $grey-400;
        display: inline-block;
        height: 1rem;
        width: 0.75rem;
        margin: 0 1px;

        @include media-breakpoint-up(xl) {
            height: 1.25rem;
            width: 1rem;
        }
    }

    em {
        display: none;
    }

    @for $i from 1 through 5 {
        &.rating-#{$i} span:nth-child(-n + #{$i + 1}) {
            background: $green-bright;
        }
    }
}

// Inputs
.otc-checkbox {
    @extend .icon-font !optional;

    display: block;
    width: 1.125rem;
    height: 1.125rem;
    background: $white;
    color: $brand-primary;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 1.125rem;
        font-size: 1rem;
    }
}

.otc-checkbox-checked {
    @extend .icon-font-ok !optional;
}

.otc-radio {
    display: block;
    min-width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: $white;
    border: $border-default;
    transition: background 300ms ease, border 300ms ease;
    will-change: background, border;
    cursor: pointer;
}

.otc-radio-hover {
    background: $white;
    border: 0.25em solid $white;
}

.otc-radio-selected {
    background: $brand-primary;
    border: 2px solid $white;
}

.otc-radio-large {
    @extend .otc-radio !optional;

    width: 1.5rem;
    height: 1.5rem;
}

.otc-radio-large-hover {
    @extend .otc-radio-hover !optional;

    border-width: 0.75em;
}

.otc-radio-large-selected {
    @extend .otc-radio-selected !optional;

    border-width: 6px;
}

// Lists
/* stylelint-disable-next-line no-duplicate-selectors */
ul {
    &.otc-list-checks {
        @extend .list;

        > li {
            padding-left: 2rem;
            position: relative;
            @extend .otc-icon !optional;
            @extend .icon-font-ok !optional;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                color: $brand-primary;
            }
        }
    }

    &.otc-list-bullets {
        @extend .list;

        > li {
            position: relative;

            &::before {
                color: $brand-primary;
            }
        }
    }
}
