.card {
    $card: '.card';

    &--media {
        $gutter-height: 20px;

        margin: 0;
        background-color: $grey-100;
        border-radius: 0;
        height: calc(100%);
        padding-bottom: rem(55px);

        @include media-breakpoint-up(md) {
            height: calc(100% - #{$gutter-height});
        }

        &:hover {
            box-shadow: 0 0 78px 0 rgba(0, 0, 0, 0.25);
        }

        #{$card}-title {
            @include line-height(28px, 24px);

            color: $grey-800 !important;
            text-align: left;
            font-size: rem(16px);
            font-weight: $font-weight-bold;
            border-bottom: 1px solid $grey-200;
            padding-bottom: rem(15px);

            a {
                color: $grey-800 !important;

                &:hover {
                    color: $grey-800 !important;
                }
            }
        }

        #{$card}__body {
            padding: rem(20px) rem(20px) rem(4px) rem(20px);
        }

        #{$card}__image {
            position: relative;
            margin: 0;
        }

        #{$card}__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &:hover {
                background-color: rgba(theme-color(primary), 0.5);

                .icon {
                    opacity: 1;
                }
            }

            .icon {
                width: 48px;
                height: 48px;
                color: $white;
                fill: $white;
                opacity: 0;

                &--zoom-in {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }

        #{$card}__downloads {
            position: absolute;
            bottom: 10px;
            margin: 0;
            line-height: 1.2;
        }

        .btn-link {
            position: relative;
            bottom: auto;
            left: 0;
            font-size: rem(14px);
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;
            color: theme-color(primary) !important;

            &:hover {
                text-decoration: underline !important;
                color: theme-color(primary) !important;
            }

            .icon {
                position: relative;
                top: 5px;
            }
        }
    }
}

.bg-medium {
    .card {
        $card: '.card';

        &--media {
            background-color: $white;

            #{$card}-title {
                border-bottom: 1px solid $grey-100;
            }
        }
    }
}

// .bg-dark {
//     .card {
//         $card: ".card";

//         &--media {
//             background-color: $white;
//         }

//         &--highlight {
//             background-color: $grey-100;
//         }
//     }
// }

.modal--mediacard {
    .card--media {
        background-color: $white;
    }

    .modal-body {
        padding: 0;
    }

    .modal-footer {
        justify-content: flex-start;

        .modal-description {
            margin: 0;
        }

        .card__downloads {
            left: 16px;
        }
    }
}
