.lock-scroll {
    overflow: hidden !important;
}

.main-navigation {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    border-bottom: solid 1px $grey-200;
    background-color: $white;
    position: relative;

    .navbar-brand {
        max-width: 100px;
        width: 100px;
        margin-left: rem(10);
        margin-right: rem(30);

        img {
            width: 100%;
        }
    }

    &--item {
        &::before {
            content: '';
            display: inline-block;
            border-left: 1px solid $grey-300;
            width: 1px;
            height: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--link {
        font-size: rem(13);
        color: $grey-500;
        display: inline-block;
        padding: rem(15) rem(30) !important;
        border-bottom: solid 4px $white;

        @include media-breakpoint-up(lg) {
            font-size: rem(17);
        }

        &:hover {
            color: $grey-600;
            background-color: $grey-50;
            border-bottom: solid 4px $grey-50;
        }

        &.is-active {
            border-bottom: solid 4px $brand-primary;
            color: $brand-primary;

            &:hover {
                background-color: $white;
            }
        }
    }

    .btn {
        &--submenu {
            padding-right: rem(20);
        }

        &--search {
            border-left: solid 1px $grey-100;
            height: 60px;
            padding-left: rem(20);
            padding-right: rem(15);
        }

        &--dealerlocator {
            padding-left: rem(20);
            padding-right: rem(20);
            height: 60px;
            line-height: 2;

            &::before {
                content: '';
                display: inline-block;
                border-left: 1px solid $grey-100;
                width: 1px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-left: -20px;
            }

            p {
                font-size: rem(17);
            }
        }
    }

    .icon {
        &--dealerlocator {
            width: 24px;
            height: 24px;
            color: $grey-600;
            fill: $grey-600;
            position: relative;
            top: 5px;
            margin-right: 3px;

            &:hover {
                background-color: $grey-50;
            }
        }

        &--search {
            width: 32px;
            height: 32px;
            color: $grey-600;
            fill: $grey-600;
            position: relative;
            top: 3px;

            &:hover {
                background-color: $grey-50;
            }
        }
    }

    &__right {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $white;
        align-items: center;
    }

    &__searchform {
        display: flex;

        .form {
            position: relative;
            opacity: 0;
            display: inline-block;
            margin-right: -250px;
        }

        .form-control {
            padding-right: rem(45px) !important;
        }

        .btn--firstclick {
            margin-right: 25px;
        }

        .btn--secondclick {
            display: none;
        }

        &.is-active {
            .btn--firstclick {
                display: none;
            }

            .form {
                opacity: 1;
                margin-right: 5px;
                display: flex;
                align-items: center;
                transition: all 0.2s ease-out;
            }

            .btn--secondclick {
                height: 100%;
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
                border: 0;
                padding: 0 rem(15px) 0 0;

                .icon {
                    top: 0;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0) !important;
                    color: $brand-primary !important;
                }
            }
        }
    }
}

$mobilenav-transition: transform 0.2s ease-out;

.mobile-navigation {
    position: fixed;
    transform: translateX(100%);
    transition: $mobilenav-transition;
    left: 0;
    right: 0;
    height: calc(100vh - 65px);
    z-index: 99;
    display: block;
    margin: 0;
    padding: 0;
    @include simple-gradient($top: $white, $bottom: $grey-200);

    @include media-breakpoint-up(sm) {
        height: calc(100vh - 103px);
    }

    &.is-open {
        transition: $mobilenav-transition;
        transform: translateX(0);
        display: flex;
        flex-direction: column;

        .mobile-navigation__footer {
            @media (orientation: landscape) {
                display: none;
                @include media-breakpoint-up(lg) {
                    display: unset;
                }
            }
        }
    }

    &__menu {
        transition: $mobilenav-transition;
        transform: translateX(100%);
        left: 0;
        right: 0;
        flex: 1;

        &.is-open {
            transition: $mobilenav-transition;
            transform: translateX(0);
            overflow-y: auto;
        }
    }

    &__scrollcontainer {
        overflow-x: hidden;
        overflow-y: auto;

        // height: 100%;
    }

    .mobile-navigation__swipenav {
        border-bottom: 1px solid $grey-300;
        transition: $mobilenav-transition;
        transform: translateX(100%);
        height: 0;
        width: 100%;
        white-space: nowrap;

        // overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        &.swipenav--lvl2 {
            transform: translateX(0);
            height: auto;
        }

        &.swipenav--lvl3 {
            transform: translateX(0);
            height: auto;
        }

        ul {
            margin: 0;

            li {
                margin: 0;
                padding: 0;

                &.is-active {
                    a {
                        color: theme-color(primary);

                        &::after {
                            content: '';
                            border-bottom: solid 2px theme-color(primary-lighter);
                            display: block;
                            width: calc(100% - 25px);
                            position: relative;
                            left: 25px;
                        }
                    }

                    .icon--chevron-left {
                        color: theme-color(primary);
                        fill: theme-color(primary);
                    }
                }

                a {
                    color: theme-color(primary-lighter);
                    font-weight: 500;
                    padding: rem(17px) rem(5px);
                    display: block;
                    position: relative;
                    font-size: rem(18);
                }

                .icon--chevron-left {
                    width: 20px;
                    height: 20px;
                    color: theme-color(primary-lighter);
                    fill: theme-color(primary-lighter);
                    position: relative;
                    top: 3px;
                }
            }
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        transition: $mobilenav-transition;
        transform: translateX(0);

        &-container {
            height: 100%;

            .icon {
                pointer-events: none;
            }
        }

        li {
            border-bottom: 1px solid $grey-300;
            margin-left: rem(30px);
            margin-right: rem(30px);
            list-style: none;
            color: theme-color(primary);

            &.parent {
                &.is-open {
                    .submenu--level2 {
                        display: block;
                    }
                }

                .submenu--level2 {
                    display: none;
                }

                .icon {
                    &--chevron-right {
                        width: 20px;
                        height: 20px;
                        color: theme-color(primary);
                        fill: theme-color(primary);
                        position: absolute;
                        top: 22px;
                        right: 5px;
                    }
                }
            }

            &.is-active {
                border-bottom: 2px solid theme-color(primary);

                a {
                    font-weight: 500;
                }
            }

            a {
                @include line-height(28px, 21px);

                position: relative;
                display: block;
                text-decoration: none;
                color: theme-color(primary);
                font-size: rem(21);
                font-weight: 700;
                padding: rem(20px) rem(10px);
            }

            .submenu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                padding: 0;
            }

            &.is-open {
                > .submenu {
                    transition: $mobilenav-transition;
                    transform: translateX(0);
                }
            }

            &.back {
                a {
                    font-size: rem(14) !important;

                    .icon--chevron-left {
                        width: 16px;
                        height: 16px;
                        color: theme-color(primary);
                        fill: theme-color(primary);
                        position: relative;
                        top: 3px;
                        left: -5px;
                    }
                }
            }
        }

        .submenu {
            transition: $mobilenav-transition;
            transform: translateX(100%);
        }

        .submenu--level2 {
            .is-open {
                .submenu--level3 {
                    display: block;
                }
            }

            .submenu--level3 {
                display: none;
            }
        }

        .submenu--level2,
        .submenu--level3 {
            li {
                &.parent {
                    .icon {
                        &--chevron-right {
                            top: 17px;
                        }
                    }
                }

                a {
                    @include line-height(20px, 16px);

                    font-size: rem(16);
                    font-weight: 100;
                    padding: rem(15px) rem(10px);
                }

                &.is-active {
                    border-bottom: 2px solid theme-color(primary);

                    a {
                        font-weight: 500;
                    }
                }
            }
        }

        // Level 2
        &.sub-is-open {
            transform: translateX(-100%);
            transition: $mobilenav-transition;

            .parent {
                &.is-open {
                    .submenu--level2 {
                        transform: translateX(100%);
                        z-index: 9;

                        // display: block;
                    }
                }

                // submenu inactive
                .submenu--level2 {
                    transform: translateX(100%);

                    // display: none;
                }
            }
        }

        // Level 3
        &.subsub-is-open {
            transform: translateX(-200%);
            transition: $mobilenav-transition;

            .parent {
                &.is-open {
                    .submenu--level3 {
                        transform: translateX(100%);
                        z-index: 9;

                        // display: block;
                    }
                }

                // submenu inactive
                .submenu--level3 {
                    transform: translateX(100%);

                    // display: none;
                }
            }
        }
    }

    &__footer {
        bottom: 0;
        width: 100%;
        min-height: 205px;

        .mobilenav-searchbar {
            position: relative;

            .form {
                display: none;
                padding: 5px;
            }

            .btn {
                position: absolute;
                right: 5px;
                top: 0;
            }

            .icon {
                margin: 7px 0;
                fill: $grey-500;
                top: 5px;
            }

            &.is-active {
                .form {
                    display: block;
                }
            }
        }

        .mobilenav-buttons {
            background-color: $white;
            position: relative;
            min-height: 60px;

            &::before {
                content: '';
                position: absolute;
                height: 35px;
                background-color: $grey-200;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 1px;
            }

            ul {
                columns: 100px 2;
                padding-top: rem(14px);
                padding-bottom: rem(14px);
                margin: 0;
            }

            .btn {
                position: relative;
                padding-top: 0;
                padding-bottom: 0;

                p {
                    font-size: rem(18);
                    color: theme-color(primary);
                }

                &--search {
                    padding-left: rem(30);
                    padding-right: rem(10);
                }

                &--dealerlocator {
                    padding-left: rem(30);
                    padding-right: rem(10);
                }
            }

            .icon {
                &--dealerlocator {
                    width: 24px;
                    height: 24px;
                    color: theme-color(primary);
                    fill: theme-color(primary);
                    position: absolute;
                    left: 0;
                    top: 2px;
                }

                &--search {
                    width: 20px;
                    height: 20px;
                    color: theme-color(primary);
                    fill: theme-color(primary);
                    position: absolute;
                    left: 0;
                    top: 2px;
                }
            }
        }

        .meta-navigation {
            &--mobile {
                background-color: $grey-800;
                position: relative;
                min-height: 135px;

                &::before {
                    content: '';
                    position: absolute;
                    height: 90px;
                    background-color: lighten($grey-800, 5%);
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    width: 1px;
                }

                ul {
                    // columns: 100px 2;
                    column-count: 2;
                    padding-top: rem(25px);
                    padding-bottom: rem(25px);
                    margin: 0;

                    li {
                        page-break-inside: avoid;
                        break-inside: avoid;

                        a {
                            color: $grey-400;
                            text-decoration: none;
                            font-family: $font-family-arial;
                            font-size: rem(12);
                            text-transform: uppercase;
                            position: relative;
                        }

                        &.show {
                            .icon--chevron-right {
                                transform: rotate(-90deg) translateY(5px);
                            }
                        }

                        .icon--chevron-right {
                            width: 18px;
                            height: 18px;
                            color: $grey-400;
                            fill: $grey-400;
                            position: absolute;
                            top: 7px;

                            // left: auto;
                            transform: rotate(90deg) translateY(-5px);
                            transition: all 100ms linear;
                        }
                    }
                }

                .dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }

                .language-list {
                    background: $grey-800;
                    position: absolute;
                    bottom: 70px;
                    right: 0;

                    a {
                        padding: 0.5rem 1rem;
                    }
                }
            }
        }
    }
}
