.base-gdpr-template {
    position: relative;
    width: 100%;

    &__inner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 3;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-position: center;
        background-size: cover;
        z-index: 1;

        .gradient {
            @include background(linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 100%));

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            z-index: 2;
        }
    }

    &__title {
        @include line-height(19px, 19px);

        margin: 0;
        font-size: rem(19px);
        font-weight: $font-weight-bold;
        color: $white;

        @include media-breakpoint-up(md) {
            @include line-height(30px, 32px);

            font-size: rem(32px);
        }

        @include media-breakpoint-up(lg) {
            @include line-height(48px, 52px);

            font-size: rem(52px);
        }
    }

    &__text {
        color: $white;
    }

    input[type='checkbox'] {
        display: none;

        &:checked + label {
            border: 0 rgba(0, 0, 0, 0) solid;
            background: linear-gradient(62deg, $green, $green);
        }

        &:checked + label::before {
            background: $white;
            left: 31px;
            box-shadow: -3px 0 0 0 rgba(0, 9, 59, 0.1);
            top: 3px;
        }
    }

    label {
        box-sizing: border-box;
        cursor: pointer;
        margin: 0;
        position: relative;
        width: 61px;
        height: 32px;
        background: $grey-100;
        border-radius: 100px;
        border: 1px $gray-300 solid;
        transition: all ease-in-out 0.25s;

        &::before {
            content: '';
            width: 26px;
            height: 26px;
            border-radius: 100px;
            background: $gray-800;
            position: absolute;
            top: 2px;
            left: 2px;
            box-shadow: 3px 0 0 0 rgba(0, 9, 59, 0.1);
            transition: all ease-in-out 0.25s;
        }
    }
}
