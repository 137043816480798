.family-day-form {
    .form-row,
    .form-group:not(.form-group--list) {
        .invalid-feedback--msg {
            margin: 0;
            position: initial;
            padding: rem(8px) 0 0 0 !important;
            font-size: rem(14px);
            pointer-events: none;
            @include line-height(18px, 14px);

            @include media-breakpoint-up(md) {
                align-items: center;
            }
        }
    }

    .form-row {
        .input-validation-wrapper {
            position: relative;

            .invalid,
            .valid,
            .help {
                &-feedback {
                    &.icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 10;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .field-validation-error {
        &.help-block {
            color: theme-color(danger);
        }

        &.alert-danger {
            padding-left: 0.75rem;
        }
    }
}
