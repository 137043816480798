.dealerlocator-entrance {
    background-color: theme-color(primary);

    .search {
        &__title {
            @include line-height(38px, 40px);

            margin: 0;
            padding: rem(50px) 0 rem(30px) 0;
            font-size: rem(40px);
            color: $white;
        }

        &__form {
            padding-bottom: rem(50px);
        }

        .loader-holder {
            position: relative;
            margin: 0;
            padding: 0;

            .loader {
                position: absolute;
                top: 0;
                left: 0;

                .spinner {
                    width: 20px;
                    height: 20px;
                    left: auto;
                    right: rem(20px);
                    transform: translate(0, -50%);
                }
            }
        }

        select {
            transition: 200ms opacity ease-in-out;

            &:disabled {
                opacity: 0.4;
                pointer-events: none;
                cursor: default;
            }
        }

        .btn {
            &--dealer-search {
                @include media-breakpoint-up(md) {
                    padding-top: rem(4px);
                    padding-bottom: rem(4px);
                }
            }
        }
    }
}
