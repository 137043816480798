$padding: 20px;

.card {
    $card: '.card';

    position: relative;
    margin: 0 0 rem(30px) 0;
    border: 0;
    background-color: transparent;

    @include media-breakpoint-up(md) {
        margin: 0;
    }

    &__image {
        margin: 0 rem($padding) rem(5px) rem($padding);
        background-size: cover;
        background-position: 50% 50%;

        &--fill {
            position: relative;

            img {
                @include fill-parent-absolute;
            }
        }

        .ratio {
            @include ratio-box(100%, 16, 9);
        }
    }

    &__image-hover {
        position: absolute;
        inset: 0;
        background: rgba($black, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s ease;

        .icon {
            padding: rem(4);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid $white;
            fill: $white;
        }
    }

    &:hover &__image-hover {
        opacity: 1;
    }

    &-title {
        @include line-height(28px, 24px);

        margin-bottom: rem(5px);
        font-weight: $font-weight-normal;

        &__anchor {
            &,
            &:hover {
                color: inherit;
            }

            &:focus-visible {
                outline: 1px solid currentcolor !important;
                outline-offset: rem(4px);
            }

            &::before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: 0;
            }
        }
    }

    &-text {
        @include line-height(26px, 16px);

        font-size: rem(16px);
        color: $grey-600;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
    }

    &__links {
        position: relative;
        list-style: none;
        margin: auto 0 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: rem(4px) rem(12px);
        color: $grey-400;

        &-item {
            display: flex;
            gap: rem(12px);

            &:first-child {
                font-weight: 500;
            }

            &:not(:last-child) {
                &::after {
                    content: '';
                    display: block;
                    width: 1px;
                    background: currentcolor;
                }

                .icon {
                    display: none;
                }
            }
        }

        &-anchor {
            display: inline-flex;
            align-items: center;

            &:hover,
            &:focus-visible {
                text-decoration: underline;
                color: $grey-900;
            }

            &:focus-visible {
                outline: 1px solid currentcolor !important;
                outline-offset: rem(4px);
            }

            .icon {
                width: 1.25em;
                height: 1.25em;

                svg {
                    fill: currentcolor;
                }
            }

            .text-light & {
                color: $white;
            }

            .content-carousel-yellow & {
                color: theme-color(warning) !important;
            }

            .content-carousel-blue & {
                color: theme-color(primary) !important;
            }

            .content-carousel-grey & {
                color: $grey-300 !important;
            }
        }
    }

    &__btn {
        margin: auto 0 0;
        align-self: flex-start;
    }

    .table {
        width: auto;
        margin: 0 rem($padding);

        table {
            margin-top: 0;
        }
    }
}
