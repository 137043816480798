/* stylelint-disable no-invalid-position-at-import-rule */
@import 'otc-variables';

@import 'marketselector';

// Utils
@import 'utils/breakpoint-indicator';

.truckconfigurator {
    // OTC Base
    @import 'otc-icons';
    @import 'otc-base';

    // OTC Elements
    @import 'block-links';
    @import 'nouislider';
    @import 'sidepanels';
    @import 'colorpicker';

    // @import "slick-carousel";

    // OTC Components
    // @import "components/cookiemessage/cookiemessage";
    @import 'modal';
    @import 'otc-loader';
    @import 'otc-message';
    @import 'otcmain';
    @import 'otc-results';

    // Print
    @import 'otc-print';

    .firststep {
        .flow-link {
            .card {
                @include media-breakpoint-up(lg) {
                    padding-bottom: rem(55px);
                }
            }
        }
    }

    .alert {
        display: block;
        position: absolute;
        width: inherit;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;

        &.fade {
            display: none;
        }
    }
}
