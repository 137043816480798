$header-video-z-index-play-button-overlay: 1;
$header-video-z-index-play-button-controls: 1;
$header-video-z-index-content: 2;

// to remain a video aspect ratio of 16:9, the video height will have to be (9/16*100 = 56.25%) of the viewport width
$header-video-height: 56.25vw;

.header-video {
    position: relative;
    width: 100%;
    height: $header-video-height;
    max-height: 70vh;
    background-position: 50%;
    background-size: cover;

    &--small {
        max-height: 60vh;
    }

    &--large {
        max-height: 80vh;
    }

    // to make text readable
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-image: linear-gradient(90deg, $black, transparent);
    }

    .vimeo-player {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .vimeo-player iframe {
        position: absolute;
        top: 50%;
        width: 100%;
        height: $header-video-height;
        transform: translateY(-50%);
    }

    &__play-toggle-overlay-mobile {
        z-index: $header-video-z-index-play-button-overlay;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        .icon {
            fill: $white;
            width: 4rem;
            height: 4rem;
            margin: auto;
        }
    }

    &__controls {
        z-index: $header-video-z-index-play-button-controls;
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
        display: none; // hide on mobile

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &__control-button {
        display: flex;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        filter: drop-shadow(0.125rem 0.125rem 0.125rem rgba($black, 0.5));

        & > .icon {
            fill: $white;
        }
    }

    &__content {
        z-index: $header-video-z-index-content;

        @include media-breakpoint-up(lg) {
            height: auto;
        }

        &--right {
            text-align: right;

            .header-video__body {
                margin-left: auto;
            }
        }
    }

    &__body {
        @include media-breakpoint-up(lg) {
            width: 60%;
        }

        .hero-header__title,
        .hero-header__subtitle,
        .hero-header__text {
            @include media-breakpoint-up(lg) {
                color: $white;
            }

            & + .btn {
                margin-top: rem(20px);
            }
        }
    }
}
