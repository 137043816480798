$showcase-pagination-font-size-mobile: rem(20px);
$showcase-navigation-buttons-width: rem(168px);
$showcase-pagination-order-desktop: 1;
$showcase-description-text-order-desktop: 2;
$showcase-navigation-order-desktop: 3;

.showcase-gallery {
    height: auto;
    padding-top: 5rem;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    &__slides {
        /* override swiper styles */
        .swiper-slide {
            // center image
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: auto;
            margin-bottom: auto;
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            opacity: 0.4;
        }

        .swiper-slide-active {
            opacity: 1;
        }
    }

    &__image {
        flex: 1;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &__description {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        padding: 1.75rem 0;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 2.75rem 0;
        }

        &.is-visible {
            opacity: 1;
        }

        &-wrapper {
            position: absolute;
            right: 0;
            left: 0;
            opacity: 0;
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            transition: opacity 0.4s ease-in-out;
            pointer-events: none;

            @include media-breakpoint-up(md) {
                padding: 0 0 2rem;
                border-bottom: 1px solid bg-color(dark);

                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    border-color: bg-color(light);
                }
            }

            &.is-active {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &-text {
            position: relative;
            width: 100%;

            @include media-breakpoint-up(md) {
                order: $showcase-description-text-order-desktop;
                width: 60%;
                margin-bottom: 2.5rem;
            }
        }
    }

    &__slide-title {
        font-size: rem(28px);
        @include line-height(32px, 28px);
    }

    &__slide-description {
        &,
        p {
            font-size: rem(18px);
            @include line-height(28px, 18px);
        }
    }

    &__slide-link {
        display: flex;
        font-size: 1rem;
        font-weight: $alert-link-font-weight;

        &:hover {
            .icon {
                fill: $brand-primary-dark;
            }
        }

        .icon {
            fill: $brand-primary;
        }

        @at-root {
            .bg-dark &,
            .bg-blue &,
            .bg-black & {
                color: white;

                .icon {
                    fill: white;
                }
            }
        }
    }

    &__navigation {
        position: relative;

        @include media-breakpoint-up(md) {
            order: $showcase-navigation-order-desktop;
        }
    }

    &__navigation-button {
        position: relative;
        width: rem(40px);
        height: rem(40px);
        padding: 0;
        margin-left: rem(10px);
        border-radius: 50%;

        &,
        &:hover,
        &:active {
            border: 1px solid bg-color(dark);

            .bg-dark &,
            .bg-blue &,
            .bg-black & {
                border-color: bg-color(light);
            }

            .icon {
                fill: bg-color(dark);

                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    fill: bg-color(light);
                }
            }
        }

        .icon {
            @include align-center(absolute);
        }
    }

    &__pagination {
        width: auto !important;
        font-size: rem(20px);
        line-height: 1;

        @include media-breakpoint-up(md) {
            order: $showcase-pagination-order-desktop;
            font-size: rem(28px);
        }

        // pagination divider line
        &::after {
            content: '';
            position: absolute;
            width: calc(100% - #{$showcase-navigation-buttons-width} - 1rem); // 1rem spacing on the right
            height: 1px;
            margin-top: calc(#{$showcase-pagination-font-size-mobile} / 2);
            margin-left: rem(18px);
            background-color: bg-color(dark);

            .bg-dark &,
            .bg-blue &,
            .bg-black & {
                background-color: bg-color(light);
            }

            @include media-breakpoint-up(md) {
                content: none;
            }
        }
    }
}
