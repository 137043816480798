$video-player-gradient-z-index: 32;
$video-player-poster-z-index: 31;

.video-player {
    position: relative;

    &__poster {
        z-index: $video-player-poster-z-index;
        @include fill-parent-absolute;

        img {
            @include fill-parent-absolute;
        }

        .gradient {
            z-index: $video-player-gradient-z-index;
            @include fill-parent-absolute;

            opacity: 0.45;
            @include background(linear-gradient(90deg, $black, transparent 80%));
        }

        .container {
            @include align-center(absolute);

            position: absolute;
            padding-left: rem(24px);
            z-index: 33;

            @include media-breakpoint-up(md) {
                padding-left: rem(32px);
            }
        }
    }

    &.no-poster {
        .video-player__poster {
            pointer-events: none;
        }

        .gradient {
            display: none;
        }
    }

    &__title {
        @include line-height(18px, 18px);

        margin: 0;
        font-size: rem(18px);
        font-weight: $font-weight-light;
        color: $white;
        max-width: 38%;

        @include media-breakpoint-up(sm) {
            @include line-height(30px, 32px);

            font-size: rem(32px);
            max-width: 40%;
        }

        @include media-breakpoint-up(md) {
            .col-md-6 & {
                @include line-height(18px, 18px);

                font-size: rem(18px);
                max-width: 36%;
            }
        }

        @include media-breakpoint-up(lg) {
            @include line-height(48px, 52px);

            font-size: rem(52px);

            .col-md-6 & {
                @include line-height(24px, 24px);

                font-size: rem(24px);
                max-width: 40%;
            }
        }
    }

    &__video {
        position: relative;
        margin: 0 auto;
        padding: 0 !important;
        z-index: 30;
        width: 100%;
        max-width: map-get($container-max-widths, 'xl') - $grid-gutter-width;

        &__placeholder {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__playbutton {
        @include align-center(absolute);

        position: absolute;
        margin: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        transition: 200ms background-color ease-in-out;
        opacity: 0.7;
        z-index: 35;

        .video-player--gdpr & {
            display: none;
        }

        .icon {
            fill: $white;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        &:hover {
            opacity: 1;
        }

        @include media-breakpoint-up(md) {
            width: 60px;
            height: 60px;

            .col-md-6 & {
                width: 50px;
                height: 50px;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 100px;
            height: 100px;

            .col-md-6 & {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.container-fluid {
    .video-player {
        margin: 0 rem(0 - ($grid-gutter-width * 0.5));
    }
}
