.comparison-carousel {
    $comparison-carousel-item-transition: opacity 0.4s ease-out;

    // border radius for the correct slides
    .slick-slide {
        &:first-of-type {
            .comparison-carousel__item {
                .card {
                    border-radius: rem(10px) 0 0 rem(10px);
                }
            }
        }

        &:last-of-type {
            .comparison-carousel__item {
                .card {
                    border-radius: 0 rem(10px) rem(10px) 0;
                }
            }
        }
    }

    .slick-disabled {
        opacity: 0;
        transition: all 0.1s ease-in;
        pointer-events: none;
    }

    button {
        transition: all 0.1s ease-in;
    }

    &__btn {
        opacity: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 0;
        background-color: transparent;
        width: 60px;
        z-index: 1;
        cursor: pointer;

        &::before {
            @include align-vertical(absolute);

            content: '';
            background-color: rgba($white, 0.2);
            width: 60px;
            height: 110px;
            position: absolute;
            transition: background-color 100ms ease-in-out;
        }

        &:hover {
            &::before {
                background-color: rgba($white, 0.4);
            }
        }

        .icon {
            @include align-center(absolute);

            position: absolute;
            fill: theme-color(primary);
            color: theme-color(primary);
            width: 48px;
            height: 48px;
        }

        &--prev {
            position: absolute;
            left: 0;

            &::before {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            @include media-breakpoint-up(sm) {
                left: 0;
            }
        }

        &--next {
            position: absolute;
            right: 0;

            &::before {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            @include media-breakpoint-up(sm) {
                right: 0;
            }
        }
    }

    &__item {
        transition: $comparison-carousel-item-transition;
        height: 100%;

        &.is-inactive {
            @include media-breakpoint-up(sm) {
                opacity: 0.2;
            }

            transition: $comparison-carousel-item-transition;
        }

        @include media-breakpoint-up(sm) {
            padding-bottom: 50px;
        }
    }
}

.slick-track {
    display: flex;
    height: 100%;

    .slick-slide {
        display: flex;
        padding-top: 25px;
        height: auto;
        align-items: stretch;
        justify-content: stretch;

        > div {
            &:first-child {
                width: 100%;
            }
        }
    }
}
