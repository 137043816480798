.card {
    $card: '.card';

    &--comparison {
        background-color: $grey-100;
        display: flex;
        align-items: stretch;
        padding: 0;
        height: 100%;
        border-radius: 0;
        margin-bottom: rem(15px);

        @include media-breakpoint-up(sm) {
            margin: rem(30px) rem(2px) 0;
        }

        #{$card}-title {
            color: $grey-800;
            text-align: center;
            font-size: rem(30px);
            font-weight: $font-weight-bold;
        }

        #{$card}__image {
            display: block;

            @include media-breakpoint-up(sm) {
                margin-top: rem(-60px);

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        #{$card}__link-overlay {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
            }

            &:hover {
                + #{$card}-body {
                    .btn {
                        @include background-image(
                            linear-gradient(
                                    180deg,
                                    rgba(darken($brand-primary-light, 5), 1) 0,
                                    rgba(darken($brand-primary, 5), 1) 100%
                                )
                                !important
                        );

                        color: $white !important;
                    }
                }
            }
        }

        #{$card}-body {
            padding-bottom: rem(75px);
        }

        #{$card}-text {
            text-align: center;
            margin-bottom: 25px;
        }

        #{$card}-list {
            padding-left: rem(0);
            padding-right: rem(0);

            @include media-breakpoint-up(sm) {
                padding-left: rem(25px);
                padding-right: rem(25px);
            }

            li {
                border-bottom: solid 1px $grey-200;
                padding: rem(5px) rem(10px);
                color: $grey-600;

                @include media-breakpoint-up(sm) {
                    padding: rem(10px) rem(20px);
                }
            }
        }

        .list {
            &--checkmark {
                white-space: nowrap;
                margin-bottom: 0;

                @include media-breakpoint-up(sm) {
                    white-space: inherit;
                }

                li {
                    position: relative;
                    display: inline-block;
                    border-bottom: solid 0 $grey-200;

                    @include media-breakpoint-up(sm) {
                        display: block;
                        padding-left: rem(45px);
                        border-bottom: solid 1px $grey-200;
                    }

                    &.is-optional {
                        .icon--checkmark {
                            opacity: 0.5;
                        }
                    }
                }

                .icon {
                    width: 20px;
                    height: 20px;

                    &--checkmark {
                        position: absolute;
                        top: -15px;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;

                        @include media-breakpoint-up(sm) {
                            top: 10px;
                            left: 15px;
                            margin-right: rem(10px);
                            right: auto;
                        }
                    }
                }
            }
        }

        .btn {
            @include line-height(16px, 16px);

            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            padding-top: rem(13px);
            padding-bottom: rem(13.6px);
            font-size: rem(16px);
            font-weight: $font-weight-normal;
            border: $border-default;
            color: theme-color(primary);
            max-width: 140px;
            background-color: $white !important;

            &:hover {
                color: $white;
            }
        }
    }

    &--highlight {
        border: solid 2px $grey-400;
        border-radius: rem(10px);
        background-color: $white;
        margin: 0 rem(5px) rem(15px);
        height: calc(100% + 50px);

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            padding-top: rem(30px);
        }

        .btn {
            @include media-breakpoint-up(sm) {
                bottom: 50px;
            }
        }
    }
}

.bg-medium {
    .card {
        $card: '.card';

        &--comparison {
            background-color: $white;
        }

        &--highlight {
            background-color: $grey-100;
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .card {
        $card: '.card';

        &--comparison {
            background-color: lighten($grey-800, 5%);

            #{$card}-title {
                color: $white;
            }

            #{$card}-text {
                color: $white;
            }

            #{$card}-list {
                li {
                    border-bottom: solid 0 lighten($grey-600, 5%);
                    color: $white;

                    @include media-breakpoint-up(sm) {
                        border-bottom: solid 1px lighten($grey-600, 5%);
                    }
                }
            }

            .btn {
                border: 1px solid $white;
                background-color: $white !important;
                color: theme-color(primary);

                &:hover {
                    color: $white;
                }
            }
        }

        &--highlight {
            background-color: $grey-600;
            border: solid 2px $grey-600;
        }
    }
}

.list--checkmark__wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-top: 15px;
    margin-bottom: 5px;
}
