.logo-footer {
    margin: 0;
    padding: 0;
    width: 100%;
    border-top: 1px solid $grey-600;

    a {
        display: inline-block;
        text-decoration: none;
    }

    .payoff {
        margin: rem(17px) 0 rem(17px) 0;
        font-size: rem(16px);
        display: block;
        @include line-height(26px, 16px);

        color: $white;

        strong {
            margin-right: rem(5px);
        }

        @include media-breakpoint-up(md) {
            margin: rem(17px) rem(20px) rem(17px) 0;
        }
    }
}
