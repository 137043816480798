.infographics {
    .infographic {
        position: relative;
        width: 100%;
        max-width: 275px;
        margin: 0 rem($grid-gutter-width) rem($grid-gutter-width * 2) rem($grid-gutter-width);

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        &__circle {
            @include ratio-box(100%, 1, 1);

            border-radius: 50%;
            background-color: $grey-100;
        }

        &__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
        }

        &__image {
            margin: 0 auto rem(15px) auto;
            width: 64px;
            height: 64px;
            text-align: center;
            fill: theme-color(secondary);
        }

        &__data,
        &__label {
            width: 100%;
            text-align: center;
        }

        &__data {
            font-size: rem(70px);
            font-weight: $font-weight-light;
            line-height: 1;
            letter-spacing: -3px;
        }

        &__label {
            color: theme-color(secondary);
        }
    }

    &__without-images {
        .infographic {
            &:first-child {
                .infographic__content {
                    border: none;
                }
            }

            @include media-breakpoint-down(sm) {
                &:nth-child(2n) {
                    .infographic__content {
                        border: none;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                &:nth-child(3n) {
                    .infographic__content {
                        border: none;
                    }
                }
            }

            &__circle {
                height: auto;
                display: flex;
                padding: 1.75rem 0;
                background-color: unset;
                border-radius: unset;
            }

            &__content {
                position: relative;
                top: unset;
                left: unset;
                margin: auto;
                padding: 1rem 0;
                border-left: 1px solid $grey-600;
            }

            &__data {
                font-size: rem(56px);
                font-weight: $font-weight-medium;
            }

            &__label {
                font-size:  rem(14px);
                color: $grey-800;
                margin-bottom: 0;
            }
        }
    }

    &-yellow {
        .infographic {
            &__label {
                color: theme-color(warning);
            }
        }
    }

    &-blue {
        .infographic {
            &__label {
                color: theme-color(primary);
            }
        }
    }

    &-grey {
        .infographic {
            &__label {
                color: $grey-300;
            }
        }
    }
}

.bg-medium {
    .infographic {
        &__circle {
            background-color: $white;
        }
    }

    .infographics__without-images,
    &.infographics__without-images {
        .infographic {
            &__circle {
                background-color: unset;
            }
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .infographic {
        &__circle {
            background-color: $grey-700;
        }

        &__data {
            color: $white;
        }
    }

    .infographics__without-images,
    &.infographics__without-images {
        .infographic {
            &__circle {
                background-color: unset;
            }

            &__label {
                color: $white;
            }
        }
    }
}
