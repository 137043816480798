.news {
    &--article {
        .image-caption {
            margin-bottom: 0;
        }

        .hero-header__content {
            top: auto;
            bottom: 0;

            @include media-breakpoint-up(lg) {
                transform: translate(-45%, 0);
            }
        }
    }

    &__meta {
        padding: 0;
        font-size: rem(12px);

        @include media-breakpoint-up(sm) {
            padding: 0 0 rem(15px) 0;
            font-size: rem(16px);
        }

        @include media-breakpoint-up(md) {
            padding: 0 0 rem(46px) 0;
        }

        .list-inline {
            margin-bottom: 0;

            .list-inline-item {
                position: relative;
                margin-right: 0;
            }

            .list-inline-item--separator {
                margin-right: rem(3px);
                padding-right: rem(6px);

                @include media-breakpoint-up(md) {
                    margin-right: rem(5px);
                    padding-right: rem(10px);
                }

                &::before {
                    content: '|';
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    &__checkmark {
        &--bordered {
            border-bottom: solid 1px $grey-400;
        }

        .list--checkmark {
            margin-bottom: 0;
            padding-bottom: 20px;

            li {
                margin-bottom: 2px;
                color: $grey-600;
                font-size: rem(14px);

                @at-root {
                    .bg-blue.text-light &,
                    .bg-black.text-light &,
                    .bg-dark.text-light & {
                        color: $white !important;
                    }
                }

                @include media-breakpoint-up(md) {
                    font-size: rem(16px);
                }
            }

            .icon {
                width: 18px;
                height: 18px;
                margin-right: 15px;

                &--checkmark {
                    position: relative;

                    svg {
                        color: $grey-400;
                        fill: $grey-400;
                    }
                }
            }
        }
    }

    &__title {
        margin-bottom: rem(25px);
    }

    &__subtitle {
        margin-bottom: rem(0);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }
    }

    h4 {
        @include line-height(28px, 18px);

        font-size: rem(18px);
        margin-bottom: 0;
        font-weight: $font-weight-bold;
    }

    p {
        margin-bottom: rem(0);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }

        &.lead {
            margin-bottom: 0;
        }
    }
}

.downloads-bar {
    position: relative;

    &__container {
        height: 40px;
        border: $border-default;
        border-radius: 5px;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0 5px 0 15px;

        @include media-breakpoint-up(sm) {
            height: 46px;
        }

        @include media-breakpoint-up(md) {
            margin: 0 0 10px;
            padding: 0 10px 0 20px;
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            height: 46px;
            left: auto;
            right: 0;
        }
    }

    &__title {
        font-weight: $font-weight-medium;
        margin-bottom: 0;
        margin-right: 15px;
        font-size: rem(14px);
        color: $grey-600;

        @include media-breakpoint-up(md) {
            font-size: rem(16px);
        }
    }

    &__link {
        position: relative;
        height: 32px;
        width: 32px;
        text-decoration: none;
        text-align: center;

        @include media-breakpoint-up(sm) {
            height: 40px;
            width: 40px;
        }

        @include media-breakpoint-up(md) {
            height: 38px;
            width: 38px;
            margin-left: 3px;
            margin-right: 3px;
        }

        &:hover {
            background-color: rgba(0, 82, 156, 0.1);
            border-radius: 50%;

            .icon {
                color: theme-color(primary);
                fill: theme-color(primary);
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 14px;
            height: 18px;
            fill: $grey-600;
            color: $grey-600;

            @include media-breakpoint-up(md) {
                height: 18px;
                width: 18px;
            }
        }
    }

    .download-options__hover {
        position: relative;
        text-align: center;
        width: 32px;
        height: 32px;
        margin: 3px auto;

        @include media-breakpoint-up(sm) {
            width: 38px;
            height: 38px;
            margin: 3px auto;
        }

        &:hover {
            background-color: rgba(0, 82, 156, 0.1);
            border-radius: 50%;
        }
    }
}
