.cookieconsentcontainer {
    padding-top: 30px;
    width: 100%;
    background: $white;
    border-top: 1px solid $grey-100;

    &--fixed-overlay {
        z-index: 999;
        position: fixed;
        bottom: 0;
    }

    &__collapse {
        max-height: 0;
        opacity: 0;
        transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;

        &--is-open {
            opacity: 1;
            max-height: 235px;
        }

        a {
            display: block;
            text-align: center;
            padding-bottom: 20px;
        }
    }

    &__buttoncontainer {
        display: flex;
        justify-content: center;

        button {
            margin: 12px 6px;
        }
    }

    &__checkboxes {
        span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;

            div {
                height: 32px;
            }

            p,
            label {
                margin: 0;
                display: inline-block;
            }
        }

        input[type='checkbox'] {
            display: none;

            &:checked + label {
                border: 0 rgba(0, 0, 0, 0) solid;
                background: linear-gradient(62deg, $green, $green);
            }

            &:checked + label::before {
                background: $white;
                left: 31px;
                box-shadow: -3px 0 0 0 rgba(0, 9, 59, 0.1);
                top: 3px;
            }
        }

        label {
            box-sizing: border-box;
            cursor: pointer;
            margin: 0;
            position: relative;
            width: 61px;
            height: 32px;
            background: $grey-100;
            border-radius: 100px;
            border: 1px $gray-300 solid;
            transition: all ease-in-out 0.25s;

            &::before {
                content: '';
                width: 26px;
                height: 26px;
                border-radius: 100px;
                background: $gray-800;
                position: absolute;
                top: 2px;
                left: 2px;
                box-shadow: 3px 0 0 0 rgba(0, 9, 59, 0.1);
                transition: all ease-in-out 0.25s;
            }
        }
    }
}

.fade-leave {
    opacity: 1;
}

.fade-leave-active {
    transition: opacity 0.5s;
    transition-delay: 0.1s;
}

.fade-leave-to {
    opacity: 0;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity 0.2s;
}

.fade-enter-to {
    opacity: 1;
}
