input,
textarea,
select {
    // optional inputs should not have validation styles
    &:optional {
        &.is-invalid,
        &.is-valid {
            border-color: $grey-400 !important;

            ~ .valid-feedback,
            ~ .invalid-feedback {
                display: none;
            }

            ~ .group-label {
                color: $grey-500 !important;
            }

            ~ .form-check-label,
            ~ .custom-control-label {
                color: $grey-900 !important;

                &::before,
                &::after {
                    background-color: $white !important;
                }
            }
        }
    }

    &.form-control {
        padding: rem(5px) rem(40px) rem(5px) rem(15px) !important;
        border-color: $grey-400;
        background-color: $white;
        color: $grey-900;
        height: 42px;
        box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1);

        &:focus,
        &:active,
        &:hover {
            color: $grey-900;
            box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1) !important;
        }

        @include input-placeholder {
            color: $grey-300;
        }

        &--zipcode {
            @include make-col(6);

            @include media-breakpoint-up(md) {
                @include make-col(4);
            }

            @include media-breakpoint-up(md) {
                @include make-col(3);
            }
        }

        &.datepicker,
        &.timepicker {
            background-color: $white !important;
            cursor: pointer;
        }
    }

    &.form-masked {
        display: none;
    }
}

textarea {
    &.form-control {
        padding: rem(15px) !important;
        height: auto;
    }
}

input[type='file'] {
    &:invalid,
    &:valid,
    &.is-invalid,
    &.is-valid {
        ~ .valid-feedback,
        ~ .invalid-feedback {
            &.icon {
                display: none;
            }
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    input {
        // optional inputs should not have validation styles
        &:optional {
            &.is-invalid,
            &.is-valid {
                ~ .form-check-label,
                ~ .custom-control-label {
                    color: $white !important;
                }
            }
        }
    }
}
