.dealerservices {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: solid 1px $grey-400;
    border-bottom: solid 1px $grey-400;

    @include media-breakpoint-up(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &__container {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-left: 10px;
        padding-right: 10px;

        h2 {
            margin-bottom: rem(20px);
            font-weight: $font-weight-light;

            @include media-breakpoint-up(md) {
                margin-bottom: rem(16px);
            }
        }

        [class*='col-'] {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xs) {
            max-width: 536px;
        }

        @include media-breakpoint-up(sm) {
            max-width: 728px;
        }

        @include media-breakpoint-up(md) {
            max-width: 952px;
        }

        @include media-breakpoint-up(ld) {
            max-width: 990px;
        }
    }

    &__list {
        margin-bottom: 0;
        padding-left: 20px;

        li {
            position: relative;
            padding: 0 0 rem(5px) rem(5px);
            color: #848f9e;

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $grey-300 !important;
                }
            }
        }

        p {
            margin-bottom: 0;
            font-size: rem(14px);

            @include media-breakpoint-up(md) {
                font-size: rem(16px);
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $grey-300 !important;
                }
            }
        }
    }
}
