.share-bar {
    position: relative;
    overflow: hidden;

    &__container {
        position: relative;
        padding: 0 rem(15px);
        border: $border-default;
        border-radius: 5px;
        background-color: $white;

        .label {
            margin: 0;
            padding: 14px 15px;
            display: none;
            max-width: 300px;
            color: $grey-600;

            @include media-breakpoint-up(lg) {
                @include line-height(32px, 20px);

                display: block;
                font-size: rem(20px);
            }
        }

        .btn {
            &--share {
                width: 50px;
                height: 50px;
                padding: 0;
                margin: 0;
                border-radius: 50%;
            }

            &:hover {
                background-color: rgba(theme-color(primary), 0.1);

                .icon {
                    color: theme-color(primary);
                    fill: theme-color(primary);
                }
            }

            .icon {
                color: $grey-600;
                fill: $grey-600;
                margin: 0 auto;
                transform: translate(0, 50%);
            }
        }
    }

    .bg-dark &,
    .bg-blue &,
    .bg-black & {
        &__container {
            background-color: $grey-800;

            .label {
                color: $white;
            }

            .btn {
                &:hover {
                    background-color: rgba($grey-100, 0.1);

                    .icon {
                        color: $grey-400;
                        fill: $grey-400;
                    }
                }

                .icon {
                    color: $grey-100;
                    fill: $grey-100;
                    margin: 0 auto;
                    transform: translate(0, 50%);
                }
            }
        }
    }
}
