/* stylelint-disable font-family-no-missing-generic-family-keyword */
.image-caption {
    position: relative;
    margin: 0 0 rem(85px) 0;

    @include media-breakpoint-up(sm) {
        margin: 0 0 rem(60px) 0;
    }

    img {
        width: 100%;
    }

    figcaption {
        @include line-height(18px, 12px);

        right: 0;
        top: 100%;
        font-size: rem(12px);
        font-style: italic;
        padding-top: rem(5px);
        color: $grey-500;

        @include media-breakpoint-up(sm) {
            position: absolute;
            @include line-height(22px, 14px);

            font-size: rem(14px);
            bottom: 0;
            top: auto;
            text-align: right;
            padding: rem(20px);
            color: $white;
            font-style: normal;
        }

        @include media-breakpoint-up(md) {
            width: 70%;
            margin-left: 30%;
            padding: rem(20px) rem(20px) rem(20px) 0;
        }
    }

    .gradient {
        @include media-breakpoint-up(sm) {
            @include background(linear-gradient(0deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 100%));

            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 25%;
            opacity: 0.5;
        }
    }
}

.object-fit-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
