.header {
    &.headroom {
        position: fixed;
        top: 0;
        left: 0;
        transition: transform 250ms ease-in-out;
        width: 100%;
        z-index: 1000;

        &--pinned {
            transform: translateY(0);
        }

        &--unpinned {
            transform: translateY(-100%);
        }
    }
}

.pagemode-edit,
.pagemode-preview,
.pagemode-debug {
    .header {
        &.headroom {
            position: relative;
        }
    }
}
