.tabbed-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ::-webkit-scrollbar {
        width: 8px;
        height: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #c2c9d2;
    }

    &__tabs {
        width: 100%;
        position: relative;
        height: fit-content;
        min-height: 400px;

        .tab {
            width: 80%;
            display: none;
            flex-direction: column;
            align-items: flex-start;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }

            &.active {
                display: flex;
            }

            .tab-top-block {
                width: 100%;
                margin-bottom: 20px;
            }

            .tab-content {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                z-index: 10;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                .tab-content-image {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    pointer-events: none;
                    z-index: 0;
                    max-width: 45%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .tab-header {
                    width: 100%;
                    margin-bottom: 20px;
                }

                .tab-description {
                    width: 100%;
                    margin-bottom: 20px;
                    z-index: 10;
                }

                .tab-button {
                    width: 100%;
                    margin-bottom: 20px;
                    color: $brand-primary;
                    padding: 10px 0;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    a {
                        display: flex;
                        font-weight: $font-weight-bold;
                    }

                    svg {
                        fill: $brand-primary;
                    }

                    &:hover {
                        color: #1976d2;
                    }
                }
            }
        }
    }

    &__navigation {
        background-color: $white;
        width: 100%;

        .navigation-tab {
            width: 80%;
            display: flex;
            align-items: center;
            padding: 10px 10px 0;
            font-weight: $font-weight-normal;
            color: $grey-800;
            overflow-x: auto;
            white-space: nowrap;

            @include media-breakpoint-up(md) {
                padding: 10px 20px 0;
            }

            &__item {
                padding: 10px 20px;
                cursor: pointer;

                &.active {
                    font-weight: $font-weight-bold;
                    border-bottom: 3px solid $brand-primary;
                }
            }
        }
    }
}