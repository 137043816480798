// create font color styles like
// .text-dark p
@each $color, $value in $text-colors {
    @include text-custom-variant('.text-#{$color} p', $value);
}

// primary text should override the default text <p> colors
@each $color, $value in $text-colors {
    @include text-custom-variant('.text-#{$color} .text-primary', $brand-primary);
}

// .text-dark h1 .h1
@each $color, $value in $heading-colors {
    @for $i from 1 through 6 {
        @include text-custom-variant('.text-#{$color} h#{$i}, .text-#{$color} .h#{$i}', $value);
    }
}

// create breakpoint dependend color styles like
// .text-lg-dark
@each $breakpoint, $bpvalue in $grid-breakpoints {
    @each $color, $value in $text-colors {
        @include text-breakpoint-variant('.text-#{$breakpoint}-#{$color} p', $breakpoint, $value);
    }
}

// .text-lg-dark h1, .h1
@each $breakpoint, $bpvalue in $grid-breakpoints {
    @each $color, $value in $heading-colors {
        @for $i from 1 through 6 {
            @include text-breakpoint-variant(
                '.text-#{$breakpoint}-#{$color} h#{$i}, .text-#{$breakpoint}-#{$color} .h#{$i}',
                $breakpoint,
                $value
            );
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    p {
        a {
            color: $grey-400 !important;
        }
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: heading-color(dark);
}

h1,
.h1 {
    @include line-height(37px, 38px);

    margin: 0 0 rem(25px) 0;
    font-size: rem(38px);
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
        @include line-height(60px, 64px);

        font-size: rem(64px);
    }
}

h2,
.h2 {
    margin: 0 0 rem(10px) 0;
    font-size: rem(24px);
    @include line-height(24px, 24px);

    font-weight: $font-weight-light;

    @include media-breakpoint-up(lg) {
        @include line-height(40px, 40px);

        font-size: rem(40px);
    }
}

h3,
.h3 {
    @include line-height(28px, 18px);

    margin: 0 0 rem(20px) 0;
    font-size: rem(18px);
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(lg) {
        @include line-height(32px, 24px);

        font-size: rem(24px);
    }
}

h4,
.h4 {
    @include line-height(20px, 16px);

    font-size: rem(14px);
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(lg) {
        @include line-height(21px, 16px);

        font-size: rem(16px);
        font-weight: $font-weight-medium;
    }
}

p {
    font-size: rem(14px);
    @include line-height(21px, 14px);

    font-weight: $font-weight-normal;
    color: text-color(dark);

    @include media-breakpoint-up(lg) {
        @include line-height(25px, 16px);

        font-size: rem(16px);
    }

    &.lead {
        @include line-height(23px, 16px);

        font-size: rem(16px);
        font-weight: $font-weight-medium;

        @include media-breakpoint-up(lg) {
            @include line-height(28px, 18px);

            font-size: rem(18px);
        }
    }
}

// styling for magazine grid
p.magazine {
    @include line-height(32px, 18px);

    font-size: rem(18px);

    @include media-breakpoint-up(lg) {
        @include line-height(32px, 20px);

        font-size: rem(20px);
    }
}

.magazine-title {
    @include line-height(52px, 24px);

    font-size: rem(24px);
    font-weight: $font-weight-light;

    @include media-breakpoint-up(lg) {
        @include line-height(52px, 36px);

        font-size: rem(36px);
    }
}

.magazine-subtitle {
    @include line-height(32px, 22px);

    font-size: rem(22px);

    @include media-breakpoint-up(lg) {
        @include line-height(32px, 28px);

        font-size: rem(28px);
    }
}

a {
    color: theme-color(primary);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: theme-color(primary-dark);
    }
}

.rte {
    a {
        &:hover {
            text-decoration: underline;
        }
    }

    ul,
    ol {
        li {
            font-size: rem(14px);
            @include line-height(21px, 14px);

            font-weight: $font-weight-normal;
            color: text-color(dark);

            @include media-breakpoint-up(lg) {
                @include line-height(25px, 16px);

                font-size: rem(16px);
            }
        }
    }

    @at-root {
        .bg-dark,
        .bg-blue,
        .bg-black {
            .rte {
                ul,
                ol {
                    li {
                        color: text-color(light);
                    }
                }
            }
        }
    }
}

.font-weight-light {
    font-weight: $font-weight-light !important; /* Overrule Bootstrap class */
}
