.sticky-call-actions-container {
    padding: 1rem 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    background-color: bg-color(medium);
    transition: background-color 0.25s ease-out;

    &.sticky-to-top {
        z-index: 201;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        box-shadow: -6px 4px 78px 0 rgba(0, 0, 0, 0.12);
        background-color: bg-color(light);
    }

    .sticky-bar-item {
        display: flex;

        .btn {
            flex: 1 1 auto;
            padding: 0.375rem 1.25rem;
            border-radius: 5px;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 0.75rem 0;
        gap: rem($grid-gutter-width);
        background-color: bg-color(light);

        &__item {
            max-width: 285px;
        }

        .sticky-bar-item {
            .btn {
                padding: 0.5rem 1.25rem;
            }
        }
    }
}

.pagemode-edit {
    .sticky-call-actions-container {
        display: block;
    }

    .sticky-to-bottom {
        position: relative;
        bottom: unset;
    }
}
