.copyright {
    margin: rem(7px) 0;
    padding: 0;
    width: 100%;

    .nav-item {
        position: relative;
        padding-left: rem(10px);

        &::before {
            content: '-';
            position: absolute;
            top: rem(7px);
            left: rem(-2px);
            font-weight: 500;
        }

        &:first-child {
            padding: rem(8px) rem(10px) rem(8px) 0;
            cursor: default;

            &::before {
                display: none;
            }
        }
    }

    .nav-item,
    .nav-link {
        @include line-height(16px, 14px);

        font-size: rem(14px);
        color: $grey-500;
    }

    .nav-link {
        position: relative;
        padding: rem(8px) rem(10px) rem(8px) 0;
        transition: color 100ms ease-in-out, fill 100ms ease-in-out;
        cursor: pointer;

        &:hover {
            color: $white;
            fill: $white;
        }
    }
}
