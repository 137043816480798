$hotspot-button-size-medium-mobile: rem(48px);
$hotspot-button-size-medium: rem(54px);
$hotspot-button-size-large-mobile: rem(80px);
$hotspot-button-size-large: rem(100px);
$hotspot-button-box-shadow: 0 2px 20px 0 rgba($black, 0.3);
$hotspot-button-icon-size-medium: rem(40px);
$hotspot-button-icon-size-large: rem(80px);

%hotspot-button-shape {
    width: $hotspot-button-size-medium-mobile;
    height: $hotspot-button-size-medium-mobile;
    border-radius: 50%;
    box-shadow: $hotspot-button-box-shadow;

    @include media-breakpoint-up(lg) {
        width: $hotspot-button-size-medium;
        height: $hotspot-button-size-medium;
    }

    > .icon {
        width: $hotspot-button-icon-size-medium;
        height: $hotspot-button-icon-size-medium;
        min-height: $hotspot-button-icon-size-medium;
        min-width: $hotspot-button-icon-size-medium;
        fill: $white;
        stroke: $brand-primary;
    }
}

.hotspot-button {
    @extend %hotspot-button-shape;

    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    background-color: $brand-primary;
    transition: transform 100ms ease-in;

    @include media-breakpoint-up(lg) {
        background-color: $brand-primary;
    }

    &--large {
        position: relative;
        width: $hotspot-button-size-large-mobile;
        height: $hotspot-button-size-large-mobile;
        background-color: $brand-primary;

        @include media-breakpoint-up(lg) {
            width: $hotspot-button-size-large;
            height: $hotspot-button-size-large;
        }

        .hotspot-button__border {
            width: $hotspot-button-size-large-mobile;
            height: $hotspot-button-size-large-mobile;
            border-width: rem(14px);

            @include media-breakpoint-up(lg) {
                width: $hotspot-button-size-large;
                height: $hotspot-button-size-large;
            }

            &::after {
                content: none;
            }
        }

        > .icon {
            width: $hotspot-button-icon-size-large;
            height: $hotspot-button-icon-size-large;
            stroke-width: 2px;
        }
    }
}

button.hotspot-button {
    cursor: pointer;
}

.hotspot-button__border {
    @extend %hotspot-button-shape;
    @include align-center(absolute);

    border: rem(6px) solid rgba($white, 0.25);
    box-sizing: content-box;
    pointer-events: none;
    transition: border-color 150ms ease-in;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        transition: transform 150ms ease-in;
    }

    button.hotspot-button:hover &,
    button.hotspot-button:focus & {
        border-color: $white;

        &::after {
            transform: scale(2.5);
            border: 1px solid rgba($white, 0.25);
        }
    }

    @include media-breakpoint-up(lg) {
        border: rem(8px) solid rgba($white, 0.2);
    }
}

.hotspot-button__label {
    z-index: $hotspot-button-label-z-index;
    position: absolute;
    transform: translateX(-15%) rotate(90deg) scale(0);
    padding: 0.5rem 1rem;

    // because the label is centered, we compensate this by adding half of the button size as margin
    margin-top: calc(#{$hotspot-button-size-medium} / -2);
    border-radius: 1rem;
    background-color: $brand-primary;
    color: $white;
    font-size: $font-size-lg;
    box-shadow: $hotspot-button-box-shadow;
    white-space: nowrap;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

    button.hotspot-button:hover + &,
    button.hotspot-button:focus + & {
        @include media-breakpoint-up(lg) {
            opacity: 1;
            transform: translate(-50%, -175%) rotate(0);
        }
    }

    &::before {
        content: '';
        @include align-horizontal(absolute);

        top: 100%;

        // create triangle with borders
        border: rem(12px) solid transparent;
        border-top-color: $brand-primary;
        margin-top: -1px;
    }
}
