.dealerlocator {
    position: relative;

    @include media-breakpoint-up(md) {
        height: 100vh;
        min-height: 575px;
    }

    .row {
        &:first-child {
            height: 100%;
        }
    }

    .col-search {
        position: relative;

        .flyout-container {
            display: none;

            .icon {
                &--close {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 15px;
                    height: 25px;
                    fill: $black;
                    pointer-events: all;
                    z-index: 100;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        &.col-flyout {
            .flyout-container {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 325px;
            max-width: 325px;
            height: 100%;
            overflow: hidden;
            transition: 200ms flex ease-in-out;

            &.col-flyout {
                flex: 0 0 650px;
                max-width: 650px;

                .flyout-container {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 325px;
                    height: 100%;
                    z-index: 5;
                    display: block;
                    background-color: $grey-100;

                    &.collapse {
                        display: none;
                    }
                }
            }
        }

        .collapse-trigger {
            display: block;
            color: $white;

            span {
                flex-shrink: 0;
            }

            .icon {
                fill: $white;
                transition: 200ms transform ease-in-out;
            }

            &.is-active {
                .icon {
                    transform: rotate(180deg);
                }
            }

            hr {
                width: 100%;
                height: 1px;
                margin: 0;
                border-top: 1px solid theme-color(primary-light);
            }
        }

        .search {
            margin: 0;
            padding: 0;
            width: 100%;
            background-color: theme-color(primary);
            transition: 200ms transform ease-in-out;
            display: none;

            @include media-breakpoint-up(md) {
                transform: translateX(-100%);
                height: 100%;
                width: 325px;
                display: block;
            }

            &.is-active {
                display: block;
                @include media-breakpoint-up(md) {
                    transform: translateX(0);
                }
            }

            &__title {
                @include line-height(38px, 32px);

                margin: 0;
                padding: rem(16px) rem(35px);
                font-size: rem(28px);
                color: $white;
                border-bottom: 1px solid theme-color(primary-light);
            }

            &__form {
                padding: rem(30px) rem(35px);
                margin: 0;

                @include media-breakpoint-up(md) {
                    overflow-y: scroll;
                    height: calc(100% - 100px);
                }

                select {
                    color: $grey-900;
                }

                input {
                    &::placeholder {
                        color: $grey-400;
                    }
                }
            }

            &__fieldset-title {
                @include line-height(20px, 16px);

                margin: rem(24px) 0 0 0;
                font-weight: $font-weight-normal;
                font-size: rem(16px);
                color: $grey-400;
                transition: 200ms transform ease-in-out;

                .icon {
                    fill: #fff;
                    transition: 200ms transform ease-in-out;
                }

                &.is-active {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .loader-holder {
                position: relative;
                margin: 0;
                padding: 0;

                .loader {
                    position: absolute;
                    top: 0;
                    left: 0;

                    .spinner {
                        width: 20px;
                        height: 20px;
                        left: auto;
                        right: rem(20px);
                        transform: translate(0, -50%);
                    }
                }

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        .loader {
            &--search {
                .loader__overlay {
                    background-color: theme-color(primary);
                }

                .spinner {
                    &__circle {
                        background-color: $white;
                    }
                }
            }
        }

        .results {
            position: relative;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
            transition: 200ms transform ease-in-out;
            z-index: 10;
            display: none;

            @include media-breakpoint-up(md) {
                position: absolute;
                top: 0;
                left: 0;
                width: 325px;
                display: block;
                transform: translateX(100%);
            }

            &.is-active {
                display: block;

                @include media-breakpoint-up(md) {
                    transform: translateX(0);
                }
            }

            .search {
                &__results-title {
                    @include line-height(28px, 18px);

                    margin: 0;
                    padding: rem(30px) rem(25px) rem(20px) rem(25px);
                    font-size: rem(18px);
                    font-weight: $font-weight-normal;
                    color: $white;
                    border-bottom: 1px solid theme-color(primary-light);
                    background-color: theme-color(primary);

                    .underline {
                        margin-right: rem(10px);
                        font-weight: $font-weight-bold;
                        border-bottom: 1px solid $white;
                    }

                    .icon {
                        margin-left: rem(-10px);
                        width: 15px;
                        height: 25px;
                        fill: $white;
                        vertical-align: bottom;
                        pointer-events: all;
                        cursor: pointer;
                    }
                }

                &__results-filter {
                    margin: 0;
                    padding: rem(15px) rem(25px);
                    background-color: theme-color(primary);

                    .search__fieldset-title {
                        position: relative;
                        margin-top: 0;
                        margin-bottom: 0;
                        color: $white;
                        display: block;

                        .icon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            fill: #fff;
                            transition: 200ms transform ease-in-out;
                        }

                        &.is-active {
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .form-group--list {
                        padding-top: 15px;
                    }
                }
            }

            .dealerlist {
                position: relative;
                display: block;
                overflow: hidden;
                overflow-y: auto;
                background-color: $white;
                min-height: 200px;

                @include media-breakpoint-up(md) {
                    min-height: auto;
                    flex: 1;
                }

                ul {
                    li {
                        position: relative;
                        margin: 0;
                        padding: 0;
                        border-bottom: $border-default;
                        background-color: transparent;
                        transition: 200ms background-color ease-in-out;

                        &:hover {
                            cursor: pointer;
                        }

                        &:hover,
                        &:active,
                        &.is-active {
                            @include media-breakpoint-up(md) {
                                background-color: $grey-100;
                            }
                        }

                        &.is-active {
                            cursor: default;
                        }

                        &.error {
                            .dealerlist__row {
                                padding-left: rem(25px);
                            }

                            &:hover {
                                cursor: default;
                                background-color: transparent;
                            }
                        }

                        .flyout-container {
                            position: relative;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            min-height: 400px;
                            background-color: $grey-100;

                            &.collapsing,
                            &.show {
                                display: block;
                            }

                            .flyout {
                                padding: rem(30px) rem(30px) rem(30px) rem(70px);
                            }
                        }
                    }
                }

                &__row {
                    position: relative;
                    margin: 0;
                    padding: rem(15px) rem(20px) rem(15px) rem(70px);
                    min-height: 64px;
                }

                &__marker {
                    @include align-vertical(absolute);

                    margin: 0;
                    padding: 0;
                    left: 0;
                    width: 64px;
                    height: 64px;
                    background-image: url('/assets/images/dealer/dealerlocator/marker.svg');
                    background-position: 50% -10px;
                    background-repeat: no-repeat;

                    span {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        display: block;
                        text-align: center;

                        &.dealerlist__number {
                            top: 11px;
                            color: $white;
                            font-size: rem(12px);
                        }

                        &.dealerlist__distance {
                            @include line-height(12px, 14px);

                            bottom: 5px;
                            font-size: rem(14px);
                            color: $grey-600;
                        }
                    }
                }

                &__dealername,
                &__dealerlocation {
                    @include line-height(24px, 16px);

                    margin: 0;
                    padding: 0;
                    font-size: rem(16px);
                    font-weight: $font-weight-normal;
                    color: $grey-800 !important;
                }
            }

            .gradient {
                @include media-breakpoint-up(md) {
                    @include background(linear-gradient(0deg, rgba($white, 1) 0, rgba($white, 0) 100%));

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 70px;
                    width: 100%;
                    pointer-events: none;
                }
            }
        }

        .flyout {
            margin: 0;
            padding: rem(30px);

            &__content {
                margin: 0;
                padding: 0 rem(40px) 0 0;

                span {
                    display: block;
                }

                .organization-name {
                    @include line-height(28px, 24px);

                    font-size: rem(24px);
                }

                .adr {
                    color: $grey-800;
                }

                .country {
                    margin-bottom: rem(15px);
                }

                .tel,
                .email {
                    color: $grey-800;
                    display: block;

                    &:hover {
                        text-decoration: underline;
                    }

                    .icon {
                        width: 16px;
                        height: 16px;
                        margin-top: 3px;
                        margin-right: 10px;
                        float: left;
                        fill: $grey-600;
                    }
                }

                .opened {
                    @include line-height(24px, 16px);

                    font-size: rem(16px);
                }

                .dealertypes {
                    margin-bottom: rem(25px);

                    &__container {
                        margin-left: rem(-5px);
                    }

                    &__title {
                        @include line-height(20px, 16px);

                        margin: rem(20px) 0 rem(10px) 0;
                        font-weight: $font-weight-normal;
                        font-size: rem(16px);
                        color: $grey-600;
                    }

                    &__type {
                        @include line-height(18px, 14px);

                        margin: rem(5px);
                        padding: rem(1px) rem(10px);
                        border-radius: 15px;
                        font-size: rem(14px);
                        color: $grey-600;
                        background-color: $white;
                        display: block;
                        float: left;
                    }
                }
            }
        }
    }

    .col-map {
        height: 100%;

        .map {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }

        .gradient {
            @include background(linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 100%));

            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 100%;
            opacity: 0.4;
            pointer-events: none;
        }

        .loader {
            &__overlay {
                opacity: 0.7;
            }
        }
    }

    select,
    input {
        transition: 200ms opacity ease-in-out;

        &:disabled {
            opacity: 0.4;
            pointer-events: none;
            cursor: default;
        }
    }

    .form-control {
        margin: 0 0 rem(15px) 0;

        &--latlng {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                appearance: none;
                margin: 0;
            }
        }
    }

    .btn--dealer-latlng {
        border: 1px solid $white;

        &[disabled] {
            border: 0;

            &:hover {
                @include background-image(
                    linear-gradient(180deg, rgba($brand-primary-light, 1) 0, rgba($brand-primary, 1) 100%) !important
                );
            }
        }
    }

    .form-group--list {
        margin-bottom: rem(20px);
        margin-left: rem(-5px);
    }

    .form-check {
        &.uselocation,
        &.dealertype {
            padding: 0;

            .form-check-input {
                display: none;
            }

            .form-check-label {
                color: $white;
                border: 1px solid theme-color(primary-light);
                transition: 200ms background-color ease-in-out, 200ms color ease-in-out;
            }
        }

        &.uselocation {
            margin: 0 0 rem(15px) 0;
            padding: 0;

            .form-check-label {
                position: relative;
                margin: 0;
                padding: rem(9px) rem(15px) rem(9px) rem(60px);
                width: 100%;
                border-radius: 4px;
                cursor: pointer;

                .location-arrow {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    width: 44px;
                    height: 40px;
                    border-right: 1px solid theme-color(primary-light);

                    .icon {
                        @include align-center(absolute);

                        fill: $white;
                        width: 16px;
                        height: 20px;
                        transition: 200ms fill ease-in-out;
                    }
                }

                &:hover {
                    background-color: theme-color(primary-light);
                }
            }

            .form-check-input {
                &:checked ~ .form-check-label {
                    background-color: $white;
                    color: theme-color(primary);

                    .location-arrow {
                        .icon {
                            fill: theme-color(primary);
                        }
                    }
                }
            }
        }

        &.dealertype {
            display: block;
            float: left;

            .form-check-label {
                margin: rem(5px);
                padding: rem(1px) rem(13px);
                border-radius: 15px;
                cursor: pointer;

                &:hover {
                    background-color: theme-color(primary-light);
                }
            }

            .form-check-input {
                &:checked ~ .form-check-label {
                    background-color: $white;
                    color: theme-color(primary);
                }
            }
        }
    }
}
