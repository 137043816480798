.filter {
    position: relative;
    margin: 0;
    padding: 0;

    .mobile-collapse-toggle {
        margin-bottom: rem(20px);
        width: 100%;

        .icon {
            position: absolute;
            top: 12px;
            right: 10px;
            transition: transform 200ms ease-in-out;
        }

        &[aria-expanded='true'] {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    .filter {
        border-bottom: $border-default;

        &--dropdowns {
            padding-bottom: rem(10px);
        }

        .dropdown {
            margin: 0 0 rem(15px) 0;
            background-color: $white;
            border: $border-default;
            border-radius: 4px;

            .ps {
                &__rail-y {
                    display: block;
                    opacity: 0.6;
                }

                &__thumb-y {
                    opacity: 0.6;
                }
            }

            &.show {
                border-radius: 4px 4px 0 0;

                .dropdown-toggle {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .dropdown-menu {
                margin: -1px 0 0 -1px;
                padding: 0 rem(25px) 0 rem(15px);
                border-radius: 0 0 4px 4px;
                width: calc(100% + 2px);
                max-height: 395px;
                overflow: hidden;
            }

            &--wide {
                .dropdown-menu {
                    @include media-breakpoint-up(md) {
                        width: 312px;
                        border-radius: 0 4px 4px;
                    }
                }
            }

            .dropdown-item {
                padding: 0;
                white-space: normal;
                border-bottom: $border-default;
                cursor: pointer;

                [class*='col-'] {
                    margin: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }

                &:active,
                &:hover,
                &:focus {
                    background-color: transparent;
                }

                .row {
                    position: relative;
                    margin-left: -15px;
                    margin-right: -25px;
                    padding: rem(15px) rem(15px) rem(15px) rem(5px);

                    @include media-breakpoint-up(md) {
                        padding: rem(8px) rem(15px) rem(8px) rem(5px);
                    }
                }

                &__input {
                    display: none;

                    &:checked ~ .row {
                        background-color: $grey-100;

                        .dropdown-item__option {
                            color: theme-color(secondary);
                        }
                    }

                    &:disabled ~ .row {
                        opacity: 0.5;
                    }
                }

                &__option {
                    @include line-height(21px, 14px);

                    margin: 0;
                    font-size: rem(14px);
                    color: theme-color(primary);
                    display: block;
                    cursor: pointer;
                }

                &__image {
                    @include ratio-box(100%, 3, 1);

                    margin-right: 30px;
                }

                .badge {
                    // position: absolute;
                    // top: 10px;
                    @include align-vertical(absolute);

                    right: 20px;
                    color: $grey-400;
                    font-weight: $font-weight-normal;
                }
            }
        }

        .collapse-toggle,
        .dropdown-toggle {
            @include line-height(22px, 15px);

            margin: 0;
            padding: rem(10px) rem(25px) rem(10px) rem(15px);
            font-size: rem(15px);
            font-weight: $font-weight-medium;
            cursor: pointer;
            display: block;
            color: $grey-900;

            .icon {
                position: absolute;
                top: 12px;
                right: 5px;
                fill: $grey-900;
                transition: transform 200ms ease-in-out;
                width: 18px;
                height: 18px;
            }
        }

        .collapse-toggle {
            &.active {
                .icon {
                    transform: rotate(45deg);
                }
            }
        }

        .dropdown-toggle {
            padding: rem(10px) rem(25px) rem(10px) rem(15px);
            background-color: transparent;

            &::after {
                display: none;
            }
        }

        &__taglist {
            margin: 0 0 rem(20px) rem(15px);

            .tag {
                position: relative;
                padding: rem(2px) rem(35px) rem(2px) rem(24px);

                .custom-control-label {
                    @include line-height(21px, 14px);

                    margin: 0;
                    padding-top: rem(4px);
                    font-size: rem(14px);
                    color: theme-color(primary);
                    font-weight: $font-weight-medium;

                    &::before {
                        top: 7px;
                    }

                    &::after {
                        top: 10px;
                    }
                }

                .badge {
                    position: absolute;
                    top: 8px;
                    right: 4px;
                    color: $grey-400;
                    font-weight: $font-weight-normal;
                }
            }
        }
    }

    // custom checkbox
    .custom-checkbox {
        .custom-control-label {
            cursor: pointer;

            &::before {
                top: 4px;
                width: 16px;
                height: 16px;
                border-radius: rem(2px);
                border: $border-default;
                background-color: $white;
                box-shadow: none !important;
            }

            &::after {
                left: 3px;
                top: 7px;
                width: 10px;
                height: 10px;
                border-radius: rem(2px);
            }
        }

        .custom-control-input {
            &:checked ~ .custom-control-label {
                &::before {
                    background-color: $white;
                    background-image: none;
                }

                &::after {
                    background-color: theme-color(secondary);
                    background-image: none;
                }
            }

            &:indeterminate ~ .custom-control-label {
                &::before {
                    background-color: theme-color(secondary);
                    background-image: none;
                }

                &::after {
                    background-image: none;
                }
            }

            &:focus ~ .custom-control-label {
                ::before {
                    box-shadow: none;
                    background-image: none;
                }
            }

            &:active ~ .custom-control-label {
                &::before {
                    color: theme-color(secondary);
                    background-color: theme-color(secondary);
                    background-image: none;
                    box-shadow: none;
                }
            }

            &:disabled {
                ~ .custom-control-label {
                    color: rgba(theme-color(primary), 0.5);
                    cursor: default;
                    pointer-events: none;

                    &::before {
                        background-color: rgba($white, 0.5);
                        background-image: none;
                    }
                }

                &:checked ~ .custom-control-label {
                    &::before {
                        background-color: rgba($white, 0.5);
                        background-image: none;
                    }

                    &::after {
                        background-color: lighten(theme-color(secondary), 30);
                        background-image: none;
                    }
                }

                &:indeterminate ~ .custom-control-label {
                    &::before {
                        background-color: rgba($white, 0.5);
                        background-image: none;
                    }

                    &::after {
                        // background-color: lighten(theme-color(secondary), 30);
                        background-color: rgba($white, 0.5);
                        background-image: none;
                    }
                }
            }
        }
    }
}
