// Custom body class
.dealer-page {
    .hero-header__content {
        padding-bottom: rem(40px) !important;
    }
}

.location-details {
    padding-top: rem(30px);
    position: relative;

    &__container {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;

        h2 {
            margin-bottom: rem(20px);
            font-weight: $font-weight-light;

            @include media-breakpoint-up(md) {
                margin-bottom: rem(16px);
            }
        }

        [class*='col-'] {
            margin-bottom: 0;
        }

        .offset-lg-1 {
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                margin-bottom: rem(16px);
            }
        }

        @include media-breakpoint-up(xs) {
            max-width: 536px;
        }

        @include media-breakpoint-up(sm) {
            max-width: 728px;
        }

        @include media-breakpoint-up(md) {
            max-width: 952px;
        }

        @include media-breakpoint-up(ld) {
            max-width: 990px;
        }
    }

    &__list {
        margin-bottom: 0;

        li {
            position: relative;
            padding: 0 0 rem(8px) rem(35px);

            @include media-breakpoint-up(md) {
                padding: rem(12px) 0 rem(8px) rem(40px);
            }
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            margin-right: rem(15px);
            fill: $grey-600;
            color: $grey-600;
            width: 20px;
            height: 20px;

            @include media-breakpoint-up(md) {
                top: 15px;
                width: 24px;
                height: 24px;
            }

            &--link,
            &--location {
                top: 3px;
                width: 20px;
                height: 20px;

                @include media-breakpoint-up(md) {
                    top: 17px;
                }
            }

            &--directions,
            &--locationcode {
                top: 0;

                @include media-breakpoint-up(md) {
                    top: 12px;
                }
            }
        }

        p {
            color: $grey-300 !important;
            margin-bottom: 0;
            font-size: rem(14px);

            @include media-breakpoint-up(md) {
                font-size: rem(16px);
            }
        }
    }

    .dealer-map {
        position: relative;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        .icon {
            &--mapdirections {
                pointer-events: none;
            }
        }

        &__container {
            margin-left: -10px;
            margin-right: -10px;
            cursor: pointer;

            .card__image {
                margin: 0;
                max-height: 150px;
                overflow: hidden;
                transition: max-height 0.4s;
                background-image: url('/assets/images/daf/placeholders/dealer-map-background.jpg');
                background-position: 50% 50%;
                background-size: auto;

                // @include media-breakpoint-up(lg) {
                //     background-size: cover;
                // }

                &::before {
                    content: '';
                    background: linear-gradient(180deg, theme-color(primary), rgba(38, 108, 171, 0));
                    opacity: 0.3;
                    z-index: 5;
                    display: block;
                    height: 150px;
                    transition: opacity 0.35s ease-in-out;
                }

                .map-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;

                    // width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: 0.2;
                    transition: opacity 0.35s ease-in-out;
                    z-index: 3;
                    margin-left: -10px;
                    margin-right: -10px;
                    width: 105%;
                }

                &.is-active {
                    max-height: 365px;

                    .dealer-marker {
                        opacity: 1;
                        transition: opacity 0.4s;
                    }

                    .map-overlay {
                        opacity: 0;
                    }

                    &::before {
                        opacity: 0;
                    }
                }

                .dealer-marker {
                    opacity: 0;
                    margin-top: -15px;
                    width: 94px;
                    height: 66px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: opacity 0.3s ease-in-out;
                    z-index: 6;
                }

                .ratio {
                    padding-top: calc(600 / 1920 * 100%);
                }
            }
        }
    }

    .btn {
        &--dealer-mapcontainer {
            display: none;

            @include media-breakpoint-up(md) {
                @include background(
                    linear-gradient(0deg, rgba($brand-primary, 1) 0, rgba($brand-primary-light, 1) 100%)
                );

                $width: 56px;
                $height: 56px;

                position: absolute;
                top: 0 - ($height * 0.5);
                left: 50%;
                margin-left: 0 - ($width * 0.5);
                width: $width;
                height: $height;
                z-index: 10;
                border: 0;
                border-radius: 50%;
                cursor: pointer;
                display: block;
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
                transition: background 0.3s ease-in-out;

                &:active,
                &:focus {
                    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25) !important;
                }

                .icon {
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    margin-left: -27px;
                    width: 54px;
                    height: 35px;
                    fill: #fff;
                    opacity: 1;
                    transition: opacity 0.3s ease-in-out;

                    &--open {
                        opacity: 1;
                    }

                    &--close {
                        top: 10px;
                        margin-left: -27px;
                        width: 54px;
                        height: 35px;
                        opacity: 0;
                        fill: $brand-primary;
                    }
                }

                &.is-active {
                    @include background(linear-gradient(0deg, rgba(#e8e9ea, 1) 0, rgba(#e8e9ea, 1) 100%));

                    .icon {
                        &--open {
                            opacity: 0;
                        }

                        &--close {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
