.teaser {
    position: relative;
    min-height: rem(378px);
    flex-direction: row;
    padding-bottom: rem(55px);

    // .content{
    //     display: flex;
    // }
    @include media-breakpoint-down(md) {
        padding-bottom: 0;
        min-height: 0;
        width: 100%;

        &::after {
            content: '';
            display: block;
            padding-top: 100%; /* ratio of 1:1 */
        }

        // div.content {
        //     display: contents;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        // }
        .teaser-body.align-self-end {
            top: rem(-50px);
        }
    }

    &__image {
        @include fill-parent-absolute;
    }

    &__gradient {
        @include fill-parent-absolute;
        @include background(linear-gradient(0deg, $black, transparent));

        opacity: 0.5;
    }

    &-body {
        position: relative;
        padding: rem(50px) rem(40px) 0 rem(40px);
        z-index: 15;

        .card-title {
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        .card-title,
        .card-text {
            text-shadow: 0 0 0 rgba($black, 0.25), 0 0 60px rgba($black, 0.75);
            color: $white !important;
        }

        .btn {
            position: absolute;
            bottom: -75px;
            left: 40px;
        }
    }
}

// background variant
.bg-blue {
    .teaser-body {
        .btn {
            @include background-image(linear-gradient(90deg, rgba($grey-700, 1) 0, rgba($grey-900, 1) 100%) !important);

            &:hover,
            &:focus,
            &:active,
            &.active {
                @include background-image(
                    linear-gradient(0deg, rgba($grey-400, 1) 0, rgba($grey-100, 1) 100%) !important
                );

                .icon {
                    fill: theme-color(primary) !important;
                }
            }
        }
    }
}
