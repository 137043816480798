.icon {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
        vertical-align: unset;
    }

    &--linkedin {
        margin-top: -2px;
    }

    &--google-plus {
        width: 34px;
    }

    &--highlight,
    &--diverge {
        width: 30px;
        height: 30px;
    }

    &-container {
        margin: 0 0 rem(60px) 0;
        padding: 0;
    }

    &-tile {
        @include make-col(12);

        @include media-breakpoint-up(sm) {
            @include make-col(6);

            .icon {
                width: 34px;
                height: 34px;
            }
        }
        @include media-breakpoint-up(md) {
            @include make-col(3);
        }
    }
}
