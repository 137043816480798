.paged-search-results {
    .loader {
        display: none;
    }

    &__search-form {
        align-items: center;

        .btn {
            &-primary {
                font-size: rem(14px);

                @include media-breakpoint-up(lg) {
                    font-size: rem(16px);
                }
            }
        }
    }

    &__results-list {
        list-style: none;
        padding: 0;
    }

    &__result-item {
        display: block;
        background-color: $white;
        padding: 10px;
        margin-bottom: 10px;
        border-bottom: solid 1px $grey-200;
        position: relative;
        width: 100%;

        &:hover {
            box-shadow: 0 5px 25px -10px rgba(0, 0, 0, 0.25);
        }

        a {
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        [class*='col-'] {
            margin: 0;
        }

        .label {
            display: block;
            font-size: 90%;
        }

        h2 {
            color: theme-color(primary);
            margin-top: rem(10px);
            margin-bottom: rem(10px);
            font-size: rem(20px);
            font-weight: 500;
        }

        p {
            color: $grey-500 !important;
        }
    }
}
