.card {
    $card: '.card';

    &--news {
        $gutter-height: 20px;

        margin: 0;
        background-color: $grey-100;
        border-radius: 0;
        padding-bottom: 0;

        a {
            display: block;
        }

        &:hover {
            box-shadow: 0 0 78px 0 rgba(0, 0, 0, 0.25);
        }

        #{$card}-date {
            color: $grey-600;
            font-size: rem(14px);
            font-weight: $font-weight-normal;
            margin-bottom: rem(5px);
            font-family: $font-family-arial;
            display: block;
        }

        #{$card}-title {
            @include line-height(24px, 18px);

            color: $grey-800;
            text-align: left;
            font-size: rem(18px);
            font-weight: $font-weight-bold;
            margin-bottom: 0;
        }

        #{$card}__image {
            position: relative;
            margin: 0;
        }

        #{$card}__downloads {
            position: absolute;
            bottom: 10px;
        }

        .btn-link {
            position: relative;
            bottom: auto;
            left: 0;
            font-size: rem(14px);
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;

            &:hover {
                text-decoration: underline !important;
            }

            .icon {
                position: relative;
                top: 5px;
            }
        }
    }
}

.bg-medium {
    .card {
        $card: '.card';

        &--news {
            background-color: $white;
        }
    }
}
