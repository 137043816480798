.questionnaire {
    margin: 0;

    &__container {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        width: 100%;

        [class*='col-'] {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xs) {
            max-width: 536px;
        }

        @include media-breakpoint-up(sm) {
            max-width: 728px;
        }

        @include media-breakpoint-up(md) {
            max-width: 952px;
        }

        @include media-breakpoint-up(ld) {
            max-width: 990px;
        }
    }

    .visual {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50%;
    }

    .carousel {
        &-inner {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &-item {
            min-height: 300px;
            align-items: center;
            justify-content: center;

            &.active,
            &-prev,
            &-next {
                display: flex;
            }
        }

        &-indicators {
            transition: opacity 600ms, visibility 600ms;

            &.is-hidden {
                visibility: hidden;
                opacity: 0;
            }

            &.is-active {
                visibility: visible;
                opacity: 1;
                display: flex;
            }

            li {
                pointer-events: none;
                background-color: $grey-800;

                &.is-hidden {
                    display: none;
                }

                &.active {
                    background-color: $grey-400;
                }
            }
        }
    }

    h2 {
        margin-bottom: rem(25px);
        font-weight: 700;
        color: $grey-800;

        &.question {
            margin-bottom: 30px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 25px;
            }
        }
    }

    h3 {
        margin: 0 auto 30px;
        width: 100%;
        max-width: 280px;
        color: $grey-600;

        @include media-breakpoint-up(md) {
            max-width: 420px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 510px;
        }
    }

    .btn {
        &--start {
            margin-bottom: 0;
        }

        &--answer {
            border-radius: rem(35px);
            font-size: rem(16px);
            border: solid 1px $grey-400;
            color: $grey-600;

            &:hover {
                border: solid 1px transparent !important;
                color: $white;
            }
        }
    }

    .loader {
        display: none;
    }
}

.bg-medium {
    .btn {
        &--answer {
            border: solid 1px $grey-400;
            color: $grey-800;

            &:hover {
                color: $white;
                border: solid 1px transparent !important;
            }
        }
    }

    .carousel-indicators {
        li {
            background-color: $grey-800;

            &.active {
                background-color: $grey-400;
            }
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .btn {
        &--answer {
            border: solid 1px $grey-400;
            color: $white;

            &:hover {
                border: solid 1px transparent !important;
            }
        }
    }

    .carousel-indicators {
        li {
            background-color: $white;

            &.active {
                background-color: $grey-50;
            }
        }
    }
}
