$truckconfigurator-header-height: rem(70px);

.otc-message {
    z-index: 9999;
    display: none;
    position: fixed;
    top: $truckconfigurator-header-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$truckconfigurator-header-height});
    padding: rem(16px) rem(32px) 0;

    // background-color: $brand-primary;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: orange;

    // &.is-active {
    //   display: flex;
    // }
}

.otc-message-icon {
    width: rem(80px);
    height: rem(80px);
    fill: $white;
    border: solid 10px red;
}
