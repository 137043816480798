@use 'sass:math';

$height: 210px;
$height-sm: 315px;
$height-lg-small: 390px;
$height-lg-medium: 433px;
$height-lg-large: 650px;

.hero-header {
    position: relative;
    padding: 0;
    z-index: 1;

    .visual {
        position: relative;
        height: $height;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-up(sm) {
            height: $height-sm;
        }

        @include media-breakpoint-up(lg) {
            height: $height-lg-small;
        }

        // create --2of3 like classnames
        @for $i from 1 through 3 {
            @for $j from 1 through 3 {
                @if $j <= $i {
                    &--#{$j}of#{$i} {
                        width: percentage(math.div(1, $i));
                        float: left;
                    }
                }
            }
        }
    }

    &--medium {
        .visual {
            @include media-breakpoint-up(lg) {
                height: $height-lg-medium;
            }
        }
    }

    &--large {
        .visual {
            @include media-breakpoint-up(lg) {
                height: $height-lg-large;
            }
        }
    }

    &--medium,
    &--large {
        .visual {
            &--1of1,
            &--1of2,
            &--1of3 {
                width: 100%;
            }

            &--2of2,
            &--2of3,
            &--3of3 {
                display: none;
            }
        }
    }

    &__visual {
        position: relative;

        .gradient {
            @include background(linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 100%));

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
        }
    }

    &.text-right {
        .hero-header__content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;

            @include media-breakpoint-up(sm) {
                text-align: center;
            }

            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }

        .hero-header__title,
        .hero-header__subtitle,
        .hero-header__text {
            max-width: none;
        }
    }

    &__content {
        padding-top: rem(35px);
        text-align: left;

        @include media-breakpoint-up(sm) {
            text-align: center;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: rem(15px);
            text-align: left;
        }

        @include media-breakpoint-up(sm) {
            .body {
                max-width: 480px;
            }
        }

        @include media-breakpoint-up(lg) {
            @include align-center(absolute);

            position: absolute;
            height: 100%;
        }

        .body {
            width: 100%;

            @include media-breakpoint-up(lg) {
                @include align-vertical(relative);

                padding: rem(35px) 0;
                max-width: 551px;
            }
        }

        .btn {
            margin-bottom: rem(30px);

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        // if text is not defined or hidden
        .h3 + .btn,
        .d-none + .btn {
            margin-top: rem(20px);
        }
    }

    &__title {
        margin-bottom: rem(20px);
        @include media-breakpoint-up(lg) {
            text-shadow: 0 1px 1px rgba($black, 0), 0 0 150px rgba($black, 0.75);
        }
    }

    &__subtitle,
    &__text {
        @include media-breakpoint-up(lg) {
            text-shadow: 0 0 0 rgba($black, 0.25), 0 0 60px rgba($black, 0.75);
        }
    }

    @include media-breakpoint-down(sm) {
        &__title,
        &__subtitle,
        &__content .btn {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__text {
        margin-bottom: rem(22px);
    }

    &.no-visual {
        min-height: 433px;

        .hero-header__title,
        .hero-header__subtitle,
        .hero-header__text {
            @include media-breakpoint-up(lg) {
                text-shadow: none;
            }
        }

        .gradient {
            display: none;
        }
    }

    &__cta-box {
        position: relative;
        margin: rem(40px) 0 0 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin: rem(60px) 0 rem(20px) 0;
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            right: 0;
            bottom: -30px;
            width: 390px;
            margin: 0;
            z-index: 9000;
        }
    }
}

.text-lg-light {
    .btn-ghost,
    .btn--ghost {
        border-color: $brand-primary;
        color: $brand-primary;

        &:hover {
            color: $white;
        }
    }

    @include media-breakpoint-up(lg) {
        .hero-header__title,
        .hero-header__subtitle,
        .hero-header__text,
        .btn-ghost,
        .btn--ghost {
            color: $white !important;
        }

        .btn-ghost,
        .btn--ghost {
            border-color: $white;

            &:hover {
                border-color: rgba(0, 0, 0, 0);
            }
        }
    }
}

.carousel {
    .hero-header {
        &__content {
            padding-top: rem(54px) !important;
        }
    }
}
