/* CUSTOM OVERRIDES FOR DAF OTC */

.sp-palette-container,
.sp-picker-container {
    padding-bottom: 0;
    margin-bottom: 0;
}

.sp-container {
    background: transparent;
    border: none;
    width: 100%;
}

.sp-picker-container {
    border: none;
    padding: 0;
    width: 100%;
}

.sp-cancel {
    color: $white !important;
}

.sp-choose {
    @extend .btn;
    @extend .btn-ghost-white;
    @extend .btn-small;

    margin: 0;
}
