.otc-loader {
    display: block;
    border-radius: rem(8px);
    background: $white;
    transition: height 300ms ease;
    cursor: pointer;
    padding: rem(60px) rem(30px) rem(20);
    border: solid $brand-primary-dark rem(1px);
    width: calc(50% - #{rem(6px)});

    &.is-active {
        // position: fixed;
        // left: 0;
        // right: 0;
        // top:0;
        // width: 100%;
        // bottom: 0;
        // border: none;
        // margin-right: 0;
        // z-index: 1;

        .otc-loader__title {
            .icon {
                transform: translateY(-50%);
            }
        }
    }

    &__popup {
        display: none;
        max-width: rem(990px);
        margin: 0 auto;
        z-index: 100;

        &.is-active {
            background: $white;
            display: block;
            position: fixed;
            left: 0;
            right: 0;
            width: 100%;
            bottom: rem(68px);
            border: none;
            z-index: 1;

            &::before {
                // content:"";
                // top: 0;
                // bottom: 0;
                // left: 0;
                // right: 0;
                // position: fixed;
                // background: $grey-400;
                // opacity: 0.5;
            }
        }
    }

    .otc-loader__header {
        background-color: $brand-primary;
        display: flex;
        align-items: center;
        height: rem(64px);
        margin: 0 rem(-30px);

        .icon {
            display: block;
            margin-left: auto;
            margin-right: rem(20px);

            // position: absolute;
            // right: 20px;
            // top: 50%;
            // transform: translateY(-50%) rotate(180deg);
            transition: transform 300ms ease;
            fill: $white;
        }
    }

    .otc-loader__title {
        font-size: rem(20px);
        line-height: rem(25px);
        padding: 0 rem(30px);
        color: $white;
        margin: 0;
    }

    .otc-loader__intro {
        font-size: rem(14px);
        line-height: 1.5;
        font-weight: 400;
    }

    .otc-loader__error-text {
        color: $red;
        line-height: 2.375;
        margin-bottom: 1rem;
        display: none;

        &.is-active {
            display: block;
        }
    }

    .otc-loader__btn {
        margin: rem(15px) 0 rem(20px) 0;

        &:hover {
            color: $white;
        }
    }
}
