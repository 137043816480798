.marketselector {
    background-color: theme-color(primary);

    .selector {
        &__title {
            margin: 0;
            padding: rem(50px) 0 rem(20px) 0;
            font-size: rem(40px);
            @include line-height(38px, 40px);

            color: $white !important;
        }

        &__text {
            margin: 0 0 rem(40px) 0;
            color: $white !important;
        }

        &__form {
            padding-bottom: rem(50px);
        }
    }
}
