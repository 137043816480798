.vacancy {
    .custom-border-bottom {
        border-bottom: $border-default;
    }

    ul,
    ol {
        li {
            font-size: rem(14px);
            @include line-height(21px, 14px);

            font-weight: $font-weight-normal;
            color: text-color(dark);

            @include media-breakpoint-up(lg) {
                @include line-height(25px, 16px);

                font-size: rem(16px);
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $white;
                }
            }
        }
    }

    &__header {
        h2 {
            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $white;
                }
            }
        }
    }

    p {
        @at-root {
            .bg-dark &,
            .bg-blue &,
            .bg-black & {
                color: $white;
            }
        }
    }

    &--detail {
        section {
            margin-bottom: 20px;
        }

        .information-list {
            span {
                display: inline-block;

                @at-root {
                    .bg-dark &,
                    .bg-blue &,
                    .bg-black & {
                        color: $white;
                    }
                }

                &:first-of-type {
                    min-width: 200px;
                }
            }

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                color: $grey-600;
                fill: $grey-600;
            }
        }
    }

    &__buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
        z-index: 900;
        background-color: #fff;
        border-top: $border-default;

        @include media-breakpoint-up(md) {
            padding: 25px 0;
        }

        a {
            margin-right: 5px;

            @include media-breakpoint-up(md) {
                margin-right: 10px;
            }
        }

        .btn-ghost {
            border-color: theme-color(primary);
            color: theme-color(primary);

            &:hover {
                color: #fff;
            }
        }
    }
}
