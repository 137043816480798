.hubspot-multi-step-form {
    opacity: 0;
    width: 100%;
    max-width: 750px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        max-width: 728px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 990px;
    }

    &.initialized {
        opacity: 1;
        transition: 500ms ease;
    }

    .search__title {
        margin: 0 0 0.625rem;
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 200;
    }

    .sub__title {
        line-height: 1.5556;
        margin: 0 0 1.25rem;
        font-size: 1.125rem;
        font-weight: 500;
    }

    .submitted-message {
        text-align: center;
        font-size: 25px;
        padding: 40px;
    }

    .step-form {
        width: 100%;
        margin: 0 auto;
        padding-right: 0;
    }

    .step-slide--inner {
        padding-top: 0;

        @include media-breakpoint-up(md) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .step-slide--inner fieldset {
        max-width: none;
        border: none;
        padding: 6px 12px 12px;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    .hs-form-field {
        margin-bottom: 24px;
    }

    .step-form--container {
        padding-top: 40px;
    }

    .step-slide--inner fieldset div {
        width: 100%;
        padding: 0 10px;
        position: relative;
    }

    .step-slide--inner fieldset div .input {
        padding: 0;
    }

    .step-slide--inner fieldset div input,
    .step-slide--inner fieldset div select,
    .step-slide--inner fieldset div input:focus {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border: 1px solid #cbd6e2;
        outline: none;
        border-radius: 3px;
        background-color: #f5f8fa;
    }

    .slick-slide {
        padding-bottom: 20px;
    }

    .step-slide--inner fieldset div input.error {
        border: 1px solid red;
    }

    .step-slide--inner fieldset div input.error:focus {
        border: 1px solid #cbd6e2;
    }

    .step-slide--inner fieldset div .input .inputs-list {
        padding: 0;
        margin: 0;
        margin-top: 15px;
        list-style: none;
        display: flex;
        flex-direction: column;
    }

    .step-slide--inner fieldset div .input .inputs-list .hs-form-checkbox {
        margin-bottom: 10px;
        font-size: 15px;
    }

    .step-slide--inner fieldset div .input .inputs-list .hs-form-checkbox label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .step-slide--inner fieldset div .input .inputs-list .hs-form-checkbox label input {
        max-width: 15px;
        height: auto;
        margin: 0;
        margin-right: 5px;
    }

    /*       Slick */

    .step-form--container .slick-dots {
        bottom: unset;
        top: 40px;
        display: none !important;
    }

    .step-form--container .slick-dots li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin: 0 35px;
    }

    .step-form--container .slick-dots li.slick-active {
        color: #fff;
        background-color: #00529c;
    }

    .step-form--container .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 40px;
        color: #fff;
        top: unset;
        bottom: -30px;
        font-weight: 500;
        font-size: 20px;
        border-radius: 4px;
        transition: all 300ms ease;
        background-image: linear-gradient(180deg, #266cab, #00529c) !important;
        border: 0;

        /*         -webkit-transition: background-image .1s ease-in-out;
  -o-transition: .1s background-image ease-in-out;
  transition: background-image .1s ease-in-out; */
    }

    .step-form--container .slick-arrow:hover {
        background-image: linear-gradient(180deg, #215f96, #004583) !important;
    }

    .step-form--container .slick-arrow.submit,
    .step-form--container .slick-arrow.submit:focus {
        border: none;
        left: 140px;
        right: 0;
        margin: auto;
        outline: none;
        bottom: -10px;
        position: absolute;
        cursor: pointer;
    }

    .step-form--container .slick-arrow.submit.centered {
        left: 0;
    }

    .step-form--container .slick-prev {
        left: 0;
        right: 140px;
        margin: auto;
    }

    .step-form--container .slick-next {
        left: 140px;
        right: 0;
        margin: auto;
        z-index: 1;
    }

    .step-form--container .slick-next.centered {
        left: 0;
    }

    .step-form--container .slick-next.slick-disabled,
    .step-form--container .slick-prev.slick-disabled {
        display: none !important;
    }

    .step-form--container .slick-arrow::before {
        display: none;
    }

    .step-form--container .slick-arrow:hover,
    .step-form--container .slick-arrow:focus {
        color: #fff;
        background-color: #00529c;
    }

    .step-form .slick-dotted.slick-slider {
        padding-bottom: 60px;
    }

    .hs-error-msgs {
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
    }

    .hs-error-msgs label {
        color: red;
        font-size: 12px;
    }

    .hs_submit {
        display: none;
    }

    .legal-consent-container .hs-form-booleancheckbox-display {
        display: flex;
    }

    .legal-consent-container .hs-dependent-field {
        padding: 0;
    }

    .legal-consent-container .hs-form-booleancheckbox-display input,
    .legal-consent-container .hs-form-booleancheckbox-display input:focus {
        max-width: 25px;
        height: 15px;
        margin-top: 18px;
    }

    .legal-consent-container .hs-fieldtype-booleancheckbox {
        padding: 0;
    }

    .notification {
        background-color: rgba(255, 207, 209, 0.2);
        border: 1px solid red;
        border-radius: 4px;
        font-size: 15px;
        color: red;
        font-weight: 400;
        padding: 15px;
        text-align: center;
        display: none;
    }

    .step-form--container .hs-input[class*='phone'] {
        padding: 0;
    }

    .step-form--container .hs-input[class*='phone'] > input {
        max-width: 65%;
        margin-left: 12px;
    }

    .step-form--container .hs-input[class*='phone'] > select {
        max-width: 30%;
    }
}
