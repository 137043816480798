.download-item {
    border-bottom: 1px solid $grey-500;
    font-size: $font-size-sm;

    &__title {
        color: $brand-primary;
        font-weight: $font-weight-bold;
        text-decoration: underline;
        font-size: inherit;
    }

    &__detail {
        color: $grey-800;

        // adjust to dark backgrounds
        .text-light & {
            color: $grey-300;
        }
    }

    .icon {
        fill: $brand-primary;

        // adjust to dark backgrounds
        .text-light & {
            fill: $white;
        }
    }
}
