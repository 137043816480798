.importsearch {
    .itemlist {
        position: relative;

        @include media-breakpoint-up(md) {
            margin-bottom: rem(25px);
            padding-left: rem(15px);
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        [class*='col-'] {
            margin: 0;
        }

        &__header {
            padding-bottom: 15px;

            // border-bottom: solid 1px $grey-400;
            font-size: rem(15px);
            font-weight: $font-weight-medium;
            color: $grey-600;
            transition: 200ms background-color ease-in-out;
        }

        &__item {
            padding-top: 20px;
            padding-bottom: 20px;
            border-top: solid 1px $grey-400;

            &:last-child {
                border-bottom: solid 1px $grey-400;
            }

            &:hover {
                background-color: $white;
            }
        }

        .item {
            &__image {
                @include ratio-box(100%, 3, 1, false);

                margin: 0;
                padding: 0;
                background-size: cover;
            }

            &__description,
            &__drawing-number,
            &__link {
                @include line-height(21px, 14px);

                margin: 0;
                font-size: rem(14px);
            }

            &__download-link,
            &__open-link {
                margin: rem(10px) rem(10px) 0 rem(10px);
                display: block;
                float: left;
                cursor: pointer;
                text-decoration: none;
                width: 24px;
                height: 24px;

                &--zip {
                    width: 24px;
                    height: 21px;

                    .icon {
                        width: 22px;
                        height: 21px;
                    }
                }
            }

            &__open-link {
                &--pdf {
                    height: 19px;

                    .icon {
                        height: 19px;
                    }
                }
            }

            &__icon {
                fill: theme-color(primary);
                cursor: pointer;
            }
        }
    }
}

.specsheet-list {
    .btn--download-all {
        position: absolute;
        right: 0;
        font-size: rem(12px);
        transform: translateY(-40px);

        @include media-breakpoint-up(md) {
            transform: translateY(0);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem(14px);
        }

        .icon {
            left: -16px;
            top: 10px;
            width: 14px;
            height: 14px;

            @include media-breakpoint-up(lg) {
                left: -20px;
                top: 7px;
                width: 18px;
                height: 18px;
            }
        }
    }
}
