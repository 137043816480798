.form-control {
    &--selected-dealer {
        padding: rem(5px) rem(40px) rem(5px) rem(25px) !important;
        border-color: $grey-400;
        background-color: $white;
        color: $grey-900;
        height: 42px;
        box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1);
        overflow: hidden;
        position: relative;

        .icon--marker {
            position: absolute;
            top: 8px;
            left: 10px;

            svg {
                fill: theme-color(primary);
                color: theme-color(primary);
            }
        }

        span {
            + p {
                @include line-height(29px, 16px);

                display: inline;
                padding-left: 20px;
                font-size: rem(16px);
                color: $grey-900 !important;

                @at-root {
                    .text-light & {
                        color: $grey-900 !important;
                    }
                }
            }
        }
    }

    &--selected-dealer-no-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: rem(15px) !important;

        p {
            width: 100%;
            white-space: nowrap;
            margin-bottom: 0;
            color: $grey-900 !important;
        }
    }
}
