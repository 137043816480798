/* stylelint-disable no-duplicate-selectors */
[class^='icon-'],
[class*=' icon-'],
.icon-base {
    /* use !important to prevent issues with browser extensions that change fonts */
    &::before {
        font-family: icomoon, arial, helvetica, sans-serif !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: greyscale;
    }
}

.icon-chevron-left::before {
    content: '\e900';
}

.icon-chevron-right::before {
    content: '\e901';
}

.icon-chevron-up::before {
    content: '\e902';
}

.icon-chevron-down::before {
    content: '\e903';
}

.icon-close::before {
    content: '\e904';
}

.icon-contact::before {
    content: '\e905';
}

.icon-open::before {
    content: '\e906';
}

.icon-plus-sign::before {
    content: '\e907';
}

.icon-save::before {
    content: '\e908';
}

.icon-share::before {
    content: '\e909';
}

.icon-truck-front::before {
    content: '\e90a';
}

/* Font Awesome Icons */
.icon-font-angle-up::before {
    content: '';
}

.icon-font-angle-down::before {
    content: '';
}

.icon-font-angle-left::before {
    content: '';
}

.icon-font-angle-right::before {
    content: '';
}

.otc-icon {
    font-family: inherit;

    &::before {
        background-image: none;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: fontawesome;
        font-size: 1.325em;
        font-weight: normal !important;
        line-height: 1;
        text-indent: 0;
        vertical-align: middle;
    }
}

.icon-back,
.icon-next,
.icon-up,
.icon-down {
    @extend .otc-icon;

    position: relative;

    &::before {
        position: absolute;
        top: 0.825em;
        font-size: 1em;
    }
}

.icon-back {
    @extend .icon-font-angle-left;

    padding-left: 1em;

    &::before {
        left: 0;
    }
}

.icon-next {
    @extend .icon-font-angle-right;

    padding-right: 1em;

    &::before {
        right: 0;
    }
}

.icon-up {
    @extend .icon-font-angle-up;

    &::before {
        right: 0;
        top: 0;
        transform: rotate(0);
        will-change: transform, top;
        transition: transform 300ms ease, top 300ms ease;
    }
}

.icon-up-rotate {
    @extend .icon-font-angle-up;

    &::before {
        transform: rotate(180deg);
        will-change: transform, top;
        transition: transform 300ms ease, top 300ms ease;
    }
}

.icon-down {
    @extend .icon-font-angle-down;

    &::before {
        right: 0;
        top: 0;
        transform: rotate(0);
        will-change: transform, top;
        transition: transform 300ms ease, top 300ms ease;
    }
}

.icon-down-rotate {
    @extend .icon-font-angle-down;

    &::before {
        transform: rotate(180deg);
        will-change: transform, top;
        transition: transform 300ms ease, top 300ms ease;
    }
}

.icon-no-label {
    // Needs to have display: block or display: inline-block
    text-indent: -999rem;

    &::before {
        text-indent: 0;
        display: block;
    }
}

.icon-paper-plane {
    svg {
        height: rem(19px);
        width: rem(19px);
    }
}

.icon-save {
    &::before {
        height: rem(18px);
        width: rem(18px);
    }
}

.icon-truck-front {
    &::before {
        height: rem(33px);
        width: rem(33px);
    }
}
