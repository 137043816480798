// Custom body class
.dealer-page {
    .hero-header__content {
        padding-bottom: rem(36px) !important;
    }

    .checkmarklist-container {
        @include media-breakpoint-up(md) {
            max-width: 450px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 600px;
        }

        .list--checkmark {
            white-space: normal;

            li {
                @include line-height(32px, 18px);

                font-size: rem(18px);
            }
        }
    }
}

.basic-info {
    height: auto;

    @include media-breakpoint-up(md) {
        height: 0;
    }
}

.card {
    $card: '.card';

    &--basic-info {
        background-color: #fff;
        position: relative;
        z-index: 1;
        margin-top: -50px;
        border-radius: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        box-shadow: 0 8px 78px 0 rgba(50, 50, 50, 0.1);

        @include media-breakpoint-up(md) {
            margin-top: -50px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: -200px;
        }

        #{$card}__header {
            background-color: theme-color(primary);
            padding: rem(15px) rem(20px);
            height: 50px;

            @include media-breakpoint-up(md) {
                padding: rem(15px) rem(13px);
            }

            @include media-breakpoint-up(lg) {
                padding: rem(17px) rem(30px);
                height: 60px;
            }

            h4 {
                @include line-height(22px, 16px);

                margin: 0;
                width: 100%;
                font-size: rem(16px);

                @include media-breakpoint-up(lg) {
                    @include line-height(26px, 18px);

                    font-size: rem(18px);
                }
            }

            a {
                color: $white;
                display: block;
            }

            span {
                color: $white;
            }

            .icon {
                width: 28px;
                height: 28px;
                color: $white;
                fill: $white;
            }
        }

        #{$card}__image {
            position: relative;
            display: none;
            max-height: 140px;

            @include media-breakpoint-up(lg) {
                display: block;
                margin: 0;
            }

            .icon {
                &--static-marker {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -100%);
                    fill: theme-color(primary);
                }
            }
        }

        #{$card}-body {
            padding: rem(25px) rem(30px);

            @include media-breakpoint-up(md) {
                padding: rem(20px) rem(20px);
            }

            @include media-breakpoint-up(lg) {
                padding: rem(25px) rem(30px);
            }
        }

        #{$card}-text {
            color: $grey-800;

            a {
                color: $grey-800 !important;
                display: block;
            }

            .goto-contactdetails {
                color: theme-color(primary) !important;

                .icon {
                    width: 28px;
                    height: 28px;
                    color: theme-color(primary);
                    fill: theme-color(primary);
                }
            }

            .basic-info__logo {
                max-width: 100%;
            }
        }
    }
}
