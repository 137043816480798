.registration-form {
    position: relative;
    margin: 0;
    padding: rem(35px) rem(38px);
    width: 100%;
    background-color: theme-color(primary);

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__close {
        position: absolute;
        top: 2px;
        right: 0;
        width: 40px;
        height: 40px;
        border: none;
        background: none;
        cursor: pointer;

        .icon {
            fill: $white;
        }
    }

    &__results-mocked {
        display: none;
    }

    h3,
    &__title {
        font-size: rem(32px);
        @include line-height(34px, 32px);

        color: $white !important;
    }

    p,
    &__text {
        color: $white !important;

        a {
            color: $white !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__text {
        margin: rem(22px) 0 0 0;
    }

    .collapse {
        &__body {
            position: relative;
            padding: rem(14px) 0 0 0;
        }
    }

    hr {
        margin-top: 0;
        margin-bottom: rem(26px);
        border-top: 1px solid theme-color(primary-lighter);
    }

    .form-group-label,
    .btn {
        color: $white !important;
    }

    .form-text {
        &.help-feedback,
        &.valid-feedback,
        &.invalid-feedback {
            padding: rem(8px) 0 0 0 !important;
        }
    }

    .form-control {
        &.is-invalid {
            border-color: theme-color(secondary) !important;
        }
    }

    .was-validated {
        :invalid,
        .is-invalid {
            border-color: theme-color(secondary) !important;
        }
    }

    .invalid-feedback {
        &--msg {
            color: theme-color(secondary) !important;
        }
    }

    .icon {
        &.invalid-feedback {
            fill: theme-color(secondary) !important;
        }
    }

    .btn {
        &-ghost {
            border-color: $white !important;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid $white !important;
                background: $white !important;
                color: theme-color(primary) !important;
                text-decoration: none !important;
            }
        }

        &-link {
            color: $white !important;
            border: none !important;
            margin-bottom: 0 !important;
            background: transparent !important;

            .icon {
                fill: $white !important;
            }

            &:hover {
                color: $white !important;

                .icon {
                    fill: $white !important;
                }
            }
        }

        &[type='submit'] {
            margin-top: rem(14px);
            margin-bottom: 0;
        }
    }

    .loader {
        &__overlay {
            background-color: theme-color(primary);
        }

        .spinner {
            top: 50% !important;

            &__circle {
                background-color: $white;
            }
        }
    }

    &__buttons {
        margin-bottom: rem(16px);
        max-width: 100% !important;
    }

    &__overlay {
        background-color: rgba($black, 0.75);
    }
}
