/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable scss/dollar-variable-pattern */
.sidepanels {
    display: none;

    // position: absolute;
    // top: $paneltop-md;
    // right: 0;
    // height:	$panelheight-md;
    // transform: translateX($panelwidth-main-md + 2rem);
    // //will-change: transform;
    // transition: transform 300ms ease;
    // z-index: 100;

    // //background: $brand-primary;

    // @include media-breakpoint-up(lg) {
    //     height:	$panelheight-lg;
    // }

    // @include media-breakpoint-up(xl) {
    //     top: $paneltop-xl;
    //     //height:	$panelheight-xl;
    //     transform: translateX($panelwidth-main-xl + 2rem);
    // }

    &.is-visible {
        display: block;

        //   height: calc(100vh);
        height: calc(100dvh - 130px);
        top: 60px;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 101;
        background-color: $white;
        box-shadow: 0 3px 50px rgba($black, 0.22);

        @include media-breakpoint-down(sm) {
            height: calc(100dvh - 124px);
        }
    }

    .sidepanel-header {
        height: rem(64px);
        background-color: $brand-primary;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            color: inherit;
            height: 100%;
            padding-left: rem(30px);
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-weight: bold;
        }

        .icon-close {
            margin-left: auto;
            height: 100%;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            padding: 0 rem(20px);
            position: relative;

            p {
                color: $white;
                padding-right: rem(14px);
                margin-bottom: 0;
            }

            // &:after{
            //   content: "";
            //   position: absolute;
            //   opacity: 0.2;
            //   background-color: $grey-100;
            //   left: rem(-1px);
            //   top: 15%;
            //   width: (1px);
            //   height: 70%;
            // }
        }
    }

    // &.totalscroll {
    //     // overflow-y: auto;
    //     // overflow-x: hidden;
    //     .sidepanel-main {
    //         overflow-y: scroll;
    //     }
    // }

    .sideloader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba($brand-primary-dark, 0.8);
        z-index: 30;
        display: none;

        &.is-active {
            display: block;
        }

        .sideloaderimage {
            @extend .loader;

            display: block;
        }
    }

    .resetoptions {
        color: $white;
        overflow: hidden;
        display: none;

        span {
            display: block;
            width: 60%;
            float: left;
            padding: 1rem 1.75rem;
            font-size: 1.125rem;
        }

        .resetfilters {
            display: block;
            width: 40%;
            float: right;
            text-align: right;
            font-size: 0.75rem;
            padding: 1rem 1.75rem 1rem 0;
            line-height: 1.5rem;
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }

        &.is-active {
            display: block;
        }
    }

    &.is-active {
        // transform: translateX(0);
    }

    .sidepanel-main {
        width: 100%;

        // height:	100%;
        background: $white;
        color: $brand-primary;
        line-height: 1.5;

        // border-radius: $r 0 0 $r;
        // transition: border-radius 300ms ease;
        // will-change: border-radius;

        @include media-breakpoint-up(lg) {
            // height:	$panelheight-lg;
        }

        @include media-breakpoint-up(xl) {
            // width: $panelwidth-main-xl;
            // height:	$panelheight-xl;
        }

        &.has-open-subpanel {
            border-radius: 0;

            // height: 100%;
        }

        a {
            color: $brand-primary;
        }

        > li {
            + li {
                border-top: 1px solid rgba($brand-primary-light, 0.6);
            }

            > .accordion-toggle-title {
                background: $grey-100;
                color: $brand-primary;
                display: block;
                padding: 0.75rem 2.75rem 0.75rem rem(30px);
                font-size: 1.125rem;
                cursor: pointer;
                line-height: 1.25;
                @extend .icon-font !optional;
                @extend .icon-down !optional;

                &::before {
                    right: 1.75rem;
                    height: 100%;
                    line-height: 2.5;
                }
            }

            > .sidepanel-main-item-title {
                display: block;
                padding: 1rem 1.75rem;
                font-size: 1.125rem;
                cursor: pointer;
            }

            &.is-active {
                .sidepanel-sub {
                    height: auto;
                }

                .accordion-toggle-title {
                    font-weight: 500;
                }
            }

            .sidepanel-sub {
                background: $grey-100;
                padding: 0;
                height: 0;
                overflow: hidden;
                transition:
                    height 300ms ease,
                    padding 300ms ease;

                // will-change: height;
                > li {
                    border-bottom: solid rem(1px) rgba(0, 82, 155, 0.2);

                    &.is-active {
                        a.sidepanel-sub-item-title {
                            @extend strong !optional;
                            @extend .icon-down-rotate;
                        }
                    }

                    > a {
                        display: block;
                        padding: 0.5rem 1.75rem;
                        font-size: 1rem;
                        cursor: pointer;
                        color: $grey-900;
                        background-color: $white;

                        &:hover {
                            color: $brand-primary;
                        }

                        &.sidepanel-sub-item-title {
                            @extend .icon-font !optional;
                            @extend .icon-down !optional;

                            &::before {
                                right: 1.75rem;
                                height: 100%;
                                line-height: 2.5;
                            }
                        }
                    }

                    &.is-active {
                        .sub-item-option-panel {
                            display: block;
                        }
                    }

                    .sub-item-option-panel {
                        background-color: $grey-100;

                        // width: $panelwidth-subitems-md;
                        // height: 100%;
                        // position: absolute;
                        // top: 0;
                        right: $panelwidth-main-md;
                        padding: 1rem 1.25rem 1.25rem;

                        // border-radius: $r 0 0 $r;
                        // transform: translateX($panelwidth-subitems-md + $panelwidth-main-md);
                        // will-change: transform;
                        transition: transform 300ms ease;
                        z-index: -1;
                        display: none;

                        @include media-breakpoint-up(xl) {
                            // width: $panelwidth-subitems-xl;
                            // right: $panelwidth-main-xl;
                            // transform: translateX($panelwidth-subitems-xl + $panelwidth-main-xl);
                        }

                        .sub-item-option-panel-disabler {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;

                            // background: rgba($brand-primary-dark,0.8);
                            z-index: 30;
                            display: none;

                            &.is-active {
                                display: block;
                            }
                        }

                        .icon-close {
                            float: right;

                            // margin-top: 0.25rem;
                            &::before {
                                font-size: 1rem;
                            }
                        }

                        .sub-item-option-panel-title {
                            font-size: 2rem;
                            margin-bottom: 1rem;
                            color: $white;
                            display: none;
                        }

                        .sub-item-options {
                            // height: 90%;
                            // overflow-y: auto;

                            .option-selector {
                                @extend .otc-radio;

                                float: left;
                                margin-right: 0.25rem;
                                margin-top: 0.25rem;
                            }

                            > li {
                                // border-bottom: 1px solid $grey-100;
                                padding: 0 0.625rem;
                                transition:
                                    background 300ms ease,
                                    padding 300ms ease;

                                // will-change: background, padding;

                                &.is-incompatible {
                                    .option-header {
                                        opacity: 0.2;
                                    }

                                    .option-description {
                                        opacity: 0.2;
                                    }
                                }

                                .option-header {
                                    overflow: hidden;
                                    padding: 0.5rem 0;
                                    display: flex;
                                    align-items: center;

                                    .option-item {
                                        display: flex;
                                    }

                                    .option-more-info-btn {
                                        display: inline-block;
                                        position: relative;
                                        top: 0;
                                        font-size: 1rem;
                                        min-width: 20px;
                                        min-height: 20px;
                                        background: $white;
                                        color: $grey-800;
                                        text-align: center;
                                        line-height: 1.2;
                                        border-radius: 50%;
                                        cursor: pointer;
                                    }

                                    // .option-selector {
                                    // 	@extend .otc-radio;
                                    // 	float: left;
                                    // 	margin-right: 1.25rem;
                                    // 	margin-top: 0.25rem;

                                    // }
                                    .option-title {
                                        float: left;

                                        // width: 90%;
                                        display: block;
                                        cursor: pointer;
                                        color: $grey-600;

                                        .option-title-text {
                                            position: relative;
                                            padding-right: 1rem;
                                        }
                                    }
                                }

                                .option-description {
                                    overflow: hidden;
                                    margin: 0 0 0 2.25rem;
                                    color: $grey-400;
                                    display: none;

                                    .option-more-info {
                                        display: none; // Out of scope
                                    }
                                }

                                &:hover {
                                    // background: $grey-600;
                                    .option-header {
                                        .option-selector {
                                            @extend .otc-radio-hover;
                                        }
                                    }
                                }

                                &.is-selected {
                                    .option-header {
                                        .option-selector {
                                            @extend .otc-radio-selected;
                                        }
                                    }
                                }

                                &.is-active {
                                    padding-bottom: 1rem;

                                    .option-description {
                                        display: block;
                                        background-color: $white;
                                        padding: 15px;
                                        border-radius: 25px;
                                        color: $grey-600;
                                    }
                                }
                            }
                        }
                    }

                    &.is-active {
                        > a {
                            color: $brand-primary;
                        }

                        .sub-item-option-panel {
                            transform: translateX(0);
                        }
                    }
                }

                &.sidepanel-sub-colorpicker {
                    > li {
                        .sub-item-option-panel {
                            .sub-item-options {
                                border: none;
                                display: flex;
                                flex-wrap: wrap;

                                > li {
                                    border: none;
                                    padding: 0.5rem 0.625rem;

                                    @include media-breakpoint-up(sm) {
                                        width: calc(100% / 3);
                                    }

                                    @include media-breakpoint-up(md) {
                                        width: calc(100% / 4);
                                    }

                                    .color-option {
                                        color: $grey-600;

                                        $colorOptionHeight: 2rem;

                                        overflow: hidden;
                                        display: block;

                                        .color-selector {
                                            float: left;

                                            .option-selector {
                                                margin-top: 0.5rem;
                                            }
                                        }

                                        .color-color {
                                            width: rem(44px);
                                            height: $colorOptionHeight;
                                            border: 1px solid #666;
                                            float: left;
                                        }

                                        .color-name {
                                            width: 82%;
                                            float: left;
                                            padding-left: 1rem;
                                            line-height: $colorOptionHeight;
                                            font-size: 0.875rem;
                                            text-transform: uppercase;
                                        }

                                        &:hover {
                                            .option-selector {
                                                @extend .otc-radio-hover;
                                            }
                                        }
                                    }

                                    &.is-selected {
                                        .color-option {
                                            .color-selector {
                                                .option-selector {
                                                    @extend .otc-radio-selected;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .filterset {
                padding: 1rem 1.75rem;
                background: $brand-primary-dark;

                a {
                    &.filterset-item {
                        display: block;
                        margin-bottom: 0.5rem;
                        position: relative;
                        padding-left: 1.75rem;

                        .filter-selector {
                            @extend .otc-radio;

                            position: absolute;
                            top: 0.25em;
                            left: 0;
                        }

                        &.is-active {
                            .filter-selector {
                                @extend .otc-radio-selected;
                            }
                        }

                        &.is-incompatible {
                            pointer-events: none;
                            opacity: 0.2;
                        }

                        &:hover {
                            .filter-selector {
                                @extend .otc-radio-hover;
                            }
                        }
                    }

                    &.filterset-item-template {
                        display: none;
                    }
                }
            }

            &.is-active {
                > .accordion-toggle-title {
                    @extend strong !optional;
                    @extend .icon-down-rotate;
                }
            }
        }
    }
}
