.otc-results {
    padding: 0 rem(15px);

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }

    &__result {
        border: solid rem(1px) transparent;
        margin-bottom: rem(20px);
        padding: rem(20px) rem(20px) rem(30px) rem(20px);
        background-color: $grey-100;
        border-radius: rem(10px);
        transition: border-color 300ms ease, opacity 300ms ease;
        position: relative;
        cursor: pointer;

        // @include media-breakpoint-down(md) {
        //   // flex: 1 0 rem(500px);
        // }

        // @include media-breakpoint-up(md) {
        //   width:calc(50% - #{rem(10px)});
        // }

        &:nth-child(odd) {
            @include media-breakpoint-up(md) {
                margin-right: rem(20px);
            }
        }

        &.is-active {
            border-color: $grey-700;
            border-radius: rem(10px);

            .btn-round {
                display: block;
            }
        }

        &.is-not-active {
            opacity: 0.5;
        }

        .btn-round {
            position: absolute;
            display: none;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            margin: 0 0 rem(-20px) 0;
        }
    }

    &__result-title {
        margin-bottom: 1rem;
        color: $brand-primary;
        font-size: rem(16px);
        font-weight: $font-weight-bold;
        line-height: rem(19px);
    }

    &__visual-wrapper {
        margin-bottom: 1rem;
    }

    &__result-image {
        width: 100%;
    }

    &__result-specs {
        display: flex;
        color: $grey-600;
        font-size: rem(14px);
        line-height: rem(16px);

        li {
            margin-right: rem(12px);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__result-spec {
        padding-left: rem(6px);
        color: $black;
    }
}
