.whitespace {
    position: relative;
    margin: 0;
    padding: 0;
    height: rem(20px);

    &--small {
        height: rem(20px);
    }

    &--medium {
        height: rem(30px);
    }

    &--large {
        height: rem(40px);
    }

    @include media-breakpoint-up(md) {
        height: rem(25px);

        &--small {
            height: rem(25px);
        }

        &--medium {
            height: rem(50px);
        }

        &--large {
            height: rem(80px);
        }
    }

    &.desktop-only {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}
