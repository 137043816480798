.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: $white;
    color: $black;
    padding: 1.25rem;
    transform: translateY(-250vh);
    transition: transform 300ms ease;
    z-index: 10010;

    @include media-breakpoint-up(lg) {
        width: 100%;
        height: unset;
        max-width: rem(470px);
        max-height: 90%;
        min-height: rem(400px);
        padding: 2rem;
        top: 50%;
        left: 50%;
    }

    &.is-active {
        transform: translateY(0);

        @include media-breakpoint-up(lg) {
            transform: translate(-50%, -50%);
        }
    }

    .modal-content-wrapper {
        overflow-y: auto;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    .modal-close {
        position: absolute;
        right: 1.25rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        height: 1.5rem;
        color: $black;
        text-align: right;
        overflow: hidden;
        z-index: 2;
        @extend .icon-no-label;

        @include media-breakpoint-up(lg) {
            right: 2rem;
        }

        & + .modal-text {
            padding-top: 4rem;
        }
    }

    .modal-code {
        font-size: 1.5rem;
        color: $grey-800;
    }

    .modal-btn {
        @extend .btn;
        @extend .btn-dark;
    }

    .separator {
        width: calc(100% + 2.5rem);
        border-top: 1px solid $grey-100;
        margin: auto -1.25rem 0;
        padding-top: 0.75rem;

        @include media-breakpoint-up(lg) {
            width: calc(100% + 4rem);
            margin: 0 -2rem;
        }
    }

    .modal-bottom-btn-wrapper {
        display: flex;
        width: 100%;
        align-self: flex-end;
        text-align: center;
        gap: 12px;

        .modal-btn {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .modal-column-wrapper {
        overflow-y: auto;
        margin-bottom: 2rem;
        color: $grey-600;
        height: 30vh;

        .modal-column {
            width: 50%;
            float: left;

            ul {
                @extend .with-bullets;

                li {
                    margin-bottom: 0.5rem;
                    padding-right: 2rem;
                }

                &.modal-validation-selected,
                &.modal-validation-deselected {
                    li {
                        &::before {
                            background: none;
                            width: auto;
                            height: auto;
                            border-radius: 0;
                            color: $brand-primary;
                            top: -0.1rem;
                        }
                    }
                }

                &.modal-validation-selected {
                    li {
                        &::before {
                            content: '+';
                        }
                    }
                }

                &.modal-validation-deselected {
                    li {
                        &::before {
                            content: '-';
                        }
                    }
                }
            }
        }
    }

    .modal-title {
        font-weight: 300;
        padding: 2rem 0;
    }

    .modal-text {
        span {
            background-color: $grey-100;
            color: $grey-800;
            padding: 0.125rem 0.5rem;
        }
    }

    @keyframes tablet-rotate {
        0% {
            transform: rotate(0);
            opacity: 0;
        }

        10% {
            transform: rotate(0);
            opacity: 1;
        }

        65% {
            transform: rotate(-90deg);
            opacity: 1;
        }

        75% {
            transform: rotate(-90deg);
            opacity: 0;
        }

        100% {
            transform: rotate(0);
            opacity: 0;
        }
    }

    .icon-font-tablet {
        font-size: 3rem;
        margin: 2rem auto 0;
        text-align: center;
        width: 1em;
        transform: rotate(0);
        opacity: 1;
        animation: tablet-rotate 3000ms infinite;
    }

    .icon-orientation {
        display: none;

        &.is-active {
            display: block;
        }
    }

    .icon--chevron-down {
        display: block;
        position: absolute;
        right: 1.25rem;
        bottom: 5.5rem; // place icon over the separator at the end of the content
        background-color: $brand-primary;
        border-radius: 50%;
        fill: white;

        &.hidden {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            right: 2rem;
            bottom: 6.5rem;
        }
    }

    .modal-error-text {
        color: $red;
        line-height: 2.375;
        margin-bottom: 1rem;
        display: none;

        &.is-active {
            display: block;
        }
    }

    .modal-form {
        fieldset {
            padding: 0;
            border: 0;
            overflow: hidden;

            label {
                width: 9rem;
                float: left;
                line-height: 2.65;
                color: $grey-600;
                min-height: 42px;
            }

            input {
                padding-right: rem(15px) !important;
                float: left;
            }
        }
    }
}

.modal-save,
.modal-load,
.modal-send,
.modal-home,
.modal-validation,
.modal-disclaimer,
.modal-summary {
    @extend .modal;
}

.modal-disclaimer {
    .icon--chevron-down {
        bottom: 1rem; // place icon at the end of the content

        @include media-breakpoint-up(lg) {
            bottom: 1.75rem;
        }
    }
}

.modal-save {
    @include media-breakpoint-up(lg) {
        max-width: 50rem;
    }

    &__share.label {
        border-left: $border-default;
        margin-right: -1.25rem;

        @include media-breakpoint-up(lg) {
            margin-right: -2rem;
        }
    }

    &__save.label {
        border-right: $border-default;
        margin-left: -1.25rem;

        @include media-breakpoint-up(lg) {
            margin-left: -2rem;
        }
    }

    .labels {
        display: flex;
        width: calc(100% + 2.5rem);
        padding: 4rem 0 2rem;

        @include media-breakpoint-up(lg) {
            width: calc(100% + 4rem);
        }
    }

    .label {
        display: flex;
        flex-direction: row;
        width: 50%;
        color: $grey-400;
        font-size: 1rem;
        border-bottom: $border-default;
        border-top: $border-default;

        &.is-active {
            border: none;
            border-top: $border-default;
            color: $brand-primary;
            font-weight: $font-weight-bold;
        }
    }

    label {
        width: 100%;
        height: 100%;
        padding: 1rem 0;
        text-align: center;
        cursor: pointer;
    }

    .tabs {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }

    .tab>input[type="radio"] {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .content {
        display: none;
        overflow-y: auto;
    }

    [name="tabs"]:checked ~ .content {
        display: block;
        transition: opacity 400ms ease-out;
    }

    .modal-form {
        width: 100%;

        @include media-breakpoint-up(md) {
            gap: 1.25rem;
        }

        &.sender-details {
            width: 100%;

            &.hidden {
                display: none;
            }
        }

        .input-code {
            width: 100%;

            &.invalid {
                border-color: $red;
            }
        }

        &__recepient-name-email,
        &.sender-details {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            gap: 1rem;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
        }

        &__recipient-name,
        &__recipient-email,
        &__sender-name,
        &__sender-email {
            width: 100%;

            &.hidden {
                display: none;
            }
        }

        .recipient-label,
        .sender-label {
            color: $brand-truck-configurator-grey;
            font-size: 1.25rem;
            font-weight: $font-weight-medium;
            margin-bottom: 1rem;
        }

        .error {
            display: block;
            color: $red;

            &.hidden {
                display: none;
            }
        }
    }

    .modal-btn {
        position: relative;
        width: 100%;
        line-height: 1.25rem;
        padding: 0.75rem;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            width: auto;
        }

        &.clipboard-copy {
            width: 100%;
            line-height: 1.25rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0.75rem;
            margin-top: auto;

            &:hover,
            &.success {
                .icon {
                    fill: $white;
                }
            }

            @include media-breakpoint-up(md) {
                width: auto;
                line-height: 1.5rem;
            }

            .icon {
                width: 1.25rem;
                height: 1.25rem;
                fill: $brand-truck-configurator-blue;
            }
        }
    }

    .modal-code {
        font-size: 0.75rem;
        line-height: 1.5rem;
        word-wrap: break-word;
        border-radius: 0.25rem;
        padding: 1rem 0;

        @include media-breakpoint-up(sm) {
            font-size: 1.125rem;
        }
    }
}
