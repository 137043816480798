.custom-file {
    display: flex;
    height: 42px;

    label {
        &[class*='col-'] {
            margin-bottom: 0;
        }
    }

    .size-exceeded-msg {
        display: flex !important;
    }
}

.custom-file-input,
.custom-file-label {
    padding: rem(10px) rem(30px) rem(5px) rem(15px) !important;
    border-color: $grey-400;
    background-color: $white;
    color: $grey-900;
    height: 42px;
    box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1) !important;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;

    &:focus,
    &:active,
    &:hover {
        color: $grey-900;
        box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1) !important;
    }

    &::after {
        padding-top: 10px;
        height: 100% !important;
        background-color: $grey-600;
        color: $white;
    }
}
