/* CUSTOM OVERRIDES FOR DAF OTC */

@import 'nouislider/distribute/nouislider';

.filterset-slideritem {
    padding: 1rem 1rem 2rem 0.75rem;

    .slidervalues {
        margin-top: 1rem;
        overflow: hidden;
        width: 132%;
        margin-left: -16%;

        .slider-min,
        .slider-max {
            width: 50%;
        }

        .slider-min {
            float: left;
        }

        .slider-max {
            right: 0;
            float: right;
            text-align: right;
        }
    }
}

.noUi-horizontal {
    height: 2px;

    .noUi-handle {
        width: 1rem;
        height: 1rem;
        background: $white;
        border-radius: 50%;
        border: none;
        box-shadow: none;
        left: 100% !important;

        &::before,
        &::after {
            display: none;
        }

        &.noUi-active {
            box-shadow: none;
            background: $white;
        }

        .noUi-tooltip {
            bottom: -190%;
            border: none;
            background: $brand-primary-dark; // Beacause of overlap
            color: $white;
            border-radius: $r;
            padding: 0 0.25rem;
        }

        &.noUi-handle-lower .noUi-tooltip {
            position: fixed;
            left: 0 !important;
        }

        &.noUi-handle-upper .noUi-tooltip {
            position: fixed;
            left: 100% !important;
        }
    }
}

.noUi-connect {
    background: $white;
    box-shadow: none;
}

.noUi-target {
    background: $brand-primary-lighter;
    border: none;
    box-shadow: none;
}
