.faq {
    &__question {
        position: relative;
        margin: 0;
        padding: rem(12px) rem(50px) rem(12px) rem(15px);
        font-weight: $font-weight-bold;
        display: block;
        border-top: 1px solid $grey-200;
        transition: background-color 200ms ease-in-out;

        &:hover,
        &:active,
        &.is-active {
            background-color: $grey-50;
        }

        .icon {
            position: absolute;
            top: 13px;
            right: 15px;
            transition: transform 200ms ease-in-out;
        }

        &.is-active {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &__answer {
        margin: 0;

        .body {
            margin: rem(12px) rem(50px) rem(25px) rem(15px);
        }
    }

    .list {
        max-width: 960px;
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .faq {
        &__question {
            color: $white !important;

            &:hover,
            &:active,
            &.is-active {
                background-color: $grey-700;
            }

            .icon {
                fill: $white;
            }
        }
    }
}
