// custom radio
.custom-radio {
    margin: 0 0 rem(10px) 0;

    &.custom-control {
        padding-left: rem(30px) !important;
    }

    .custom-control {
        &-label {
            margin-top: 2px;
            cursor: pointer;

            &::before {
                top: 4px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: $border-default;
                background-color: $white !important;
                box-shadow: 0 0 4px transparent, inset 0 2px 2px rgba($black, 0.1) !important;
            }

            &::after {
                left: 5px;
                top: 9px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-image: none !important;
            }
        }

        &-input {
            &:checked ~ .custom-control-label {
                &::before {
                    background-color: $white !important;
                }

                &::after {
                    background-color: theme-color(primary) !important;
                    background-image: none !important;
                }
            }

            &:indeterminate ~ .custom-control-label {
                &::before {
                    background-color: $white !important;
                }

                &::after {
                    background-color: transparent !important;
                    background-image: none;
                }
            }

            &:active ~ .custom-control-label {
                &::before {
                    background-color: $white;
                    box-shadow: none;
                }
            }

            &:disabled {
                ~ .custom-control-label {
                    cursor: default;
                    pointer-events: none;

                    &::before {
                        background-color: rgba($white, 0.5);
                    }
                }

                &:checked ~ .custom-control-label {
                    &::before {
                        background-color: rgba($white, 0.5);
                    }

                    &::after {
                        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyMCAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMxQjQ4NzE7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNy44LDcuM2MwLDAuMy0wLjEsMC41LTAuMywwLjdsLTcuMyw3LjNsLTEuNCwxLjRDOC43LDE2LjksOC40LDE3LDguMiwxN2MtMC4zLDAtMC41LTAuMS0wLjctMC4zbC0xLjQtMS40DQoJbC0zLjctMy43Yy0wLjItMC4yLTAuMy0wLjQtMC4zLTAuN3MwLjEtMC41LDAuMy0wLjdsMS40LTEuNEM0LDguOCw0LjIsOC43LDQuNSw4LjdTNSw4LjgsNS4yLDguOWwzLDNsNi42LTYuNkMxNSw1LjEsMTUuMiw1LDE1LjUsNQ0KCWMwLjMsMCwwLjUsMC4xLDAuNywwLjNsMS40LDEuNEMxNy43LDYuOCwxNy44LDcuMSwxNy44LDcuM3oiLz4NCjwvc3ZnPg0K');
                        background-size: cover;
                        opacity: 0.5;
                    }
                }

                &:indeterminate ~ .custom-control-label {
                    &::before {
                        background-color: rgba($white, 0.5);
                    }

                    &::after {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
