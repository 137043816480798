.archive {
    .grid-sizer {
        margin-bottom: 0 !important;
    }

    .itemlist {
        position: relative;

        .alert {
            margin: rem(20px) auto;
            max-height: 50px;
        }

        [class*='col-'] {
            margin-bottom: $grid-gutter-width;
        }
    }

    .loader {
        .spinner {
            top: 230px;
        }
    }
}
