body {
    @include line-height(24px, 16px);
    @include font-smoothing;

    font-family: $font-family-base;
    font-size: rem(16px);
    letter-spacing: -0.35px;
}

// add margin bottom on cols in mobile views
[class*='col-'] {
    margin-bottom: rem(25px);

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}
