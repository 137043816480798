$white: #fff;
$black: #000;
$green-bright: #8ac543;
$red: #f00;

// New breakpoints for OTC (desktop and landscape tablets)
$lg: 64.0625em; // 1025px, desktops
// $lg: 85.375em; // 1366px, desktops
$xl: 85.375em; // 1366px, desktops

$r: 0.5rem; // Border-radius

$scrollbar-width: 1.0625rem; // 17px;

$panelwidth-main-md: 16rem;
$panelwidth-subitems-md: 32rem;
$panelheight-md: 480px;
$paneltop-md: 6rem;
$mainpanelwidth-md: 71vw; // 77?

$panelheight-lg: 70vh;
$panelwidth-main-xl: 16rem;
$panelwidth-subitems-xl: 32rem;
$panelheight-xl: 78vh;
$paneltop-xl: 6rem;
$mainpanelwidth-xl: 84vw;

$header-height: 3.75rem; // 60px;
$footer-height: 4.375rem; // 70px;
$footer-height-mobile: 4rem; // 64px;
