.block-links-wrapper {
    opacity: 0;

    &.is-active {
        opacity: 1;
    }

    &.block-links-wrapper-template {
        display: none;
    }

    .block-links {
        opacity: 0;
        transition: opacity 300ms ease;

        @include media-breakpoint-up(xl) {
            justify-content: center;
        }

        &.is-active {
            opacity: 1;

            .block-link {
                opacity: 1;

                &.is-incompatible {
                    // sass-lint:disable nesting-depth
                    a {
                        pointer-events: none;

                        .btn {
                            // @extend .btn;
                            opacity: 0.6;
                        }
                    }

                    .block-link-visual,
                    .block-link-subvisual {
                        opacity: 0.3;
                    }

                    // sass-lint:enable nesting-depth
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-top: 1rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 2rem;
        }

        &.slider {
            left: auto;
            float: none;

            .block-link {
                right: auto;
            }
        }

        .block-link-line-break {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .block-link-col {
            margin-bottom: rem(40px);
        }

        .block-link {
            border: solid rem(1px) transparent;
            border-radius: rem(10px);
            width: 100%;
            height: 100%;
            margin-top: rem(20px);
            margin-bottom: rem(20px);
            background-color: $grey-100;
            transition: border-color 300ms ease, opacity 300ms ease;
            position: relative;
            display: flex;
            flex-direction: column;

            &:hover {
                box-shadow: -6.632px 4.474px 78px 0 rgba($black, 0.5);
            }

            &.is-active {
                border-color: $grey-700;
                border-radius: rem(10px);

                .btn-round {
                    display: block;
                }
            }

            &.is-not-active {
                opacity: 0.5;
            }

            .btn-round {
                position: absolute;
                display: none;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                margin: 0 0 rem(-20px) 0;
            }

            .block-link-title {
                margin-top: auto;
                margin-bottom: rem(28px);
                color: $black;
                padding-top: 1rem;
                font-size: rem(18px);
                font-weight: $font-weight-bold;
                line-height: rem(20px);
                text-align: center;
            }

            .block-link-text {
                display: none;
                padding: 0 1.875rem;
                color: $white;
                overflow: hidden;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            .block-links__visual-wrapper {
                margin-top: rem(-20px);
            }

            .block-link-visual,
            .block-link-visual2 {
                width: 100%;
                height: auto;

                img {
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }
            }

            .block-link-subvisual {
                display: block;
                height: 4vh;
                margin: 1rem auto 0;
            }

            &:hover,
            &.is-active {
                &::before {
                    background: rgba($white, 0.15);
                }
            }
        }
    }
}

.firststep,
.step-2 {
    .block-link {
        background: rgba(216, 216, 216, 0.3);

        // @include media-breakpoint-up(xl) {
        //   max-width: 380px;
        // }

        .block-link-visual {
            width: 100%;
        }

        .btn {
            margin-top: 0 !important;
        }
    }
}

.step-2 {
    // padding: 0px !important;

    // .col-6 {
    //   @include media-breakpoint-down(sm) {
    //     display: contents;
    //   }
    //   &:after {
    //     @include media-breakpoint-down(sm) {
    //       content: '';
    //       width: 100%;
    //     }
    //   }
    //   .block-link {
    //     @include media-breakpoint-down(sm) {
    //       margin-left: auto;
    //       margin-right: auto;
    //       width: 50%;
    //     }
    //   }
    // }

    // .block-links{
    //   margin: 0px !important;
    //     .block-link {
    //       max-width: 90%;
    //       @include media-breakpoint-up(sm) {
    //         max-width: calc(24% - #{rem(10px)});
    //         height: rem(180px);
    //         margin: 0px rem(13px) 0px rem(13px);
    //       }

    //       .block-link-title{
    //         padding-top: rem(40px);
    //       }
    //     }
    //   }
}

.premodelselectionstep {
    .slick-slide {
        margin: 0 rem(8px);

        .block-link {
            &-visual {
                max-height: 16vh !important;

                @include media-breakpoint-up(lg) {
                    max-height: 18vh !important;
                }

                @include media-breakpoint-up(xl) {
                    max-height: 20vh !important;
                }
            }

            &-title {
                font-size: rem(22px);
                height: 80px;

                @include media-breakpoint-up(lg) {
                    font-size: rem(24px);
                    height: 92px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: rem(26px);
                    height: 126px;
                }
            }

            .btn {
                margin-top: 0;
            }
        }
    }
}
