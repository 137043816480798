.recruitercontact-card {
    position: relative;

    &--background-primary {
        background-color: $grey-100;

        h2 {
            color: heading-color(dark) !important;
        }

        p,
        a,
        .icon {
            color: text-color(dark) !important;
            fill: text-color(dark) !important;
        }
    }

    &__container {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding: 30px;

        h2 {
            margin-top: rem(15px);
            margin-bottom: rem(20px);
            font-weight: $font-weight-light;
            color: $white;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
                margin-left: rem(15px);
                margin-bottom: rem(16px);
            }
        }

        [class*='col-'] {
            margin-bottom: 0;
        }

        img {
            margin: 0 0 rem(16px) 0 !important;

            @include media-breakpoint-up(sm) {
                margin: 0 !important;
            }

            .ratio {
                @include ratio-box(100%, 16, 9);
            }
        }
    }

    &__list {
        margin-bottom: 0;

        li {
            position: relative;
            padding: 0 0 rem(8px) rem(35px);

            @include media-breakpoint-up(sm) {
                margin-left: rem(15px);
            }

            @include media-breakpoint-up(md) {
                margin-left: rem(15px);
                padding: rem(12px) 0 rem(8px) rem(40px);
            }

            p {
                a {
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                        color: $white;
                    }
                }
            }
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            margin-right: rem(15px);
            fill: $white;
            color: $white;
            width: 20px;
            height: 20px;

            @include media-breakpoint-up(md) {
                width: 24px;
                height: 24px;
                @include align-vertical(absolute);
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    fill: $grey-400;
                    color: $grey-400;
                }
            }

            &.flag-icon {
                width: 24px;
                height: 18px;
            }

            &--link,
            &--location {
                width: 20px;
                height: 20px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: rem(14px);

            @include media-breakpoint-up(md) {
                font-size: rem(16px);
            }

            a {
                color: $grey-600;

                &:hover {
                    text-decoration: underline !important;
                    color: theme-color(primary);
                }
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $grey-300 !important;
                }
            }
        }
    }
}
