.pagination {
    font-size: rem(14px);

    .page-item {
        .page-link {
            padding: rem(8px) rem(10px);
            color: theme-color(primary);

            @include media-breakpoint-up(lg) {
                padding: rem(8px) rem(12px);
            }

            .icon {
                width: 16px;
                height: 16px;
                vertical-align: bottom;
                fill: theme-color(primary);
            }

            &:hover {
                color: theme-color(primary);
            }
        }

        &.active {
            .page-link {
                pointer-events: none;
                cursor: auto;
                color: $white;

                .icon {
                    fill: $white;
                }
            }
        }

        &.disabled {
            .page-link {
                color: $grey-400;

                .icon {
                    fill: $grey-400;
                }
            }
        }
    }
}
