.countrycontact-card {
    position: relative;

    &__container {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;

        h2 {
            margin-bottom: rem(20px);
            font-weight: $font-weight-light;

            @include media-breakpoint-up(md) {
                margin-bottom: rem(16px);
            }
        }

        [class*='col-'] {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xs) {
            max-width: 536px;
        }

        @include media-breakpoint-up(sm) {
            max-width: 728px;
        }

        @include media-breakpoint-up(md) {
            max-width: 952px;
        }

        @include media-breakpoint-up(ld) {
            max-width: 990px;
        }
    }

    &__list {
        margin-bottom: 0;

        li {
            position: relative;
            padding: 0 0 rem(8px) rem(35px);

            @include media-breakpoint-up(md) {
                padding: rem(12px) 0 rem(8px) rem(40px);
            }
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            margin-right: rem(15px);
            fill: $grey-600;
            color: $grey-600;
            width: 20px;
            height: 20px;

            @include media-breakpoint-up(md) {
                @include align-vertical(absolute);

                width: 24px;
                height: 24px;
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    fill: $grey-400;
                    color: $grey-400;
                }
            }

            &.flag-icon {
                width: 24px;
                height: 18px;
            }

            &--link,
            &--location {
                width: 20px;
                height: 20px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: rem(14px);

            @include media-breakpoint-up(md) {
                font-size: rem(16px);
            }

            a {
                color: $grey-600;

                &:hover {
                    text-decoration: underline !important;
                    color: theme-color(primary);
                }
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $grey-300 !important;
                }
            }
        }
    }
}
