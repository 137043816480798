/* stylelint-disable scss/at-import-partial-extension */
@import 'node_modules/@chancedigital/animate.scss/animate.scss';

.app-vocationals {
    [class*='col-'] {
        margin-bottom: 0;
    }

    .focus,
    :focus {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.5) !important;
    }
}

.voc-slider {
    &__title {
        position: absolute;
        left: 0;
        bottom: 15%;
        margin: 0 20px;
        padding-top: 10px;
        opacity: 0.7;
        width: calc(100% - 40px);
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            bottom: -5px;
            left: 0;
            background-color: #fff;
            visibility: hidden;
            transition: all 0.2s ease-in-out;
        }

        &.is-current {
            opacity: 1;

            &::before {
                width: 100%;
                visibility: visible;
            }
        }
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        &:focus {
            box-shadow: none !important;
            border: 1px solid rgba(255, 255, 255, 0.5) !important;
        }

        > span {
            display: none;
        }
    }

    .glide__slides {
        margin-bottom: 0;

        li {
            cursor: pointer;

            .focus,
            &:focus {
                box-shadow: none !important;
                border: 1px solid rgba(255, 255, 255, 0.5) !important;
            }

            @at-root {
                .voc-slider--solutions {
                    .glide__slides {
                        li {
                            padding-bottom: 20px;
                        }
                    }
                }
            }

            &:hover {
                .voc-slider__title {
                    opacity: 1;

                    &::before {
                        visibility: visible;
                        width: 100%;
                    }
                }
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(101, 111, 123, 0) 0%, #353c45 100%);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.5;
            }

            a {
                .focus,
                &:focus {
                    box-shadow: none !important;
                }
            }
        }
    }

    &--solutions {
        background-image: linear-gradient(to bottom, $grey-800, $grey-800 50%, $grey-800 50%, $white 50%, $white 50%);

        li {
            &::before {
                display: none !important;
            }
        }

        .voc-slider__content {
            color: $grey-800 !important;
        }
    }

    .glide__arrows {
        .glide__arrow {
            height: 42px;
            width: 42px;
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -24px;
            border: 0;
            background-color: rgba(0, 82, 156, 0.9);
            cursor: pointer;
            padding: 0;
            border-radius: 4px;
            transition: opacity 200ms;

            &.is-disabled {
                opacity: 0;
                transition: opacity 200ms;
            }

            &--next {
                left: auto;
                right: 10px;
            }

            .icon {
                width: 24px;
                height: 24px;
                display: block;
                margin: 0 auto;
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $white;
            }
        }
    }
}

// Hero header
.hero-header {
    &--voc {
        .hero-header__content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .body,
            h3,
            p {
                max-width: none;
            }
        }

        .hero-header__title {
            text-shadow: 0 2px 3px rgba(0, 0, 0, 0.9);
        }

        .icon--arrow-down {
            background-color: $white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            border: 1px solid $grey-500;
            box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.25);

            @include media-breakpoint-up(sm) {
                width: 40px;
                height: 40px;
            }

            @include media-breakpoint-up(md) {
                width: 58px;
                height: 58px;
            }

            svg {
                width: 18px;
                height: 18px;
                position: relative;
                top: 6px;
                fill: $brand-primary;

                @include media-breakpoint-up(sm) {
                    width: 24px;
                    height: 24px;
                    top: 8px;
                }

                @include media-breakpoint-up(md) {
                    width: 32px;
                    height: 32px;
                    top: 12px;
                }
            }
        }
    }
}

// Teaser
.voc-teaser {
    .btn--reset {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        background-color: rgba(0, 82, 156, 0.9);
        border: 0;
        height: 42px;
        width: 42px;
        line-height: normal;
        cursor: pointer;
        border-radius: 4px;
        padding: 0;

        @include media-breakpoint-up(md) {
            top: 20px;
            right: 20px;
        }

        .icon {
            width: 24px;
            height: 24px;
            display: block;
            margin: 0 auto;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: $white;
        }
    }
}

.voc-breadcrumb {
    background: linear-gradient(0deg, $grey-200 0%, $grey-100 100%);

    &__btn {
        height: 80px;
        width: 100%;
        background: linear-gradient(0deg, $grey-100 0%, $grey-200 100%);
        border: 0;
        cursor: pointer;
        border-right: solid 1px $grey-300;
        position: relative;
        display: block;

        &.is-open {
            background: linear-gradient(0deg, $brand-primary 0%, $brand-primary 100%);

            .icon--chevron-down {
                color: $white;
                fill: $white;
                transform: rotate(-180deg);
                transition: all 200ms ease;
                transform-origin: 12px 6px;

                @include media-breakpoint-up(md) {
                    transform-origin: 16px 8px;
                }
            }

            .voc-breadcrumb__category-title,
            .voc-breadcrumb__category-title-value {
                color: $white !important;
            }
        }

        // @include media-breakpoint-up(md) {
        //   min-width: 385px;
        // }

        &--first {
            min-width: auto;
        }

        .icon {
            color: $brand-primary;
            fill: $brand-primary;

            &--undo {
                transform: rotate(-90deg);
            }

            &--chevron-down {
                width: 24px;
                height: 24px;
                right: 3px;
                @include align-vertical(absolute);

                transition: all 200ms ease;

                @include media-breakpoint-up(md) {
                    width: 32px;
                    height: 32px;
                    right: 10px;
                }
            }
        }
    }

    &-btn {
        &__first {
            width: 10%;

            @include media-breakpoint-up(md) {
                width: auto;
            }
        }

        &__second,
        &__third {
            width: 45%;
            flex: 1;

            @include media-breakpoint-up(lg) {
                width: auto;
                flex: none;
                min-width: 385px;
            }
        }
    }

    &__category-title {
        color: $grey-500;
        font-size: $font-size-sm;

        @include media-breakpoint-up(md) {
            font-size: $font-size-base;
        }
    }

    &__category-title-value {
        font-size: $font-size-sm;

        &::first-letter {
            text-transform: uppercase;
        }

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg;
        }
    }
}

// Transitions Vue
.voc-fade-enter-active {
    transition: opacity 250ms ease-in;
}

.voc-fade-leave-active {
    transition: opacity 250ms ease-out;
}

.voc-fade-enter,
.voc-fade-leave-to {
    opacity: 0;
}

// voc slide transition
.voc-slide-enter-active {
    transition: all 250ms ease-in;
}

.voc-slide-leave-active {
    transition: all 250ms ease-out;
}

.voc-slide-enter-to,
.voc-slide-leave {
    max-height: 500px;
    overflow: hidden;
}

.voc-slide-enter,
.voc-slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
