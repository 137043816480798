// add js-readable custom property that contains current breakpoint
body {
    // can be read with javascript
    // getComputedStyle(document.body).getPropertyValue('--current-breakpoint');
    --current-breakpoint: 'xs';
    --background-color: hsla(90deg, 50%, 50%, 0.5);

    @include media-breakpoint-up(s) {
        --current-breakpoint: 's';
        --background-color: hsla(105deg, 50%, 50%, 0.5);
    }
    @include media-breakpoint-up(sm) {
        --current-breakpoint: 'sm';
        --background-color: hsla(120deg, 50%, 50%, 0.5);
    }
    @include media-breakpoint-up(md) {
        --current-breakpoint: 'md';
        --background-color: hsla(150deg, 50%, 50%, 0.5);
    }
    @include media-breakpoint-up(lg) {
        --current-breakpoint: 'lg';
        --background-color: hsla(180deg, 50%, 50%, 0.5);
    }
    @include media-breakpoint-up(xl) {
        --current-breakpoint: 'xl';
        --background-color: hsla(210deg, 50%, 50%, 0.5);
    }
}

// when on local environment, add block indicating breakpoint to bottom right corner
// the data-is-local-env attribute is set in plugins/add-local-attr-to-body.client.js
body[data-is-local-env='true']::before {
    content: var(--current-breakpoint);
    position: fixed;
    z-index: var(--z-breakpoint-indicator, 10000);
    bottom: 0.5em;
    right: 0.5em;
    background: var(--background-color);
    border-radius: 0.2em;
    padding: 0.2em 0.5em;
    width: 4em;
    text-align: center;
}

