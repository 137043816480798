.btn {
    @include line-height(32px, 16px);

    border-color: transparent;
    box-shadow: none;
    white-space: normal;
    font-size: rem(16px);
    font-weight: $font-weight-medium;
    padding: rem(8px) rem(15px);
    border-radius: 4px;
    color: $white;

    .icon {
        fill: $white;
    }

    @include media-breakpoint-up(lg) {
        @include line-height(32px, 20px);

        font-size: rem(20px);
        padding: rem(11px) rem(20px);
    }

    &:disabled {
        opacity: 0.5;
    }

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
        border-color: transparent;
        box-shadow: none;
        color: $white;

        .icon {
            fill: $white;
        }
    }

    &-primary,
    &-ghost,
    &--ghost {
        @include background-image(
            linear-gradient(180deg, rgba($brand-primary-light, 1) 0, rgba($brand-primary, 1) 100%) !important
        );

        border: 0;
        transition: 100ms background-image ease-in-out;

        &:hover,
        &.hover,
        &:active,
        &.active,
        &:focus,
        &.focus {
            @include background-image(
                linear-gradient(
                        180deg,
                        rgba(darken($brand-primary-light, 5), 1) 0,
                        rgba(darken($brand-primary, 5), 1) 100%
                    )
                    !important
            );
        }

        .icon {
            vertical-align: text-bottom;
        }
    }

    &-link,
    &--link {
        position: relative;
        padding-left: 0;
        padding-right: rem(20px);
        color: theme-color(primary);

        @include media-breakpoint-up(lg) {
            padding-right: rem(22px);
        }

        .icon {
            fill: theme-color(primary);
            position: absolute;
            width: 18px;
            height: 18px;
            top: 11px;
            left: 0;

            &--right {
                left: auto;
                right: 0;
            }

            @include media-breakpoint-up(lg) {
                width: 20px;
                height: 20px;
                top: 14px;
            }
        }

        &:hover,
        &.hover,
        &:active,
        &.active,
        &:focus,
        &.focus {
            color: theme-color(primary-dark);

            .icon {
                fill: theme-color(primary-dark);
            }
        }
    }

    &--submenu {
        background-color: transparent;
        padding: 0;

        &:focus-visible {
            outline: 1px solid $brand-primary !important;
            outline-offset: rem(4);
            border-radius: 0;
        }
    }

    &--search,
    &--dealerlocator:not(.truck-configurator) {
        background-color: transparent;
        border-radius: 0;
        color: $grey-600;
        font-weight: $font-weight-normal;

        &:hover,
        &:focus,
        &:active {
            color: $grey-600;

            @include media-breakpoint-up(md) {
                background-color: $grey-50;
            }

            .icon {
                fill: $grey-600;
            }
        }
    }

    &-round,
    &--round {
        @include background-image(
            linear-gradient(180deg, rgba($brand-primary-light, 1) 0, rgba($brand-primary, 1) 100%) !important
        );

        position: relative;
        margin: 0;
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 0;
        transition: 100ms background-image ease-in-out;
        box-shadow: 0 3px 5px 0 rgba($black, 0.25);

        .icon {
            position: absolute;
            top: 5px;
            left: 6px;
            width: 30px;
            height: 30px;
            fill: $white;
        }

        &:hover,
        &.hover,
        &:active,
        &.active,
        &:focus,
        &.focus {
            @include background-image(
                linear-gradient(
                        180deg,
                        rgba(darken($brand-primary-light, 5), 1) 0,
                        rgba(darken($brand-primary, 5), 1) 100%
                    )
                    !important
            );

            box-shadow: 0 3px 5px 0 rgba($black, 0.25) !important;
        }
    }

    &-ghost,
    &--ghost {
        border: 1px solid $white;
        color: $white;
        background: transparent !important;

        .icon {
            fill: $white;
        }
    }

    &--quicklink {
        @include line-height(20px, 16px);

        text-align: left;
        display: flex;
        align-items: center;
        gap: rem(16);
        border: $border-default;
        color: $grey-800;
        transition: none;
        padding: rem(16px) rem(20px);
        background: $white;

        .icon {
            fill: currentcolor;
            flex: 0 0 auto;
        }

        @include media-breakpoint-up(md) {
            padding: rem(20px);
        }

        &:hover,
        &:active,
        &:focus {
            @include background-image(
                linear-gradient(
                        180deg,
                        rgba(darken($brand-primary-light, 5), 1) 0,
                        rgba(darken($brand-primary, 5), 1) 100%
                    )
                    !important
            );

            color: $white;
        }
    }

    &-sm {
        max-height: 46px !important;
        font-size: rem(16px) !important;
        @include line-height(24px, 16px);

        .icon {
            top: 13px;
        }
    }

    &--unfold {
        width: 56px;
        height: 56px;
        transition: background-image 250ms ease-in-out;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 36px;
            height: 36px;
            margin-top: -18px;
            margin-left: -18px;
            transform: rotate(-45deg);
            transition: fill 250ms ease-in-out, transform 350ms ease-in-out;
            color: $white;
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            @include background-image(linear-gradient(0deg, rgba($grey-400, 1) 0, rgba($grey-100, 1) 100%) !important);

            cursor: pointer;

            .icon {
                fill: theme-color(primary);
            }
        }

        &.active {
            .icon {
                transform: rotate(0deg);
            }
        }
    }

    &--dealer-search {
        border: 1px solid $white;
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid $white;
        }
    }
}

.bg-light,
.bg-medium {
    .btn {
        &-ghost,
        &--ghost {
            border: 1px solid $brand-primary-light !important;
            color: $brand-primary-light !important;
            background: transparent !important;

            .icon {
                fill: $brand-primary-light !important;
                vertical-align: text-bottom !important;
            }

            &:hover,
            &.hover,
            &:active,
            &.active,
            &:focus,
            &.focus {
                @include background-image(
                    linear-gradient(
                            180deg,
                            rgba(darken($brand-primary-light, 5), 1) 0,
                            rgba(darken($brand-primary, 5), 1) 100%
                        )
                        !important
                );

                border-color: transparent !important;
                box-shadow: none !important;
                color: $white !important;

                .icon {
                    fill: $white !important;
                }
            }
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .btn {
        &-link,
        &--link {
            color: $grey-400 !important;

            .icon {
                fill: $grey-400 !important;
            }

            &:hover,
            &.hover,
            &:active,
            &.active,
            &:focus,
            &.focus {
                color: $grey-500 !important;

                .icon {
                    fill: $grey-500 !important;
                }
            }
        }
    }
}
