/*!
 * ClockPicker v{package.version} for Bootstrap (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 */
// sass-lint:disable-all
.clockpicker .input-group-addon {
    cursor: pointer;
}

.clockpicker-moving {
    cursor: move;
}

.clockpicker-align-left.popover > .arrow {
    left: 25px;
}

.clockpicker-align-top.popover > .arrow {
    top: 17px;
}

.clockpicker-align-right.popover > .arrow {
    left: auto;
    right: 25px;
}

.clockpicker-align-bottom.popover > .arrow {
    top: auto;
    bottom: 6px;
}

.clockpicker-popover .popover-title {
    background-color: #fff;
    color: #999;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
}

.clockpicker-popover .popover-title span {
    cursor: pointer;
}

.clockpicker-popover .popover-content {
    background-color: #f8f8f8;
    padding: 12px;
}

.popover-content:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.clockpicker-plate {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    overflow: visible;
    position: relative;

    /* Disable text selection highlighting. Thanks to Hermanya */
    -webkit-touch-callout: none;
    user-select: none;
}

.clockpicker-canvas,
.clockpicker-dial {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -1px;
    top: -1px;
}

.clockpicker-minutes {
    visibility: hidden;
}

.clockpicker-tick {
    border-radius: 50%;
    color: #666;
    line-height: 26px;
    text-align: center;
    width: 26px;
    height: 26px;
    position: absolute;
    cursor: pointer;
}

.clockpicker-tick.active,
.clockpicker-tick:hover {
    background-color: rgb(192, 229, 247);
    background-color: rgba(0, 149, 221, 0.25);
}

.clockpicker-button {
    background-image: none;
    background-color: #fff;
    border-width: 1px 0 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    padding: 10px 0;
}

.clockpicker-button:hover {
    background-image: none;
    background-color: #ebebeb;
}

.clockpicker-button:focus {
    outline: none !important;
}

.clockpicker-dial {
    transition: transform 350ms, opacity 350ms;
}

.clockpicker-dial-out {
    opacity: 0;
}

.clockpicker-hours.clockpicker-dial-out {
    transform: scale(1.2, 1.2);
}

.clockpicker-minutes.clockpicker-dial-out {
    transform: scale(0.8, 0.8);
}

.clockpicker-canvas {
    transition: opacity 175ms;
}

.clockpicker-canvas-out {
    opacity: 0.25;
}

.clockpicker-canvas-bearing,
.clockpicker-canvas-fg {
    stroke: none;
    fill: rgb(0, 149, 221);
}

.clockpicker-canvas-bg {
    stroke: none;
    fill: rgb(192, 229, 247);
}

.clockpicker-canvas-bg-trans {
    fill: rgba(0, 149, 221, 0.25);
}

.clockpicker-canvas line {
    stroke: rgb(0, 149, 221);
    stroke-width: 1;
    stroke-linecap: round;

    /* shape-rendering: crispEdges; */
}

.clockpicker-button.am-button {
    margin: 1px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.clockpicker-button.pm-button {
    margin: 1px 1px 1px 136px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

// sass-lint:enable-all
