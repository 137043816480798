.stickycontact {
    z-index: 200;
    position: fixed;
    right: auto;
    left: 30px;
    bottom: 0;
    width: calc(100% - 60px);
    background-color: theme-color(primary);
    border-radius: rem(5px) rem(5px) 0 0;
    box-shadow: -6px 4px 78px 0 rgba(0, 0, 0, 0.12);

    @include media-breakpoint-up(md) {
        right: 50px;
        width: 280px;
        left: auto;
    }

    > .btn--stickycontact {
        @include background(linear-gradient(180deg, theme-color(primary-light), theme-color(primary)));

        background-color: transparent;
        border: solid 0;
        width: 100%;
        cursor: pointer;
        border-bottom: 1px solid theme-color(primary-light);
        padding: 0;
        border-radius: rem(5px) rem(5px) 0 0;

        > img {
            position: absolute;
            top: -25px;
            right: 15px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 3px solid $white;
            box-shadow: -3px 2px 25px 0 rgba(0, 0, 0, 0.25);
            margin: 0;
        }

        p {
            color: $white;
            margin-bottom: 0;
            padding: rem(16px) rem(20px) rem(16px) rem(30px);
            font-weight: $font-weight-bold;
            font-size: rem(16px);
        }
    }

    &__list {
        margin-bottom: 0;

        li {
            a {
                border-top: 1px solid theme-color(primary);
                border-bottom: 1px solid theme-color(primary-light);
                padding: rem(14px) rem(5px) rem(14px) rem(30px);
                display: flex;
                color: $white;
                align-items: center;

                &:hover {
                    background-color: theme-color(primary-light);
                    border-bottom: 1px solid theme-color(primary-lighter);
                    border-top: 1px solid theme-color(primary-lighter);
                }
            }
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: rem(15px);

            svg {
                fill: $white;
                color: $white;
            }
        }
    }
}
