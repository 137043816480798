/* stylelint-disable no-duplicate-selectors */
// ClockPicker v{package.version} for Bootstrap (http://weareoutman.github.io/clockpicker/)
// Copyright 2014 Wang Shenwei.
// Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)

.clockpicker {
    .input-group-addon {
        cursor: pointer;
    }

    &-moving {
        cursor: move;
    }

    &-align-left {
        .popover > .arrow {
            left: 25px;
        }
    }

    &-align-top {
        .popover > .arrow {
            top: 17px;
        }
    }

    &-align-right {
        .popover > .arrow {
            left: auto;
            right: 25px;
        }
    }

    &-align-bottom {
        .popover > .arrow {
            top: auto;
            bottom: 6px;
        }
    }

    &-popover {
        .popover-title {
            background-color: #fff;
            color: #999;
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
            text-align: center;

            span {
                cursor: pointer;
            }
        }

        .popover-content {
            background-color: #f8f8f8;
            padding: 12px;

            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    &-plate {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        overflow: visible;
        position: relative;
        -webkit-touch-callout: none;
        user-select: none;
    }

    &-canvas,
    &-dial {
        width: 200px;
        height: 200px;
        position: absolute;
        left: -1px;
        top: -1px;
    }

    &-minutes {
        visibility: hidden;
    }

    &-tick {
        border-radius: 50%;
        color: #666;
        line-height: 26px;
        text-align: center;
        width: 26px;
        height: 26px;
        position: absolute;
        cursor: pointer;

        &.active,
        &:hover {
            // background-color: rgb(192, 229, 247);
            background-color: rgba(0, 149, 221, 0.25);
        }
    }

    &-button {
        background-image: none;
        background-color: #fff;
        border-width: 1px 0 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin: 0;
        padding: 10px 0;

        &:hover {
            background-image: none;
            background-color: #ebebeb;
        }

        &:focus {
            outline: none !important;
        }
    }

    &-dial {
        transition: transform 350ms, opacity 350ms;

        &-out {
            opacity: 0;
        }
    }

    &-hours {
        .clockpicker-dial-out {
            transform: scale(1.2, 1.2);
        }
    }

    &-minutes {
        .clockpicker-dial-out {
            transform: scale(0.8, 0.8);
        }
    }

    &-canvas {
        transition: opacity 175ms;

        &-out {
            opacity: 0.25;
        }

        &-bearing,
        &-fg {
            stroke: none;
            fill: rgb(0, 149, 221);
        }

        &-bg {
            stroke: none;
            fill: rgb(192, 229, 247);

            &-trans {
                fill: rgba(0, 149, 221, 0.25);
            }
        }

        line {
            stroke: rgb(0, 149, 221);
            stroke-width: 1px;
            stroke-linecap: round;
        }
    }

    &-button {
        .am-button {
            margin: 1px;
            padding: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }

        .pm-button {
            margin: 1px 1px 1px 136px;
            padding: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }
    }
}
