.footer-links {
    padding-top: rem(38px);

    &__title {
        margin: 0 0 rem(18px) 0;
        padding: 0 0 rem(6px) 0;
        color: $white;
        font-size: rem(16px);
        @include line-height(40px, 16px);

        font-weight: $font-weight-normal;
        border-bottom: 1px solid $grey-700;
    }

    .nav {
        margin-bottom: rem(26px);
    }

    .nav-link {
        padding-left: 0;
        font-size: rem(14px);
        @include line-height(16px, 14px);

        color: $grey-400;
        fill: $grey-400;
        transition: color 100ms ease-in-out, fill 100ms ease-in-out;
        cursor: pointer;

        &:hover {
            color: $white;
            fill: $white;
        }
    }

    .social-links {
        margin-top: rem(-8px);
        max-width: 180px;
    }
}
