.table {
    overflow-x: scroll;
    overflow-y: visible;
    margin-bottom: rem(10px);

    @include media-breakpoint-up(lg) {
        overflow-x: hidden;
    }

    table {
        overflow: hidden;
        width: 100%;
        margin: rem(25px) auto rem(10px) auto;
        border: 0;

        @include media-breakpoint-up(lg) {
            table-layout: fixed;
        }
    }

    .table-row,
    .table-header-row {
        border-bottom: $border-default;
    }

    th,
    td {
        position: relative;
        border: 0;
        outline: 0;
        padding: rem(10px) rem(10px) rem(10px) rem(15px);
        background-clip: padding-box;
        color: $grey-600;
        font-size: rem(12px);

        @include media-breakpoint-up(sm) {
            font-size: rem(14px);
        }
    }

    &.has-zebra-stripes-odd {
        .table-row {
            &:nth-child(odd) {
                background-color: $grey-100;
            }

            &:nth-child(even) {
                background-color: $white;
            }

            &::after {
                content: none;
            }
        }
    }

    &.has-zebra-stripes-even {
        .table-row {
            &:nth-child(even) {
                background-color: $grey-100;
            }

            &:nth-child(odd) {
                background-color: $white;
            }

            &::after {
                content: none;
            }
        }
    }

    &.has-icons {
        .table-row {
            th,
            td {
                min-width: rem(100px);
                vertical-align: middle;
            }
        }
    }

    .table__properties {
        vertical-align: middle;
        padding: rem(15px) rem(5px) rem(15px) rem(10px);
        position: absolute;
        left: 0;
        width: rem(120px);
        text-align: left;

        @include media-breakpoint-up(sm) {
            width: rem(170px);
        }

        &--first-normal-row {
            margin-top: -1px;
            border-top: $border-default;
        }
    }

    .highlighted {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: rem(1500px);
            bottom: 0;
            left: 0;
            background-color: rgba(185, 193, 198, 0.15);
        }
    }

    &__legend {
        margin-top: rem(10px);

        p {
            display: inline-block;
            font-size: rem(12px);
            padding: 0 rem(10px);
        }

        .icon {
            margin-left: rem(10px);
            position: relative;
            top: 3px;
        }
    }

    .bg-dark &,
    .bg-blue &,
    .bg-black & {
        &.has-zebra-stripes-odd {
            .table-row {
                &:nth-child(odd) {
                    background-color: $grey-900;
                }

                &:nth-child(even) {
                    background-color: $grey-800;
                }
            }
        }

        &.has-zebra-stripes-even {
            .table-row {
                &:nth-child(even) {
                    background-color: $grey-900;
                }

                &:nth-child(odd) {
                    background-color: $grey-800;
                }
            }
        }

        th,
        td {
            color: $white;

            a {
                color: $white;
            }
        }
    }
}

.comparison-table {
    &__container {
        position: relative;
    }

    .table {
        width: calc(100% - #{rem(120px)});
        margin-left: rem(120px);

        @include media-breakpoint-up(sm) {
            width: calc(100% - #{rem(170px)});
            margin-left: rem(170px);
        }

        &.no-properties,
        &.properties-not-fixed {
            width: 100%;
            margin-left: 0;
        }

        &.properties-not-fixed {
            @include media-breakpoint-up(sm) {
                width: calc(100% - #{rem(170px)});
                margin-left: rem(170px);
            }

            .table__properties {
                position: relative;
                min-width: rem(120px);

                @include media-breakpoint-up(sm) {
                    position: absolute;
                    min-width: auto;
                }
            }
        }
    }

    .table-row {
        border-bottom: 0;
    }

    .table-header-row {
        th {
            z-index: 200;
            padding: rem(10px) rem(10px) rem(10px) rem(15px);
            text-align: center;
            color: theme-color(secondary);
            font-weight: 600;
            vertical-align: top;

            a {
                color: inherit;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .nogutters {
            padding: 0;
            width: 0;
        }
    }

    td {
        text-align: center;
        vertical-align: middle;
        min-width: rem(100px);
        width: 20%;
    }

    .icon {
        width: rem(16px);
        height: rem(16px);

        &-checkmark {
            position: relative;
            display: inline-block;

            svg {
                color: theme-color(secondary);
                fill: theme-color(secondary);
            }

            &--opaque {
                svg {
                    opacity: 0.5;
                }
            }
        }
    }

    &--blue {
        .table-header-row th {
            color: theme-color(primary);
        }

        .icon-checkmark svg {
            color: theme-color(primary);
            fill: theme-color(primary);
        }
    }

    &--grey {
        .table-header-row th {
            color: $grey-600;
        }

        .icon-checkmark svg {
            color: $grey-400;
            fill: $grey-400;
        }
    }

    &--yellow {
        .table-header-row th {
            color: $yellow;
        }

        .icon-checkmark svg {
            color: $yellow;
            fill: $yellow;
        }
    }
}
