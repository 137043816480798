$countdown-card-half-height: 0.68em;
$countdown-card-border-radius: 0.15em;

.countdown-clock {
    perspective: rem(600px);
    margin: 0 auto;
    text-align: center;

    &__piece {
        display: inline-block;
        margin: 0 1px;

        @include media-breakpoint-up(md) {
            margin: 0 rem(2px);
        }
    }

    &__slot {
        display: block;
        margin-top: rem(6px);
        color: $grey-500;
        font-size: rem(11px);
        letter-spacing: 0.125rem;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: rem(12px);
            letter-spacing: 0.25rem;
        }
    }
}

.countdown-card {
    position: relative;
    display: block;
    padding-bottom: $countdown-card-half-height;
    font-size: 2.5rem;
    line-height: 0.95;

    @include media-breakpoint-up(md) {
        font-size: 3.8rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 5rem;
    }

    &__top,
    &__bottom,
    &__back-bottom,
    &__back::before,
    &__back::after,
    &__top-4digits,
    &__bottom-4digits,
    &__back-bottom-4digits,
    &__back-4digits::before,
    &__back-4digits::after {
        display: block;
        width: 1.8em;
        height: $countdown-card-half-height;
        background-color: $grey-900;
        color: rgba($white, 0.9);
        padding: 0.21em 0.15em 0.36em;
        border-radius: $countdown-card-border-radius $countdown-card-border-radius 0 0;
        backface-visibility: hidden;
        transform-style: preserve-3d;
    }

    &__top-4digits,
    &__bottom-4digits,
    &__back-bottom-4digits,
    &__back-4digits::before,
    &__back-4digits::after {
        width: 2.2em;
    }

    &__bottom,
    &__back-bottom,
    &__bottom-4digits,
    &__back-bottom-4digits {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 0;
        overflow: hidden;
        border-top: solid 1px $black;
        border-radius: 0 0 $countdown-card-border-radius $countdown-card-border-radius;
        background-color: $grey-800;
        color: $white;
        pointer-events: none;
    }

    &__back-bottom,
    &__back-bottom-4digits {
        z-index: 1;
    }

    &__bottom::after,
    &__back-bottom::after,
    &__bottom-4digits::after,
    &__back-bottom-4digits::after {
        display: block;
        margin-top: -$countdown-card-half-height;
    }

    &__back::before,
    &__bottom::after,
    &__back-bottom::after,
    &__back-4digits::before,
    &__bottom-4digits::after,
    &__back-bottom-4digits::after {
        content: attr(data-value);
    }

    &__back,
    &__back-4digits {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0%;
        pointer-events: none;

        &::before,
        &__back-4digits::before {
            position: relative;
            overflow: hidden;
            z-index: -1;
        }
    }
}

.countdown .countdown-card__back::before,
.countdown .countdown-card__back-4digits::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
}

.countdown .countdown-card__bottom,
.countdown .countdown-card__bottom-4digits {
    transform-origin: center top;
    animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
    animation-fill-mode: both;
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }

    99% {
        opacity: 1;
    }

    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {
    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }

    51% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
        z-index: 5;
    }
}

// backgrounds
.bg-dark,
.bg-blue,
.bg-black {
    .countdown-clock__slot {
        color: $white;
    }

    .countdown-card__top,
    .countdown-card__bottom,
    .countdown-card__back-bottom,
    .countdown-card__back::before,
    .countdown-card__back::after {
        background-color: $grey-100;
        color: $black;
    }

    .countdown-card__bottom,
    .countdown-card__back-bottom {
        background-color: $grey-50;
        color: $grey-800;
    }
}
