.breadcrumb-list {
    display: flex;
    flex-wrap: wrap;
    gap: rem(6);
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: rem(14);
    color: theme-color(primary);

    .icon {
        margin: 0;
        fill: currentcolor;
        width: 18px;
        height: 18px;
    }

    a {
        display: inline-flex;
        gap: rem(6);

        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }

        &:focus-visible {
            outline: 1px solid currentcolor !important;
            outline-offset: rem(4);
        }

        &[aria-current='page'] {
            font-weight: 700;
        }
    }

    &__item {
        display: none;
        gap: rem(6);
        align-items: center;

        &:nth-last-child(2) {
            display: flex;
        }
    }

    &__icon-divider {
        display: none;
    }

    @include media-breakpoint-up(md) {
        a {
            color: currentcolor;
        }

        &__item {
            display: flex;
        }

        &__icon-divider {
            display: block;
        }

        &__icon-back {
            display: none;
        }
    }
}
