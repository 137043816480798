$link-card-transition-duration: 200ms;

.link-card {
    position: relative;
    display: block;
    border: 1px solid transparent;
    border-radius: rem(10px);
    transition: background-color $link-card-transition-duration ease-in, border $link-card-transition-duration ease-in;

    &:hover {
        background-color: rgba($grey-100, 0.5);
        border-color: $grey-200;

        .bg-dark &,
        .bg-blue &,
        .bg-black & {
            background-color: rgba($white, 0.05);
        }

        .bg-medium & {
            background-color: $white;
        }

        .btn-round {
            opacity: 1;
        }
    }

    img {
        height: rem(120px);
    }

    .btn-round {
        opacity: 0; // hide without hover
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        transition: opacity $link-card-transition-duration ease-in;
    }
}
