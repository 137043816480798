.loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        background-color: bg-color(light);
    }

    .spinner {
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        transform: translate3d(-50%, -50%, 0);

        &__circle-wrapper {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;

            &:nth-child(1) {
                transform: rotate(0deg);

                .spinner__circle {
                    animation-delay: 0ms;
                }
            }

            &:nth-child(2) {
                transform: rotate(340deg);

                .spinner__circle {
                    animation-delay: 50ms;
                }
            }

            &:nth-child(3) {
                transform: rotate(320deg);

                .spinner__circle {
                    animation-delay: 100ms;
                }
            }

            &:nth-child(4) {
                transform: rotate(300deg);

                .spinner__circle {
                    animation-delay: 150ms;
                }
            }

            &:nth-child(5) {
                transform: rotate(280deg);

                .spinner__circle {
                    animation-delay: 200ms;
                }
            }

            &:nth-child(6) {
                transform: rotate(260deg);

                .spinner__circle {
                    animation-delay: 250ms;
                }
            }
        }

        &__circle {
            top: 50%;
            left: 50%;
            width: 10%;
            height: 10%;
            margin-top: -50%;
            margin-left: -5%;
            position: absolute;
            background: theme-color(secondary);
            transform-origin: 50% 500%;
            border-radius: 50%;
            animation: rotate 5000ms infinite cubic-bezier(0.48, 0.18, 0.43, 0.89);
        }
    }

    &--form-control {
        .spinner__circle {
            background: $white;
        }
    }
}

.bg-medium {
    .loader {
        &__overlay {
            background-color: bg-color(medium);
        }
    }
}

.bg-dark {
    .loader {
        &__overlay {
            background-color: bg-color(dark);
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    42% {
        transform: rotate(360deg);
    }

    62% {
        opacity: 1;
    }

    64% {
        opacity: 0;
    }

    84% {
        transform: rotate(720deg);
        opacity: 0;
    }

    85% {
        transform: rotate(920deg);
        opacity: 0;
    }

    88% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
        transform: rotate(1080deg);
        opacity: 1;
    }
}
