* {
    box-sizing: border-box;
}

.certificate-generator {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .bulk-select-tabs {
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0.5rem;
            width: 100%;
            height: 1px;
            background-color: $grey-300;
            z-index: -1;
        }

        label {
            cursor: pointer;
            border-left: 1px solid $grey-300;
            border-top: 1px solid $grey-300;
            border-bottom: 1px solid $grey-300;
            background-color: $grey-100;
            color: $grey-500;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;

            &:last-child {
                border-right: 1px solid $grey-300;
            }
        }

        input {
            display: none;

            &:checked + label {
                background-color: $white;
                color: $grey-700;
                border-bottom: 1px solid $white;
            }
        }
    }

    hr {
        margin: 0;
        border: none;
        border-top: 1px solid #e0e0e0;
    }

    .input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .search-block {
            width: 100%;
            display: flex;

            &__input {
                margin-right: 20px;
                width: 50%;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            &__button {
                .cert-btn {
                    background-image: $brand-btn-blue-gradient !important;

                    &:hover {
                        background-color: $brand-primary;
                    }
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;

                    .cert-btn {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .is-valid ~ .feedback-icon,
            .is-invalid ~ .feedback-icon {
                display: flex;
            }

            .is-valid {
                border-color: $validation-warning-green !important;

                ~ .feedback-icon {
                    svg {
                        fill: $validation-warning-green;
                    }
                }
            }

            .is-invalid {
                border-color: $validation-warning-red !important;

                ~ .feedback-icon {
                    svg {
                        fill: $validation-warning-red;
                    }
                }
            }

            .valid-feedback.feedback-icon,
            .invalid-feedback.feedback-icon {
                position: absolute;
                width: auto;
                bottom: 10px;
                right: 10px;
                margin-top: 0;
            }

            .certificate-generator__title {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 20px;
            }
        }

        .status-field {
            width: 100%;
            min-height: 41px;
            padding: 8px 16px;
            display: flex;
            align-items: center;

            &.is-valid {
                background-color: $validation-warning-light-green;
            }

            &.is-invalid {
                background-color: $validation-warning-light-red;
            }

            p {
                margin: 0;
            }

            ul li {
                color: text-color(dark);
            }
        }
    }

    .downloads-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        .certificates {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .no-certificates-text {
                margin: 20px 0;
            }

            .certificate {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__download {
                    .cert-btn {
                        border: 1px solid $brand-primary;
                        border-radius: 4px;
                        color: $brand-primary;
                        display: flex;

                        svg {
                            fill: $brand-primary;
                        }

                        &:hover {
                            color: $white;
                            background-image: $brand-btn-blue-gradient !important;

                            svg {
                                fill: $white;
                            }
                        }

                        @include media-breakpoint-down(sm) {
                            .button__text {
                                display: none;
                            }
                        }
                    }
                }

                .pdf-meta-info {
                    display: flex;
                    width: 100%;
                    margin: 20px 0;

                    .vin {
                        margin-right: 20px;
                    }
                }
            }
        }

        .vin,
        .plate {
            min-width: 125px;
        }

        &__title {
            width: 100%;
            font-weight: 400;
            font-size: 26px;
        }

        .titles {
            display: flex;
            justify-content: space-between;
            margin: 20px 0 10px;

            .pdf-headers {
                display: flex;
                width: 100%;
                font-weight: 600;

                .vin {
                    margin-right: 20px;
                }
            }
        }
    }

    .cert-btn {
        position: relative;
        padding: 8px 16px;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        height: 42px;

        .button__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:disabled {
            background-color: #e0e0e0;
            border-color: #e0e0e0;
            color: #9e9e9e;
            cursor: not-allowed;

            &:hover {
                background-color: #e0e0e0;
                border-color: #e0e0e0;
                color: #9e9e9e;
            }
        }
    }

    .cert-btn__text {
        transition: all 0.2s;
    }

    .cert-btn--loading {
        .download-icon,
        .button__text {
            opacity: 0;
        }
    }

    .cert-btn--loading::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid $brand-primary;
        border-top-color: $white;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }
}
