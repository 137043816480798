.driver-manual-search {
    @include media-breakpoint-up(md) {
        padding: rem(40px);
    }

    .form-fieldset {
        padding-bottom: 0;
    }

    .errormessage {
        padding: 0;
        margin: 0;
    }

    &__title {
        font-size: rem(26px);
        font-weight: $font-weight-medium;
        text-align: center;
        line-height: 1.25;
        margin-bottom: 2rem;
    }

    &__form,
    &__results {
        padding: rem(40px);
    }

    &__form {
        padding-bottom: 0;
    }

    &__search-button {
        z-index: 1;
        margin: 0 auto -2rem;
    }

    .is-hidden {
        display: none;
    }

    &__results {
        margin-top: rem(40px);

        .row {
            align-items: center;
        }

        ul {
            margin: 0;
        }

        li {
            font-size: rem(14px);
            padding-left: rem(20px);
            margin-bottom: rem(10px);

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            &:nth-child(even) {
                background-color: $white;
            }
        }

        h2 {
            padding-bottom: rem(10px);
            border-bottom: solid rem(2px) $grey-400;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: $font-weight-medium;
            text-align: center;
        }

        .icon--file-pdf {
            fill: theme-color(secondary);
            color: theme-color(secondary);
            margin-right: 20px;
        }

        .manual-title,
        .manual-link {
            padding-top: rem(5px);
            padding-bottom: rem(5px);

            @include media-breakpoint-up(md) {
                min-height: rem(45px);
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &__error {
        margin-bottom: 0;
        font-size: rem(24px);
        font-weight: $font-weight-medium;
        text-align: center;
    }

    &__specification {
        margin-bottom: 0;
        font-size: rem(24px);
        text-align: center;
    }

    &__upgrades {
        font-size: rem(32px);
        font-weight: $font-weight-bold;
        text-align: center;

        &[data-has-upgrades='false'] {
            color: $green;
        }

        &[data-has-upgrades='true'] {
            color: $brand-secondary;
        }
    }

    &__follow-up {
        margin-top: rem(20px);
        text-align: center;

        p {
            font-weight: $font-weight-bold;
        }

        .btn {
            max-width: rem(300px);

            @include media-breakpoint-down(md) {
                display: block;
                margin: 0 auto;
            }

            & + .btn {
                margin-top: rem(10px);

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                    margin-left: rem(20px);
                }
            }
        }
    }

    .btn-primary {
        padding-top: rem(6px);
        padding-bottom: rem(6px);
    }

    .tpl {
        display: none;
    }

    @at-root {
        .bg-medium & {
            background-color: transparent;
        }

        .bg-dark &,
        .bg-blue &,
        .bg-black & {
            background-color: transparent;

            &__results {
                li {
                    &:nth-child(even) {
                        background-color: $grey-700;
                    }
                }

                h2 {
                    border-top: solid 1px $grey-600;
                    border-bottom: solid 1px $grey-600;
                }

                .icon--file-pdf {
                    fill: $white;
                    color: $white;
                    margin-right: 20px;
                }

                .manual-link {
                    a {
                        color: $white;
                    }
                }
            }
        }
    }
}
