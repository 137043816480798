.carousel {
    &-control-next,
    &-control-prev {
        width: 60px;
    }

    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;

        .icon--chevron-left,
        .icon--chevron-right {
            @include align-center(absolute);

            position: absolute;
            fill: $white;
            color: $white;
            width: 48px;
            height: 48px;
        }

        &::before {
            @include align-vertical(absolute);

            content: '';
            background-color: rgba($white, 0.2);
            width: 60px;
            height: 110px;
            position: absolute;
            transition: background-color 100ms ease-in-out;
        }

        &:hover {
            &::before {
                background-color: rgba($white, 0.4);
            }
        }
    }

    .carousel-control-prev {
        &::before {
            content: '';
            right: 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    .carousel-control-next {
        &::before {
            left: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
    }

    &-indicators {
        height: 5px;

        li {
            width: 35px;
            height: 5px;
            background-color: $grey-300;
            cursor: pointer;
            transition: background-color 100ms ease-in-out, opacity 100ms ease-in-out;

            &:hover {
                background-color: $grey-400;
            }

            &.active {
                background-color: $grey-600;
                cursor: default;
            }

            @include media-breakpoint-up(sm) {
                width: 55px;
            }

            @include media-breakpoint-up(lg) {
                background-color: $white;
                width: 110px;
                opacity: 0.5;

                &:hover {
                    background-color: $white;
                    opacity: 0.75;
                }

                &.active {
                    background-color: $white;
                    opacity: 1;
                    cursor: default;
                }
            }
        }
    }
}
