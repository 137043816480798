.invalid,
.valid,
.help {
    &-feedback {
        display: none;

        &--msg {
            margin: 0;
            padding: rem(8px) 0 0 0 !important;
            font-size: rem(14px);
            pointer-events: none;
            @include line-height(18px, 14px);

            @include media-breakpoint-up(md) {
                align-items: center;
                padding: 0 0 0 rem($grid-gutter-width) !important;
            }
        }

        &.icon {
            margin: rem(11px) rem(20px) 0 rem(-40px);
            z-index: 10;
            width: 20px;
            height: 20px;
        }
    }
}

.valid {
    &-feedback {
        &.icon {
            fill: theme-color(success);
        }
    }
}

.invalid {
    &-feedback {
        &.icon {
            fill: theme-color(danger);
        }
    }
}

.help {
    &-feedback {
        &--msg {
            display: flex;
            color: $grey-500;
        }
    }
}

.form-control {
    &.is-invalid ~ .help-feedback {
        &--msg {
            display: none;
        }
    }

    &.is-invalid ~ .invalid-feedback {
        &--msg {
            display: flex;
        }
    }
}

.was-validated {
    .form-control {
        &:invalid ~ .help-feedback,
        &.is-invalid ~ .help-feedback {
            &--msg {
                display: none;
            }
        }

        &:invalid ~ .invalid-feedback,
        &.is-invalid ~ .invalid-feedback {
            &--msg {
                display: flex;
            }
        }

        &:valid ~ .invalid-feedback,
        &.is-valid ~ .invalid-feedback {
            display: none !important;
        }
    }
}

.form-group {
    &.is-invalid {
        .form-check-label {
            &::before {
                border-color: theme-color(danger);
            }
        }

        .invalid-feedback {
            display: block;
            float: left;

            &--msg {
                margin: rem(4px) 0 0 0;
                padding-left: rem(10px) !important;
                width: auto;
            }

            &.icon {
                margin: 0;
            }
        }
    }

    &.is-valid {
        .valid-feedback {
            display: none;
        }
    }
}

.was-validated textarea,
textarea {
    &.form-control {
        & ~ .help-feedback--msg,
        &.is-valid ~ .help-feedback--msg,
        &.is-invalid ~ .help-feedback--msg,
        &.is-invalid ~ .invalid-feedback--msg,
        &.is-valid ~ .valid-feedback--msg {
            align-items: start;

            @include media-breakpoint-up(md) {
                margin-top: rem(13px);
            }
        }
    }
}

.alert-danger {
    margin-bottom: rem(50px);
    color: theme-color(danger);
    padding-left: rem(55px);

    .icon {
        &.alert-feedback {
            position: absolute;
            top: rem(11px);
            left: rem(17px);
            fill: theme-color(danger);
        }
    }
}
