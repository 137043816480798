.breadcrumb-nav {
    display: flex;
    margin: auto 0;

    @include media-breakpoint-down(lg) {
        display: none;
    }

    &__item {
        font-size: rem(14px);

        &::before {
            display: inline-block;
            padding-right: $breadcrumb-item-padding;
            padding-left: $breadcrumb-item-padding;
            color: $brand-truck-configurator-blue;
            content: ">";
        }

        &.breadcrumb-nav__link {
            color: $brand-truck-configurator-blue;

            &:hover {
                color: $brand-truck-configurator-blue;
            }
        }
    }

    &__title {
        color: $brand-truck-configurator-grey;
    }
}
