.featured-products {
    margin-bottom: -20px;
    @include make-row;

    .product-tile-col {
        margin-bottom: 20px;
        @include make-col-ready;

        .product-tile {
            position: relative;
            border: 1px solid $grey-200;
            border-radius: 4px;
            height: 100%;
            display: block;
            background-color: $white;

            &:hover {
                box-shadow: 2px -2px 78px 0 rgba(0, 0, 0, 0.2);
            }

            &__image {
                position: relative;
                margin: 0;
                padding: rem(10px) rem(20px) rem(40px) rem(20px);
                width: 100%;
                border-bottom: 1px solid $grey-200;

                img {
                    width: 100%;
                }
            }

            &__manufacturer-logo {
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: 15px;
                width: auto !important;
                max-height: 16px;
            }

            &__body {
                padding: rem(10px) rem(20px) rem(10px) rem(20px);
                width: 100%;
            }

            &__artnumber {
                margin: 0 0 rem(4px) 0;
                font-size: rem(14px);
                font-weight: $font-weight-bold;
                color: theme-color(primary) !important;
            }

            &__name {
                margin: rem(2px) 0 rem(6px) 0;
                font-weight: $font-weight-bold;
                font-size: rem(18px);
                @include line-height(18px, 18px);
            }

            &__description {
                margin: 0;
                font-size: rem(14px);
                @include line-height(20px, 14px);
            }

            &__prices {
                display: none;
                width: 100%;
            }
        }
    }

    &--portrait {
        .product-tile-col {
            @include make-col(12);

            @include media-breakpoint-up(sm) {
                @include make-col(6);
            }

            @include media-breakpoint-up(md) {
                @include make-col(3);
            }
        }
    }

    &--landscape {
        .product-tile-col {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }

            .product-tile {
                &__image {
                    width: 33%;
                    height: 100%;
                    float: left;
                    border: none;
                }

                &__body {
                    padding-top: rem(20px);
                    padding-bottom: rem(30px);
                    width: 67%;
                    height: 100%;
                    float: left;
                    border-left: 1px solid $grey-200;
                }
            }
        }
    }
}
