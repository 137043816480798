.card-highlight {
    margin: 0;
    padding-bottom: 0;

    @media all and (-ms-high-contrast: none) {
        display: block;
    }

    &:first-child {
        margin-top: rem(10px);

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    .card__image,
    .icon--highlight {
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin: rem(10px) 0 0 0;
        }
    }

    .card__image {
        max-width: 100%;
    }

    .icon--highlight {
        fill: theme-color(primary);
        max-width: 30px;
        max-height: 30px;

        .highlight-features-blue & {
            fill: theme-color(primary);
        }

        .highlight-features-orange & {
            fill: theme-color(secondary);
        }

        .highlight-features-yellow & {
            fill: $yellow;
        }

        .highlight-features-grey & {
            fill: theme-color(grey-800);
        }
    }

    .card-body {
        padding: rem(10px) 0 0 0;

        @include media-breakpoint-up(md) {
            padding: rem(10px) rem(20px) rem(20px) 0;
        }

        .card-title {
            @include line-height(20px, 20px);

            font-size: rem(20px);
            font-weight: 700;
            margin-bottom: rem(12px);
        }

        .card-text {
            @include line-height(20px, 16px);

            margin-bottom: rem(12px);
        }
    }

    &__link {
        display: inline-flex;
        font-weight: 700;

        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }

        &:focus-visible {
            outline: 1px solid currentcolor !important;
            outline-offset: rem(4);
        }

        .icon {
            fill: currentcolor;
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .card-highlight {
        color: $grey-400 !important;

        .card-text,
        p,
        li,
        a {
            color: $grey-400 !important;
        }
    }
}
