.teaser-fullwidth {
    position: relative;

    &__visual {
        position: relative;

        img {
            width: 100%;
            max-width: none;
            margin-bottom: 0 !important;
        }

        .gradient {
            @include background(linear-gradient(0deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 100%));

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
        }
    }

    &__content {
        position: relative;
        padding: rem(25px) 0 rem(15px) 0;
        width: 100%;

        .body {
            padding: 0;
        }

        .teaser-fullwidth__title {
            @include line-height(24px, 24px);

            font-size: rem(24px);
        }

        .teaser-fullwidth__text {
            @include line-height(22px, 14px);

            margin-bottom: rem(25px);
            font-size: rem(14px);
        }

        .btn {
            @include media-breakpoint-down(md) {
                @include background(
                    linear-gradient(180deg, rgba($brand-primary-light, 1) 0, rgba($brand-primary, 1) 100%)
                );

                border: 1px solid theme-color(primary);
                color: theme-color(primary);

                &:hover {
                    @include background(
                        linear-gradient(
                            180deg,
                            rgba(darken($brand-primary-light, 5), 1) 0,
                            rgba(darken($brand-primary, 5), 1) 100%
                        )
                    );

                    color: $white;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            @include align-horizontal(absolute);

            top: 0;
            height: 100%;

            .body {
                max-width: 500px;
                @include align-center(absolute);
            }

            .btn {
                border: 1px solid $white !important;
                color: $white !important;

                &:hover,
                &.hover,
                &:active,
                &.active,
                &:focus,
                &.focus {
                    border-color: transparent !important;
                }
            }
        }
    }

    &__cta-box {
        position: relative;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin: rem(60px) 0 rem(20px) 0;
        }

        // DONE BY JAVASCRIPT BECAUSE IE11 :-(

        @include media-breakpoint-up(lg) {
            position: absolute;
            right: 0;
            bottom: -30px;
            width: 390px;
            margin: 0;
            z-index: 9000;
        }

        // only add margin if body is present
        .teaser-fullwidth__content > .body + & {
            margin-top: rem(40px);
        }
    }

    // positioning
    @include media-breakpoint-up(lg) {
        &.content {
            &--top {
                &-left {
                    .body {
                        left: 0;
                        right: auto;
                        top: rem(35px);
                        transform: none;
                    }
                }

                &-center {
                    .body {
                        top: rem(35px);
                        transform: translate(-50%, 0);
                    }
                }

                &-right {
                    .body {
                        left: auto;
                        right: 0;
                        top: rem(35px);
                        transform: none;
                    }
                }
            }

            &--middle {
                &-left {
                    .body {
                        left: 0;
                        right: auto;
                        transform: translate(0, -50%);
                    }
                }

                &-right {
                    .body {
                        left: auto;
                        right: 0;
                        transform: translate(0, -50%);
                    }
                }
            }

            &--bottom {
                &-left {
                    .body {
                        left: 0;
                        right: auto;
                        top: auto;
                        bottom: rem(35px);
                        transform: none;
                    }
                }

                &-center {
                    .body {
                        top: auto;
                        bottom: rem(35px);
                        transform: translate(-50%, 0);
                    }
                }

                &-right {
                    .body {
                        left: auto;
                        right: 0;
                        top: auto;
                        bottom: rem(35px);
                        transform: none;
                    }
                }
            }
        }
    }
}

.teaser-fullwidth__title {
    @include line-height(40px, 36px);

    margin-bottom: rem(15px);
    font-size: rem(36px);
    color: $white !important;
}

.teaser-fullwidth__text {
    @include line-height(28px, 18px);

    margin-bottom: rem(35px);
    font-size: rem(18px);
    color: $grey-100 !important;
}

.text-light {
    .teaser-fullwidth__title,
    .teaser-fullwidth__text {
        color: $white !important;
    }
}

.container-fluid {
    .teaser-fullwidth {
        margin: 0 rem(0 - ($grid-gutter-width * 0.5));
    }
}
