.other-locations {
    .row-wrapper {
        &::before {
            background-color: #fff;
            content: '';
            width: 100%;
            margin-left: -10px;
            margin-right: -10px;
            height: 50px;
            position: absolute;
        }
    }

    &__container {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;

        @include media-breakpoint-up(xs) {
            max-width: 536px;
        }

        @include media-breakpoint-up(sm) {
            max-width: 728px;
        }

        @include media-breakpoint-up(md) {
            max-width: 952px;
        }

        @include media-breakpoint-up(ld) {
            max-width: 990px;
        }
    }
}

.card {
    $card: '.card';

    &--other-location {
        background-color: #fff;
        position: relative;
        z-index: 1;
        border-radius: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        box-shadow: 0 8px 78px 0 rgba(50, 50, 50, 0.1);

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }

        #{$card}__image {
            position: relative;
            display: none;
            max-height: 140px;

            @include media-breakpoint-up(lg) {
                display: block;
                margin: 0;
            }

            .icon {
                &--static-marker {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -100%);
                    fill: theme-color(primary);
                }
            }
        }

        #{$card}-body {
            padding: rem(25px) rem(30px);

            .card-title {
                font-weight: 500;

                @at-root {
                    .bg-dark.text-light &,
                    .bg-blue.text-light &,
                    .bg-black.text-light &,
                    .bg-medium.text-light & {
                        color: heading-color(dark) !important;
                    }
                }
            }
        }

        #{$card}-text {
            color: $grey-800 !important;

            span {
                display: block;
            }

            a {
                color: $grey-800 !important;
            }

            .btn-link {
                position: relative;
                top: auto;
                left: auto;
                text-align: left;
                display: inline-block;
                color: theme-color(primary) !important;
                padding-bottom: 0;
            }
        }
    }
}
