.quicklinks {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__item {
        display: flex;
        flex: 1;

        .btn {
            flex: 1 1 auto;
        }
    }

    @include media-breakpoint-down(sm) {
        &__item {
            .btn {
                margin-top: -1px;
                border-radius: 0;
            }

            &:first-child {
                .btn {
                    border-radius: 5px 5px 0 0;
                }
            }

            &:last-child {
                .btn {
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        gap: rem($grid-gutter-width);
        flex-direction: row;

        &__item {
            max-width: 285px;
        }
    }
}

.quicklinks-new {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;

    &__item {
        display: flex;

        &:nth-child(odd) {
            border-right: $border-default;
        }

        &:not(:nth-last-child(2):nth-child(odd), :last-child) {
            border-bottom: $border-default;
        }
    }

    &__link {
        @include line-height(14px, 11px);

        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(8);
        padding: rem(12);
        background: $white;
        font-size: rem(11px);
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        text-align: center;
        color: text-color(dark);

        &:hover,
        &:focus-visible {
            color: theme-color(primary);
        }

        &:focus-visible {
            text-decoration: underline;
        }
    }

    &__img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 38px;
        flex: 1 1 auto;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;

        &__item {
            flex: 1 1 100%;
            max-width: 250px;
            min-height: auto;
            border-right: $border-default;

            &:last-child {
                border-right: 0;
            }

            &:not(:nth-last-child(2):nth-child(odd), :last-child) {
                border-bottom: 0;
            }
        }

        &__link {
            @include line-height(20px, 14px);

            font-size: rem(14px);
            gap: rem(12);
            padding: rem(10) rem(20);
        }

        &__img {
            max-height: 70px;
        }
    }
}
