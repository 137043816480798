@use 'sass:math';

.scrollytelling {
    position: relative;

    &.has-scroll {
        overflow-y: visible;

        @include media-breakpoint-up(md) {
            overflow: visible;
        }
    }

    &__container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: opacity 750ms;

        > .swiper-container {
            width: 100%;
            height: 100%;

            .swiper-wrapper {
                display: block; // for IE11 reasons
            }
        }
    }

    .scrollytelling-dragger {
        z-index: 4;
        position: fixed;
        left: 50%;
        bottom: 3rem;
        transform: translateX(-50%);

        @include media-breakpoint-up(md) {
            bottom: 5rem;
        }

        &.is-hidden {
            bottom: -5rem;
            transition-delay: 0s;
        }
    }

    &__subcontent {
        z-index: 1;
        display: none;
        position: absolute;
        opacity: 0;
        width: 100%;
        background-color: $white;

        &.is-active {
            display: block;
            opacity: 1;
            transition: 100ms opacity ease-in-out;
        }
    }

    &__image-preview {
        width: 100%;
        object-fit: cover;
    }
}

.scrollytelling-slide {
    background: $white;

    &__image,
    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__gradient {
        opacity: 0;
        background: linear-gradient(180deg, rgba($black, 0.1), rgba($black, 0.5)),
            linear-gradient(270deg, rgba($black, 0.15), rgba($black, 0.5));
        transition: opacity 1s;

        &.fade-in {
            opacity: 1;
        }
    }

    &__container {
        z-index: 1;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: rem(960px);
        margin: 0 auto;
    }

    &__body {
        z-index: 1;
        padding-left: 1.5rem;
        padding-right: 3.5rem;
        margin-left: 0;

        @include media-breakpoint-up(sm) {
            padding-right: 4rem;
        }

        @include media-breakpoint-up(lg) {
            width: percentage(math.div(2, 3));
            padding: 0;
        }

        .scrollytelling-slide--intro & {
            padding-left: 1rem;
            text-align: center;

            @include media-breakpoint-up(lg) {
                width: percentage(math.div(10, 12));
                padding: 0;
                margin-right: auto;
                margin-left: auto;
            }
        }

        &.will-animate {
            opacity: 0;
            transform: translateY(150%);
            transition:
                opacity 1s,
                transform 1s;
        }

        &.reveal {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__topic {
        opacity: 0.75;
        color: $white;
        font-size: rem(18px);
        font-weight: $font-weight-medium;
        line-height: 1.33;

        @include media-breakpoint-up(md) {
            font-size: rem(24px);
        }
    }

    &__title {
        color: $white;
        font-size: rem(48px);
        font-weight: $font-weight-bold;
        line-height: 1.05;

        @include media-breakpoint-up(md) {
            font-size: rem(78px);
        }
    }

    &__subtitle {
        color: $white;
        font-size: rem(26px);
        line-height: 1.17;
        font-weight: $font-weight-light;

        @include media-breakpoint-up(md) {
            font-size: rem(48px);
        }
    }
}

.scrollytelling-scroll-indicator {
    z-index: 1;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 2rem;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    transition: opacity 1s;
    pointer-events: none;

    &.is-hidden {
        opacity: 0;
    }

    &__title {
        margin-bottom: 0.5rem;
        font-size: rem(18px);

        @include media-breakpoint-up(sm) {
            font-size: rem(24px);
            font-weight: $font-weight-light;
        }
    }

    @keyframes arrowIndicator {
        0% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(8px);
        }

        100% {
            transform: translateY(0);
        }
    }

    .scrollytelling__icon-wrapper {
        animation: arrowIndicator 1.25s infinite;
    }

    .icon {
        transform: rotate(90deg);
        width: rem(30px);
        height: rem(30px);
    }
}

.scrollytelling__label {
    color: $white;
    font-size: rem(14px);
    letter-spacing: 2px;
    text-transform: uppercase;
}

.scrollytelling__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border: 1px solid rgba($white, 0.5);
    border-radius: 50%;

    &--large {
        padding: 1rem;
    }

    .icon {
        fill: $white;
    }
}

.scrollytelling__external-link {
    display: inline-flex;
    align-items: center;
    padding: 1rem;
    padding-top: 0;
    color: $white;
    font-size: rem(20px);
    font-weight: $font-weight-medium;
    text-decoration: underline;

    &:hover {
        color: $brand-secondary;
        text-decoration: underline;
    }

    .icon {
        fill: $white;
    }
}

.scrollytelling__progress {
    z-index: 4;
    position: absolute;
    top: 50%;
    right: 2rem;
    opacity: 1;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: rem(40px);
    cursor: pointer;
    transition: opacity 500ms;

    &.is-hidden {
        opacity: 0;
    }

    @include media-breakpoint-up(md) {
        right: rem(40px);
    }

    .scrollytelling__menu-indicator {
        transform: rotate(-90deg) translate(115%, rem(6px));
        transform-origin: bottom right;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            transform: rotate(-90deg) translate(115%, 0.125rem);
        }
    }

    .scrollytelling-nav.is-visible ~ & {
        display: none;
    }
}

.scrollytelling__progress-indicator {
    width: rem(8px);
    height: rem(8px);
    margin: rem(8px);
    margin-right: 0;
    border-radius: 50%;
    background-color: $grey-300;
    box-shadow:
        0 0.125rem 0.25rem 0 rgba($black, 0.5),
        0 0 rem(30px) 0 $black;
    transition:
        height 500ms,
        border-radius 500ms;

    @include media-breakpoint-up(md) {
        margin-right: rem(4px);
    }

    &.is-active {
        height: rem(52px);
        border-radius: rem(6px);
        background-color: $white;
    }
}

.scrollytelling-menu {
    z-index: 100;
    position: fixed;
    transform: translate3d(100%, 0, 0);
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 2rem;
    background-color: rgba($black, 0.9);
    transition: transform 400ms ease-in;

    @include media-breakpoint-up(md) {
        width: rem(372px);
        background-color: rgba($black, 0.6);
    }

    &.is-visible {
        transform: translate3d(0, 0, 0);
        transition: transform 200ms ease-out;
    }

    &.initial-transition {
        transition-duration: 1s;
    }

    &__close {
        justify-content: flex-end;

        button {
            display: flex;
            align-items: center;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .scrollytelling__menu-indicator {
        display: inline-block;
        transform: rotate(-90deg) translate(20%, rem(21px));
        transform-origin: bottom left;
    }

    &__item {
        position: relative;
        display: flex;
        width: 100%;

        &.is-active button,
        > button:hover {
            color: $white;
        }

        &--nav {
            padding: rem(10px);
            padding-left: 0;

            > button {
                display: inline-flex;
                align-items: center;
                border: 0;
                background-color: transparent;
                color: $grey-300;
                font-size: 1rem;
                line-height: 1.25;
                text-align: left;
                text-decoration: none;
                cursor: pointer;
            }

            &.is-active {
                > button {
                    align-items: flex-end;
                }
            }

            > button::before {
                content: '';
                flex-shrink: 0;
                margin-right: rem(20px);
                width: rem(8px);
                height: rem(8px);
                border-radius: 50%;
                background-color: $grey-300;
                transition:
                    height 500ms,
                    border-radius 500ms;
            }

            &.is-active > button::before {
                height: rem(52px);
                border-radius: rem(6px);
                background-color: $white;
            }
        }
    }
}

html.disable-desktop-scroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}
