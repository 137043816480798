.form {
    [class*='col-'] {
        margin-bottom: rem(10px);
    }

    input,
    select,
    textarea,
    span {
        &[class*='col-'] {
            margin-bottom: 0;
        }
    }

    &-fieldset {
        padding-bottom: rem(22px);

        &-title {
            @include line-height(20px, 20px);

            margin: 0 0 rem(20px) 0;
            padding-bottom: 10px;
            font-size: rem(20px);
            font-weight: $font-weight-medium;
            border-bottom: $border-default;
        }

        &--double {
            .form-group {
                width: 100%;
                display: block;
                padding: 0 10px;

                @include media-breakpoint-up(md) {
                    flex: 0 0 50%;
                    width: 50%;
                    display: inline-block;
                }
            }
        }
    }

    &-group {
        &-label {
            @include line-height(20px, 16px);

            margin: 0 0 rem(6px) 0;
            font-size: rem(16px);
            color: $grey-500;
            width: 100%;

            .optional {
                @include line-height(18px, 14px);

                margin-top: 3px;
                font-size: rem(14px);
                float: right;
            }
        }
    }

    .loader {
        .spinner {
            top: auto;
            bottom: 10%;
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .form,
    .form-signin {
        color: $white;
    }
}
