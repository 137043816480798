.unfolding-grid {
    position: relative;

    &__controls {
        height: 1px;
        overflow: visible;
    }

    &__seperator {
        position: absolute;
        width: 100%;
        max-width: 1000px;
        height: 1px;
        background: linear-gradient(90deg, rgba($grey-400, 0), $grey-400 50%, rgba($grey-400, 0));
        opacity: 1;
        transition: opacity 450ms ease-in-out;
        z-index: 5;
        @include align-horizontal(absolute);
    }

    &__label {
        position: absolute;
        top: -65px;
        z-index: 10;
        transition: top 250ms ease-in-out, opacity 250ms ease-in-out;
        color: $grey-500;
        @include align-horizontal(absolute);

        &.hidden {
            top: 0;
            opacity: 0;
        }

        @include media-breakpoint-up(md) {
            font-size: rem(18px);
        }
    }

    .btn--unfold {
        position: absolute;
        top: -28px;
        z-index: 15;
        @include align-horizontal(absolute);
    }

    .row {
        margin-top: -1px;

        &.collapsing {
            display: block;
        }
    }
}

.bg-dark,
.bg-blue,
.bg-black {
    .unfolding-grid {
        &__label {
            color: $grey-400;
        }
    }
}

.bg-blue {
    .unfolding-grid {
        .btn--unfold {
            @include background-image(linear-gradient(90deg, rgba($grey-700, 1) 0, rgba($grey-900, 1) 100%) !important);

            &:hover,
            &:focus,
            &:active,
            &.active {
                @include background-image(
                    linear-gradient(0deg, rgba($grey-400, 1) 0, rgba($grey-100, 1) 100%) !important
                );
            }
        }
    }
}
