.cta-box {
    position: relative;
    margin: 0;
    padding: rem(35px) rem(38px);
    width: 100%;

    &__title {
        font-size: rem(32px);
        @include line-height(34px, 32px);

        max-width: 100% !important;
    }

    &__text {
        max-width: 100% !important;
    }

    &__buttons {
        margin-bottom: rem(16px);
        max-width: 100% !important;
    }

    .btn {
        margin-bottom: 0;

        &-primary {
            color: $white !important;
            border-color: theme-color(primary-light) !important;
        }

        &-ghost {
            color: theme-color(primary) !important;
            background-color: $white !important;
            border-color: theme-color(primary-light) !important;
        }

        &-link {
            border: none !important;
            color: theme-color(primary) !important;
            background: transparent !important;

            .icon {
                fill: theme-color(primary) !important;
            }

            &:hover {
                color: theme-color(primary) !important;

                .icon {
                    fill: theme-color(primary) !important;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        min-height: 350px;
    }
}

.text-medium .cta-box,
.text-dark .cta-box {
    &__title {
        color: heading-color(dark) !important;
    }

    &__text {
        color: text-color(dark) !important;
    }
}
