@use 'sass:math';

$doorway-door-transition-duration: 1000ms;
$doorway-door-transition-timing-function: ease;
$doorway-door-transition: $doorway-door-transition-duration $doorway-door-transition-timing-function;
$doorway-detail-transition-duration: 400ms;
$doorway-detail-transition-timing-function: ease-in;
$doorway-detail-transition: $doorway-detail-transition-duration $doorway-detail-transition-timing-function
    $doorway-door-transition-duration;
$doorway-link-transition-delay: 50ms;
$doorway-overlay-tint-opacity-inactive: 0.7;
$doorway-overlay-tint-opacity-neutral: 0.4;
$doorway-overlay-tint-opacity-active: 0.2;

.doorway {
    position: relative;
    overflow: hidden;

    &__doors {
        display: flex;
        flex-direction: column;
        height: 85vh; // to make sure there is room beneath the component (fixed header included)
        overflow: hidden;
        margin: 0;

        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
    }

    &__door {
        position: relative;
        height: percentage(math.div(1, 3));
        overflow: hidden;
        transition: height $doorway-door-transition;

        @include media-breakpoint-up(xl) {
            width: percentage(math.div(1, 3));
            height: auto;
            transition-property: width;
        }

        // 2 doors
        .doorway.has-2-doors & {
            height: 50%;

            @include media-breakpoint-up(xl) {
                width: 50%;
                height: auto;
            }

            &.is-active {
                height: 100%;

                @include media-breakpoint-up(xl) {
                    width: 75%;
                    height: auto;
                }
            }
        }

        // 3 doors
        &.is-active {
            height: 80%;

            @include media-breakpoint-up(xl) {
                width: 50%;
                height: auto;
            }

            .doorway__door-detail {
                transform: translateY(rem(-40px));
                transition: transform $doorway-detail-transition;

                @include media-breakpoint-up(xl) {
                    transform: translateY(rem(-60px));
                }
            }

            .doorway__door-content {
                @include media-breakpoint-down(lg) {
                    bottom: rem(40px);
                }
            }

            .doorway__door-overlay-tint {
                opacity: $doorway-overlay-tint-opacity-active;
            }

            .doorway__door-hide-overflow::after {
                left: rem(80px);
                right: rem(-80px);
                transition: right $doorway-door-transition, left $doorway-detail-transition;

                @include media-breakpoint-up(xl) {
                    left: rem(60px);
                }
            }

            .doorway__door-arrow-link {
                transform: translate(50%, 50%);
                transition-delay: $doorway-door-transition-duration + $doorway-link-transition-delay;

                @include media-breakpoint-up(xl) {
                    transform: translate(0, 50%);
                }
            }
        }

        // doors that are not hovered
        .doorway.has-active-door &:not(.is-active) {
            .doorway__door-overlay-tint {
                opacity: $doorway-overlay-tint-opacity-inactive;
            }
        }
    }

    &__door-image {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__door-content {
        position: absolute;
        top: 0;
        right: rem(32px);
        bottom: rem(24px);
        left: rem(40px);
        transition: bottom ($doorway-detail-transition-duration - $doorway-link-transition-delay)
            $doorway-detail-transition-timing-function;

        @include media-breakpoint-up(xl) {
            top: auto;
            right: 0;
            bottom: rem(100px);
            left: rem(60px);
            width: rem(400px);
        }
    }

    &__door-hide-overflow {
        position: absolute;
        bottom: 0;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
            position: static;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            border-bottom: 1px solid $grey-500;
            transition: right $doorway-door-transition,
                left $doorway-detail-transition-duration $doorway-detail-transition-timing-function;
        }
    }

    &__door-detail {
        transform: translateY(100%);
        padding: rem(60px) rem(20px) 0;
        transition: transform $doorway-door-transition;

        @include media-breakpoint-up(xl) {
            padding-right: 0;
            padding-left: 0;
        }

        .lead {
            p {
                font-size: inherit;
            }
        }
    }

    &__door-arrow-link {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-250%, 50%); // initially hide link outside of door
        transition: transform ($doorway-detail-transition-duration - $doorway-link-transition-delay)
            $doorway-detail-transition-timing-function;
    }

    &__door-link-overlay-desktop {
        display: none;

        @include media-breakpoint-up(xl) {
            display: block;
        }
    }

    &__door-link-overlay-desktop,
    &__door-overlay,
    &__door-overlay-tint {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__door-overlay {
        background-image: linear-gradient(270deg, rgba($black, 0.25), transparent 13%),
            // shadow on right side of door
            linear-gradient(225deg, transparent 40%, $black); // gradient to make text readable, starting bottom left

        transition: top $doorway-door-transition;
    }

    &__door-overlay-tint {
        opacity: $doorway-overlay-tint-opacity-neutral;
        background-image: linear-gradient(225deg, $brand-primary-dark, $black); // blue tint
        transition: opacity $doorway-door-transition;
    }
}
