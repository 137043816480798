.list {
    &--columns {
        padding: 0;
        margin: 0;
        list-style: none;
        column-gap: 20px;

        &-double {
            column-count: 1;

            @include media-breakpoint-up(md) {
                column-count: 2;
            }
        }

        &-triple {
            column-count: 1;

            @include media-breakpoint-up(md) {
                column-count: 3;
            }
        }
    }

    &--checkmark {
        margin-bottom: 0;

        li {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin-top: rem(5px);
            margin-bottom: rem(5px);
            color: $grey-600;

            @include media-breakpoint-up(sm) {
                align-items: center;
            }

            @at-root {
                .bg-dark &,
                .bg-blue &,
                .bg-black & {
                    color: $grey-400 !important;
                }
            }
        }

        .icon {
            width: 18px;
            min-width: 18px;
            height: 18px;
            margin-right: 15px;

            &--checkmark {
                position: relative;
                display: inline-block;

                @include media-breakpoint-up(sm) {
                    display: flex;
                }

                // TODO: refactor! all the importants!

                svg {
                    color: $grey-400;
                    fill: $grey-400;

                    @at-root {
                        .bg-dark &,
                        .bg-blue &,
                        .bg-black & {
                            fill: $white !important;
                        }
                    }
                }

                &-blue {
                    svg {
                        color: theme-color(primary) !important;
                        fill: theme-color(primary) !important;

                        @at-root {
                            .bg-dark &,
                            .bg-blue &,
                            .bg-black & {
                                fill: theme-color(primary) !important;
                            }
                        }
                    }
                }

                &-orange {
                    svg {
                        color: theme-color(secondary) !important;
                        fill: theme-color(secondary) !important;

                        @at-root {
                            .bg-dark &,
                            .bg-blue &,
                            .bg-black & {
                                fill: theme-color(secondary) !important;
                            }
                        }
                    }
                }

                &-yellow {
                    svg {
                        color: theme-color(warning) !important;
                        fill: theme-color(warning) !important;

                        @at-root {
                            .bg-dark &,
                            .bg-blue &,
                            .bg-black & {
                                fill: theme-color(warning) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
